import * as React from 'react';
import { Link } from 'react-router-dom';
import { AnimatePresence, motion, MotionValue, stagger, useMotionValue, useSpring, useTransform, useViewportScroll } from 'framer-motion';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons'

import { AppContextState, IAppState } from '../context';

import './PageContainer.scss';

type Props = {
    pathName: string,
    children: React.ReactNode
};
type State = {
    toggleNav: Boolean,
    mounted: Boolean,
    unieqKey: number,
    scrollY: number,
    isNavToggling: Boolean,
};
class PageContainer extends React.Component<Props, State, IAppState> {
    context!: IAppState;

    state: State = {
        toggleNav: true,
        mounted: false,
        unieqKey: 0,
        scrollY: 0,
        isNavToggling: false,
    };

    contentScroll = React.createRef<HTMLDivElement>();
    smoothElmScroll = React.createRef<HTMLDivElement>();
    pageElmContainer = React.createRef<HTMLDivElement>();

    containerVariant = {
        initial: {
            y: '120vh',
            borderRadius: 50,
            opacity: 0
        },
        // animate: {
        //     y: 0,
        //     scale: 1,
        //     transition: {
        //         duration: 1,
        //         ease: [1, .1, .35, 1],
        //     }
        // },
        exit: {
            y: '-100vh',
            // opacity: 0,
            borderRadius: 50,
            maxHeight: 0,
            transition: {
                duration: .5,
                ease: [0.5, 0.025, 0, 1] 
            }
        }
    }

    componentDidMount(): void {
        
    }

    componentWillUnmount(): void {
        window.removeEventListener('scroll', this.onScrollHandler);
    }

    onScrollHandler = (e: Event) => {
        // if(this.context.crrFeature === this.props.pathName) {
        //     let scrollling = this.smoothElmScroll.current,
        //         compos: any = scrollling ? window.getComputedStyle(scrollling) : {},
        //         matrix = new WebKitCSSMatrix(compos.transform),
        //         currentScroll = window.isMobile ? window.scrollY : matrix.m42 * -1;
        //     this.context.setParallaxPos(currentScroll);   
        // }

        
    }

    toggleNavHandler() {
        this.setState({
            toggleNav: !this.state.toggleNav,
        });
    }

    render() {
        const { pathName } = this.props;
        const { toggleNav, mounted, unieqKey, scrollY } = this.state;

        const namespace = (pathName.split('/')[1] || 'home') + unieqKey.toString();

        return (
            <>
                <motion.div
                    id="PageContainer"
                    ref={this.pageElmContainer}
                    className={'PageContainer ' + namespace + (window.isMobile ? ' mobile' : '') + (toggleNav ? ' toggled' : '')}
                    key={'PageContainer' + namespace}
                    variants={this.containerVariant}
                    initial='initial'
                    animate={{
                        y: 0,
                        // scale: isToggleNav ? 0.95 : 1,
                        opacity: 1,
                        borderRadius: 0,
                        transition: {
                            duration: mounted ? 0.5 : .75,
                            ease: window.onFirstMounted || mounted ? [0.5, 0.025, 0, 1] : [1, .1, .35, 1],
                            delay: mounted ? 0 : .5,
                        }
                    }}
                    exit='exit'
                    onAnimationComplete={() => {
                        window.onFirstMounted = false;
                        this.setState({
                            mounted: true,
                            toggleNav: !mounted ? false : toggleNav
                        })
                    }}
                >
                    {/* <WrapPageScroll
                        crrPathName={pathName}
                        refSmooth={this.smoothElmScroll}
                    > */}
                        <motion.div className="sub content-overflow" ref={this.contentScroll}
                            style={{
                                y: toggleNav ? -scrollY : 0,
                            }}
                        >
                            {this.props.children}
                        </motion.div>
                    {/* </WrapPageScroll> */}
                </motion.div>
            </>
        );
    }
}

PageContainer.contextType = AppContextState;

export default PageContainer;