import * as React from 'react';
import { checkSession, deleteCookie, getCookie, getData, setCookie, sleep } from './libs/utils';
import { Link } from 'react-router-dom';
import { LayoutGroup, AnimatePresence, motion } from 'framer-motion';
import Button from './common/components/Interactive/Button';
import ModalUploader from './common/ModalUploader';

export interface IThemeStateObject {
    [value: string]: string;
}

export interface IUser {
    user_id: number
    username: string
    firstname: string
    lastname: string
    phone_number: string
    email: string
    image_url: string | null
    birthday: string | null
    projects_members: Array<any>
}

export interface IProject {
    project_id: number
    project_name: string
    project_description: string
    project_logo: string | null,
    project_cover: string | null,
    invite_code: string
    created_date: string
    modified_date: string
    usageData: any
}

interface IAuthContext {
    user: IUser | null;
    setUser: (user: IUser) => void;
    logout: () => void;
}

interface IProjectContext {
    project: IProject | null;
    rolePower?: number
    setProject: (project: IProject, rolePower: number) => void;
    deleteProject: () => void;
}

export interface IAppState {
    auth: IAuthContext
    project: IProjectContext
    theme: IThemeStateObject
    refreshSession: () => void
    isServerError: boolean
    keywordSearchInput?: string
    setSearchKeyword: (keyword: string) => void
    lastContentUpdate?: number
    setLastContentUpdate: (time: number) => void
    uploadList: Array<any>
    setUploadList: (list: Array<any>) => void
    addUploadList: (item: any) => void
    removeUploadList: (key: string) => void
    updateUploadList: (key: string, item: any) => void
}

export const AppContextState = React.createContext<IAppState>({
    auth: {
        user: null,
        setUser: () => {},
        logout: () => {},
    },
    project: {
        project: null,
        setProject: () => {},
        deleteProject: () => {},
    },
    theme: {
        
    },
    refreshSession: async () => {},
    isServerError: false,
    keywordSearchInput: '',
    setSearchKeyword: () => {},
    lastContentUpdate: 0,
    setLastContentUpdate: () => {},
    uploadList: [],
    setUploadList: () => {},
    addUploadList: () => {},
    removeUploadList: () => {},
    updateUploadList: () => {}
});

type PageProps = {
    children: React.ReactNode
};
type PageState = {
    
};
class ContextWraper extends React.Component<PageProps, PageState> {
    

    state: IAppState = {
        auth: {
            user: null,
            setUser: (user: IUser) => {
                this.setState({
                    auth: {
                        ...this.state.auth,
                        user,
                    }
                });
            },
            logout: () => {
                this.setState({
                    auth: {
                        ...this.state.auth,
                        user: null,
                    }
                });
                deleteCookie('token');
                deleteCookie('project_id');
                window.location.href = '/';
            },
        },
        project: {
            project: null,
            setProject: (project: IProject, rolePower: number) => {
                console.log('rolePower', rolePower)
                setCookie('project_id', project.project_id.toString(), 7);
                this.setState({
                    project: {
                        rolePower,
                        ...this.state.project,
                        project,
                    },
                });
            },
            deleteProject: () => {
                this.setState({
                    project: {
                        ...this.state.project,
                        project: null,
                    },
                });
                deleteCookie('project_id');
                window.location.href = '/teams';
            }
        },
        theme: {

        },
        refreshSession: async () => {
            await this.doCheckToken();
        },
        isServerError: false,
        keywordSearchInput: '',
        setSearchKeyword: (keyword: string) => {
            this.setState({
                keywordSearchInput: keyword
            })
        },
        lastContentUpdate: 0,
        setLastContentUpdate: (time: number) => {
            this.setState({
                lastContentUpdate: time
            })
        },
        uploadList: [],
        setUploadList: async (list: Array<any>) => {
            let currentQueue = ++this.lastQueueIndex;
            this.currentQueue.push(currentQueue);
            
            while (this.currentQueue[0] !== currentQueue) {
                await sleep(10);
            }

            this.setState({
                uploadList: list
            })
            await sleep(10);
            this.currentQueue.shift();
        },
        addUploadList: async (item: any) => {
            if(this.state.uploadList.find((_item: any) => _item.key === item.key)) return;

            let currentQueue = ++this.lastQueueIndex;
            this.currentQueue.push(currentQueue);
            
            while (this.currentQueue[0] !== currentQueue) {
                await sleep(10);
            }
            this.setState({
                uploadList: [...this.state.uploadList, item]
            })

            await sleep(10);
            this.currentQueue.shift();
        },
        removeUploadList: async (key: string) => {
            let currentQueue = ++this.lastQueueIndex;
            this.currentQueue.push(currentQueue);
            
            while (this.currentQueue[0] !== currentQueue) {
                await sleep(10);
            }
            this.setState({
                uploadList: this.state.uploadList.filter((_item: any) => _item.key !== key)
            })

            await sleep(10);
            this.currentQueue.shift();
        },
        updateUploadList: async (key: string, item: any) => {
            let currentQueue = ++this.lastQueueIndex;
            this.currentQueue.push(currentQueue);
            
            while (this.currentQueue[0] !== currentQueue) {
                await sleep(10);
            }
            console.log('updateUploadList', key, item)
            this.setState({
                uploadList: this.state.uploadList.map((_item: any) => {
                    if(_item.key === key) {
                        return item
                    }
                    return _item;
                })
            })

            await sleep(10);
            this.currentQueue.shift();
        }
    };

    currentQueue: Array<any> = [];
    lastQueueIndex = 0;

    componentDidMount(): void {
        this.doCheckToken(true);
    }

    async doCheckToken(redirect: boolean = false) {
        let result = await checkSession();

        if(result === true) {
            this.setState({
                isServerError: true
            })
            return;
        }
        
        if (!result && window.location.pathname !== '/') {
            window.location.href = '/';
            return;
        }

        if (result) {
            this.state.auth.setUser(result);
            if(window.location.pathname === '/') {
                if(result.projects_members.length === 0) {
                    if(redirect) window.location.href = '/join-team';
                    return;
                }
            } else if(window.location.pathname === '/dashboard' && result.projects_members.length === 0) {
                if(redirect) window.location.href = '/join-team';
                return;
            }

            if(window.location.pathname === '/join-team') {
                return;
            }

            if(result.projects_members.length > 0) {
                let project_id = getCookie('project_id');
                if(!project_id && (window.location.pathname === '/' || window.location.pathname === '/dashboard')) {
                    if(redirect) window.location.href = '/teams';
                    return 
                    
                } else {
                    let targetProject = result.projects_members.find((project: any) => project.project_id == project_id);
                    if(!targetProject && window.location.pathname !== '/teams') {
                        if(redirect) window.location.href = '/teams';
                        return;
                    }

                    if(targetProject) {
                        console.log("SETPROJECT")
                        this.state.project.setProject(targetProject.project, targetProject.role_power);

                        if(window.location.pathname === '/') {
                            if(redirect) window.location.href = '/dashboard';
                        }
                    }
                }
            } else {
                if(window.location.pathname !== '/join-team') {
                    if(redirect) window.location.href = '/join-team';
                }
            }

            console.log(this.state)
            return
        }
    }

    render() {
        return (
            <AppContextState.Provider value={{
                ...this.state,
            }}>
                {this.state.isServerError && <motion.div className='centerContain'>
                            {(()=>{
                                window.document.title = '404 - Page not found';
                                return false;
                            })() && <></>}
                            <motion.div
                                initial={{
                                    opacity: 0,
                                    scale: 0,
                                }}
                                animate={{
                                    opacity: 1,
                                    scale: 1,
                                }}
                                exit={{
                                    opacity: 0,
                                    scale: 0,
                                }}
                                transition={{
                                    duration: .3,
                                }}
                            >
                                <h1>500</h1>
                                <h3>Some error occured</h3>
                                <br/>
                                <Button onClick={() => {
                                    window.location.reload();
                                }}>Refresh</Button>
                            </motion.div>
                        </motion.div>}
                {!this.state.isServerError && this.props.children}
            </AppContextState.Provider>
        );
    }
}

export default ContextWraper;


