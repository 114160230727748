import * as React from 'react';
import { Link, Navigate } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import PageContainer from '../../common/PageContainer';
import { Router, Route } from 'react-router-dom';

import './index.scss';

import { AppContextState, IAppState } from '../../context';
import Button from '../../common/components/Interactive/Button'
import TextBox from '../../common/components/Interactive/Input/TextBox';
import { getData, postData, setCookie, displayFilesSizeFormat, postUplaodFile, sleep } from '../../libs/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowRight, faChevronCircleDown, faChevronDown, faTimes } from '@fortawesome/free-solid-svg-icons';
import DashboardContainer from '../../common/DashboardContainer';
import VideoList from '../../common/components/Video-List';
import LoadingIcon from '../../common/LoadingIcon';
import ReactPlayer from 'react-player';
import HeaderUploader from './components/header';
import UploadList from './components/UploadList';
import PlayerPanel from './components/PlayerPanel';
import TagTimeline from './components/TagTimeline';
import configs from '../../configs';
import IngredientPanel from './components/IngredientPanel';
import SubmitPanel from './components/SubmitPanel';
import DescriptionPanel from './components/DescriptionPanel';

type PageProps = {
    isShowPage: Boolean
    onClose: (shouldRefresh: boolean) => void
};
type PageState = {
    loading: Boolean,
    VideoList: Array<any>
    crrSelectedKey: string
};
class ModalUploader extends React.Component<PageProps, PageState, IAppState> {
    context!: IAppState;

    state = {
        loading: true,
        VideoList: [],
        isShowPage: false,
        crrSelectedKey: ''
    };

    static setCrrSekected(key: string) {

    }

    componentDidMount() {
        this.getVideoList();
        ModalUploader.setCrrSekected = (key: string) => {
            this.setState({
                crrSelectedKey: key
            })
        }
    }

    componentDidUpdate(prevProps: Readonly<PageProps>, prevState: Readonly<PageState>, snapshot?: IAppState | undefined): void {
        if(this.props.isShowPage && this.state.VideoList.length == 0) {
            this.getVideoList();
        }
    
    }

    componentWillUnmount(): void {
        
    }

    requestedVideoList = false;
    async getVideoList() {
        if(this.requestedVideoList === true) {
            console.log('requestedVideoListrequestedVideoListrequestedVideoList')
            return;
        }

        let result = await getData('/api/footage/getFileList?project_id=' + this.context.project.project?.project_id + '&status=inactive')
        if(result.status === 200) {
            const data = await result.json();
            if(data.files) {
                for(let i = 0; i < data.files.length; i++) {
                    let file = data.files[i].file;
                    console.log('file', file)
                    let itemKey = (`${file.file_id}-${file.owner_id}`).toString();
                    file.file_path = configs.getAssetUrl() + file.file_path;
                    // console.log(file)
                    await sleep(100);
                    this.context.addUploadList({
                        key: itemKey,
                        data: file,
                        file: file,
                        filename: file.title,
                        percent: 100,
                        success: true
                    });
                    this.requestedVideoList = true;
                }
            }
        }
    }

    crrfileQueue = 1;

    async onDropFile(files: any) {
        console.log('files', files)
        if(files.length > 0) {
            for(let i = 0; i < files.length; i++) {
                let itemKey = (Date.now() + (++this.crrfileQueue)).toString();
                let file = files[i];
                this.context.addUploadList({
                    key: itemKey,
                    file: file,
                    filename: file.name,
                    percent: 0
                });
                await sleep(100);
                this.uploadFile(files[i], itemKey);
                if(this.context.uploadList.length === 1) {
                    this.setState({
                        crrSelectedKey: itemKey
                    })
                }
            }
        }
    }

    uploadFile = async (file: any, itemKey: string) => {
        // console.log('filefile', file)
        if(file) {

            let targetFile = this.context.uploadList.find((item: any) => item.key === itemKey);

            console.log('targetFile', targetFile)

            let formData = new FormData();
            formData.append('file', file);
            formData.append('project_id', (this.context.project.project?.project_id || 0).toString());
            let result = await postUplaodFile('/api/storage/uploadFile', formData, (progressEvent: any) => {
                console.log('progressEvent', progressEvent)
                let percent = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                this.context.updateUploadList(itemKey, {
                    ...targetFile,
                    percent: percent
                });
            });
            if(result.status === 200) {
                console.log('success', result)
                const data = await result.json();
                this.context.updateUploadList(itemKey, {
                    ...targetFile,
                    percent: 100,
                    success: true,
                    data: data
                });
            } else {
                console.log('error', result)
                this.context.updateUploadList(itemKey, {
                    ...targetFile,
                    percent: 100,
                    success: false,
                    data: result
                });
            }
        }
    }

    render() {
        // console.log(this.props.isShowPage)
        return (<AnimatePresence mode='wait'>
        {this.props.isShowPage && 
           <motion.div id="uploaderPage">
                <motion.div className='sub'
                    initial={{
                        opacity: 0,
                        y: '100vh'
                    }}
                    animate={{
                        opacity: 1,
                        y: '0',
                        transition: {
                            duration: 0.25,
                            ease: 'easeInOut'
                        }
                    }}
                    exit={{
                        opacity: 0,
                        y: '100vh'
                    }}
                >
                    <motion.button className='closeButton'
                        onClick={() => {
                            this.context.setLastContentUpdate(Date.now());
                            this.props.onClose(false)
                        }}
                    >
                        <FontAwesomeIcon icon={faChevronDown} />
                    </motion.button>
                    <HeaderUploader />

                    <motion.div className='topPanel'>
                        <motion.div className='sidePanel'>
                            <UploadList onDropFile={(files: any) => this.onDropFile(files)}
                                crrSelectedKey={this.state.crrSelectedKey}
                                onSelectItem={(key: string) => {
                                    this.setState({
                                        crrSelectedKey: key
                                    })
                                }}
                            />
                        </motion.div>
                        <motion.div className='playerPanel'>
                            <PlayerPanel crrSelectedKey={this.state.crrSelectedKey} />
                        </motion.div>
                        <motion.div className='sidePanel'>
                            <TagTimeline crrSelectedKey={this.state.crrSelectedKey} />
                        </motion.div>
                    </motion.div>

                    <motion.div className='bottomPanel'>
                        <motion.div className='sidePanel'>
                            <IngredientPanel crrSelectedKey={this.state.crrSelectedKey} />
                        </motion.div>
                        <motion.div className='editorPanel'>
                            <DescriptionPanel crrSelectedKey={this.state.crrSelectedKey} />
                        </motion.div>
                        <motion.div className='sidePanel'>
                            <SubmitPanel crrSelectedKey={this.state.crrSelectedKey} />
                        </motion.div>
                    </motion.div>
                </motion.div>
           </motion.div>
        }
        </AnimatePresence>);
    }
}

ModalUploader.contextType = AppContextState;

export default ModalUploader;