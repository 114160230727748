import * as React from 'react';
import { Link } from 'react-router-dom';
import { AnimatePresence, motion, stagger } from 'framer-motion';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faGithub } from '@fortawesome/free-brands-svg-icons'
import { faAt, faCheckCircle, faPhotoFilm, faTimesCircle, faTrash } from '@fortawesome/free-solid-svg-icons'

import { AppContextState, IAppState } from '../../../context';

import { deleteData, getData, getRolePowerName } from '../../../libs/utils';

import { FileUploader } from "react-drag-drop-files";
import LoadingIcon from '../../LoadingIcon';
import Button from '../../components/Interactive/Button';

const videoTypes = [
    "mp4",
    "webm",
    "ogg",
    "mov",
    "avi",
    "wmv",
    "flv",
    "mkv",
    "m4v",
];

type Props = {
    crrSelectedKey?: string,
    onSelectItem: (key: string) => void,
    onDropFile: (files: any) => void
};
type State = {
    
};
class UploadList extends React.Component<Props, State, IAppState> {
    context!: IAppState;

    state: State = {
        
    };


    componentDidMount(): void {
        
    }

    uploadRef = React.createRef<HTMLInputElement>();

    async deleteFileInAPI(file_id: number) {
        let result = await deleteData('/api/storage/deleteFile?file_id=' + file_id)
        console.log(result)
    }

    render() {
        // const { textColor, crrFeature, isToggleNav }: IAppState = this.context;
        // console.log(this.context)

        const { auth, project, uploadList } = this.context;


        return (
            <motion.div id="uploadListPanel">
                
                <motion.div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                }}>
                    <motion.h1 className="topic">Footage List</motion.h1>
                    <motion.div style={{
                        padding: '10px 5px 0',
                    }}>
                        <Button style={{
                            whiteSpace: 'nowrap',
                        }}
                        onClick={() => {
                            this.uploadRef.current?.click();
                        }}
                        >Upload Files</Button>
                        <input id="fileUpload" type="file" multiple ref={this.uploadRef} hidden
                            onChange={(e) => {
                                    this.props.onDropFile(e.target.files);
                            }}
                            name="filesUplaod" accept="video/*"
                        />
                    </motion.div>
                </motion.div>
                <FileUploader 
                    handleChange={(files: any) => {
                        this.props.onDropFile(files);
                    }}

                    multiple={true}
                    
                    name="file"
                    disabled
                    types={videoTypes}
                    hoverTitle="..."
                z>
                    <motion.div className='vid-list'>

                        {/* {Array(20).fill(0).map((item, index) => {
                            return <motion.div className={'vid-item ' + (index == 0 ? 'active' : '')}>
                                <motion.div className='vid-info'>
                                    <motion.p className='title'>Test {index}.mp4</motion.p>
                                    <motion.div className='right-side-option'>
                                        {index % 2 === 0 &&
                                            <motion.div className='upload-progress-bar'>
                                                <motion.div className='progress' style={{
                                                    width: '20%'
                                                }}></motion.div>
                                            </motion.div>
                                        }
                                        {index % 2 === 1 &&
                                            <motion.button className='option' style={{
                                                color: 'green'
                                            }}>
                                                <FontAwesomeIcon icon={faCheckCircle} />
                                            </motion.button>
                                        }
                                        <motion.button className='option'>
                                            <FontAwesomeIcon icon={faTrash} />
                                        </motion.button>
                                    </motion.div>
                                </motion.div>
                            </motion.div>;
                        })} */}
                        
                        {this.context.uploadList && this.context.uploadList!.map((item, index) => {
                            // console.log(item)
                            return <motion.div
                                key={'video-upload-' + item.key}
                                className={'vid-item ' + (item.key === this.props.crrSelectedKey ? 'active' : '')}
                                onClick={(e) => {
                                    this.props.onSelectItem(item.key);
                                }}
                            >
                                <motion.div className='vid-info'>
                                    <motion.p className='title'>{item.filename}</motion.p>
                                    <motion.div className='right-side-option'>
                                        {item.percent < 100 &&
                                            <motion.div className='upload-progress-bar'>
                                                <motion.div className='progress' style={{
                                                    width: item.percent + '%'
                                                }}></motion.div>
                                            </motion.div>
                                        }
                                        {item.percent >= 100 && !item.error && <>
                                            {!item.data && // show loading icon
                                                <motion.button className='option' style={{}}>
                                                    <LoadingIcon size={10} border={2}/>
                                                </motion.button>
                                            }

                                            {item.data && 
                                                <motion.button className='option' style={{
                                                        color: 'green'
                                                    }}
                                                    initial={{
                                                        opacity: 0,
                                                        scale: 0.5,
                                                    }}
                                                    animate={{
                                                        opacity: 1,
                                                        scale: 1,
                                                        transitionDuration: '0.2s',
                                                    }}
                                                >
                                                    <FontAwesomeIcon icon={faCheckCircle} />
                                                </motion.button>
                                            }
                                        </>}
                                        
                                        {item.percent >= 100 && item.error &&
                                            <motion.button className='option' style={{
                                                    color: 'red'
                                                }}
                                                initial={{
                                                    opacity: 0,
                                                    scale: 0.5,
                                                }}
                                                animate={{
                                                    opacity: 1,
                                                    scale: 1,
                                                    transitionDuration: '0.2s',
                                                }}
                                            >
                                                <FontAwesomeIcon icon={faTimesCircle} />
                                            </motion.button>
                                        }
                                        {(item.percent >= 100 || item.error) && item.data && <motion.button className='option'
                                            onClick={async (e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                if(window.confirm('Are you sure you want to delete this file?') == false) return;
                                                
                                                if(item.data) {
                                                    await this.deleteFileInAPI(item.data.file_id);
                                                    await getData('/api/storage/deleteFile?file_id=' + item.data.file_id);
                                                }
                                                this.context.removeUploadList(item.key);
                                            }}
                                        >
                                            <FontAwesomeIcon icon={faTrash} />
                                        </motion.button>}
                                    </motion.div>
                                </motion.div>
                            </motion.div>;
                        })}

                        {this.context.uploadList.length == 0 && <motion.div className='centerContain'>
                            <motion.p className='title'>Drag file here to upload</motion.p>
                        </motion.div>}
                    </motion.div>
                </FileUploader>
            </motion.div>
        );
    }

}

UploadList.contextType = AppContextState;

export default UploadList;