import { Component, createRef, CSSProperties } from 'react'

// Fonts & Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'

interface IRecipeProps {
    className?: string,
    style?: CSSProperties
    children?: any,
    onClick?: any
}

class Button extends Component<IRecipeProps> {

    inputBox: any = createRef();

    constructor(props: any) {
        super(props);
    }    

    render() {
        const { className, style } = this.props;
        return <>
            <button className={'btn-layout ' + className} style={style}
                onClick={this.props.onClick}
            >
                { this.props.children }
            </button>
        </>
    }
}

export default Button;