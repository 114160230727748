import * as React from 'react';
import { Link, Navigate } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import PageContainer from '../../common/../PageContainer';
import { Router, Route } from 'react-router-dom';

import './index.scss';

import { AppContextState, IAppState } from '../../../context';
import Button from '../../../common/components/Interactive/Button'
import TextBox from '../../../common/components/Interactive/Input/TextBox';
import { getData, postData, setCookie, displayFilesSizeFormat, postUplaodFile, sleep, deleteData, putData } from '../../../libs/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faImage, faDownload, faTrash } from '@fortawesome/free-solid-svg-icons';
import DashboardContainer from '../../../common/DashboardContainer';
import VideoList from '../../../common/components/Video-List';
import LoadingIcon from '../../../common/LoadingIcon';
import ReactPlayer from 'react-player';
import configs from '../../../configs';
import { FileUploader } from 'react-drag-drop-files';

const imageTypes = [
    'jpeg',
    'jpg'
];

type PageProps = {
    onClose: (shouldRefresh: boolean) => void
    
};
type PageState = {
    loading: Boolean,
    userDetail: any
    files: any[]
    firstname: string
    lastname: string
    phone_number: string
    saving: boolean
    shouldRefresh: boolean
    uploadingProfile: boolean
    uploadingSample: boolean
    enableUploadSample: boolean
};
class UserProfileModal extends React.Component<PageProps, PageState, IAppState> {
    context!: IAppState;

    state = {
        loading: true,
        userDetail: {
            actor_id: 0,
            project_id: 0,
            firstname: "",
            lastname: "",
            image_url: null,
            created_date: null,
            actor_tag_map: [],
            projects: {},
            raw_data: null,
        },
        files: [],
        firstname: '',
        lastname: '',
        phone_number: '',
        saving: false,
        shouldRefresh: false,
        uploadingProfile: false,
        uploadingSample: false,
        enableUploadSample: true
    };

    constructor(props: any) {
        super(props);
    }

    componentDidMount() {
        this.getuserDetail();
    }

    componentWillUnmount(): void {
        
    }

    async getuserDetail() {
        await this.context.refreshSession();
        this.setState({
            loading: false,
            firstname: this.context.auth.user?.firstname || '',
            lastname: this.context.auth.user?.lastname || '',
            userDetail: {
                image_url: this.context.auth.user?.image_url || '',
            }
        })
    }

    pickFile = (e: any) => {
        const files = e.target.files;
        this.setState({
            files: files
        })

        setTimeout(() => {
            this.uploadImage();
        }, 100);
    }

    uploadImage = async () => {
        if(this.state.files.length > 0) {
            this.setState({
                uploadingProfile: true
            })
            let formData = new FormData();
            formData.append('file', this.state.files[0]);
            let result = await postUplaodFile('/api/user/uploadUserProfile', formData, (progressEvent: any) => {
                
            });
            if(result.status === 200) {
                const data = await result.json();
                await this.getuserDetail();
            }
            await sleep(1000);
            this.setState({
                uploadingProfile: false
            })
        }
    }

    inputFileRef = React.createRef<HTMLInputElement>();

    saveActor = async () => {
        this.setState({
            saving: true
        })
        let result = await putData('/api/user/updateUserProfile', {
            firstname: this.state.firstname,
            lastname: this.state.lastname,
            phone_number: this.state.phone_number,
        })
        this.setState({
            saving: false,
            shouldRefresh: true
        })
        if(result.status === 200) {
            const data = await result.json();
            await this.getuserDetail();
        }
        // this.props.onClose(this.state.shouldRefresh);
    }

    render() {
        // console.log(this.props)


        return (
            <motion.div className='UserProfileModal'>
                <motion.div className='modalBg'
                    initial={{ opacity: 0 }}
                    animate={{
                        opacity: 1,
                        transition: {
                            duration: 0.3,
                        }
                    }}
                    exit={{
                        opacity: 0,
                    }}
                    onClick={() => {
                        this.props.onClose(this.state.shouldRefresh);
                    }}
                ></motion.div>
                <motion.div className='modalContent'>
                    <motion.div className='sub'
                        layoutId={`modal-user-option-mainUserOption`}
                    >
                        <p className='title'>Edit User Profile</p>
                        <motion.div className='sidePanel'>      
                            <motion.div className='circleImage' onClick={() => {
                                this.inputFileRef.current?.click();
                            }}
                            // layoutId={`actor-modal-a${this.props.actor_id}-image`}
                            >
                                {!this.state.userDetail.image_url &&
                                <motion.div className='centerContain'>
                                    <FontAwesomeIcon icon={faImage} />
                                </motion.div>}
                                {this.state.userDetail.image_url &&
                                <motion.img src={configs.getAssetUrl() + this.state.userDetail.image_url || ''}
                                    onLoad={(e) => {
                                        e.currentTarget.style.opacity = '1';
                                    }}
                                />}
                                <input ref={this.inputFileRef} hidden type="file" name="file" id="file" className="inputfile" onChange={this.pickFile} />
                                
                                { this.state.uploadingProfile && <motion.div className='bg-dim-backdrop'>
                                    <motion.div className='loadingCenter'>
                                        <LoadingIcon size={30}/>
                                    </motion.div>
                                </motion.div>}
                            </motion.div>
                            <br/> 
                        </motion.div>

                        <motion.div className='inputBox'
                            // layoutId={`actor-modal-a${this.props.actor_id}-firstname`}
                        >
                                <TextBox 
                                    placeholder='First Name'
                                    style={{
                                        paddingRight: 0
                                    }}
                                    value={this.state.firstname}
                                    onChange={(value: string) => {
                                        this.setState({
                                            firstname: value
                                        })
                                    }}
                                />
                            </motion.div>
                            <motion.div className='inputBox'
                                // layoutId={`actor-modal-a${this.props.actor_id}-lastname`}
                            >
                                <TextBox 
                                    placeholder='Last Name'
                                    style={{
                                        paddingRight: 0
                                    }}
                                    value={this.state.lastname}
                                    onChange={(value: string) => {
                                        this.setState({
                                            lastname: value
                                        })
                                    }}
                                />
                            </motion.div>
                            <br/>
                            <motion.div className='btnBox'>
                                <Button
                                    style={{
                                        width: '100%',
                                        height: 40
                                    }}
                                    onClick={() => {
                                        this.saveActor();
                                    }}
                                >{this.state.saving ? 'Saving...' : 'Save'}</Button>
                                <Button
                                    onClick={() => {
                                        this.props.onClose(this.state.shouldRefresh);
                                    }}
                                    style={{
                                        width: '100%',
                                        height: 40,
                                        backgroundColor: 'transparent'
                                    }}
                                >Close</Button>
                            </motion.div>
                        
                    </motion.div>
                </motion.div>
            </motion.div>
        );
    }

}

UserProfileModal.contextType = AppContextState;

export default UserProfileModal;