import * as React from 'react';
import { Link, Navigate } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import PageContainer from '../common/PageContainer';
import { Router, Route } from 'react-router-dom';

import './index.scss';

import { AppContextState, IAppState } from '../context';
import Button from '../common/components/Interactive/Button'
import TextBox from '../common/components/Interactive/Input/TextBox';
import { getData, postData, setCookie } from '../libs/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faPhotoVideo, faUser } from '@fortawesome/free-solid-svg-icons';
import DashboardContainer from '../common/DashboardContainer';
import LoadingIcon from '../common/LoadingIcon';
import VideoPreviewModal from '../common/components/VideoPreview';
import ActorModal from './ActorModal';
import configs from '../configs';

type PageProps = {
};
type PageState = {
    loading: Boolean,
    actorList: Array<Object>,
    crrOpenActor?: string,
    lastKeywordSearch: string
    exiting: boolean,
    targetPath?: string
};
class ActorPage extends React.Component<PageProps, PageState, IAppState> {
    context!: IAppState;

    state = {
        loading: true,
        actorList: [],
        crrOpenActor: undefined,
        lastKeywordSearch: '',
        exiting: false,
        targetPath: undefined
    };

    componentDidMount() {
        this.getActorList();
    }

    componentWillUnmount(): void {
        
    }

    componentDidUpdate(prevProps: Readonly<PageProps>, prevState: Readonly<PageState>, snapshot?: IAppState | undefined): void {
        // console.log(this.context.keywordSearchInput, this.state.lastKeywordSearch)
        if(this.context.keywordSearchInput !== this.state.lastKeywordSearch) {
            this.setState({
                loading: true,
                lastKeywordSearch: this.context.keywordSearchInput || ''
            });
            this.getActorList();
        }
    }

    getActorList = async () => {
        let result = await getData('/api/actor/getActorList?project_id=' + this.context.project.project?.project_id + '&keywords=' + this.context.keywordSearchInput)
        this.setState({
            loading: false
        })
        if(result.status === 200) {
            const data = await result.json();
            if(data.actors) {
                
                // this.setState({
                //     videoList: data.files.map((item: any) => {
                //         return {
                //             title: item.file.title,
                //             duration: item.file.duration || 0,
                //             description: item.file.description || '',
                //             tags: item.tags.map((tag: any) => tag.tag.name ?? ''),
                //             source: "",
                //             thumbnail: item.file.thumbnail,
                //             ...item
                //         };
                //     })
                // })
                this.setState({
                    actorList: data.actors
                })
            }
        }
    }

    render() {
        return (
            <>
                {this.state.exiting && this.state.targetPath && (
                    <Navigate to={this.state.targetPath} replace={true} />
                )}
                <motion.div className="timeline"
                    initial={{ opacity: 0 }}
                    animate={{
                        opacity: 1,
                        transition: {
                            duration: 0.5,
                        }
                    }}
                    exit={{
                        opacity: 0,
                    }}
                >
                    <motion.div style={{
                        display: 'flex',
                        alignItems: 'center',
                    }}>
                        <p className='title'>Sorted A-Z</p>
                        { this.context.project != null && this.context.project!.rolePower! >= 2 &&
                            <motion.div style={{
                                    paddingTop: 2,
                                    marginLeft: 20,
                                    height: 30,
                                }}
                                layoutId={'actor-modal-a0-panel'}
                            >
                                <Button
                                    onClick={() => {
                                        this.setState({
                                            crrOpenActor: '0'
                                        })
                                    }}
                                >Add actor +</Button>
                            </motion.div>
                        }
                    </motion.div>
                    { this.state.crrOpenActor && <ActorModal actor_id={this.state.crrOpenActor} onClose={(shouldRefresh: boolean) => {
                        this.setState({
                            crrOpenActor: undefined
                        })
                        this.getActorList();
                    } }/>}
                    { this.state.loading && <div className='loadingCenter'><LoadingIcon size={30}/></div>}
                    { !this.state.loading && this.state.actorList.length === 0 && <div className="no-footage" style={{
                        position: 'relative',
                        width: '100%',
                        height: '50vh',
                        opacity: .5
                    }}>
                        <div className='centerContain'>
                            <FontAwesomeIcon icon={faUser} fontSize={50}/>
                            <br/><br/>
                            <h1>No actor found</h1>
                        </div>
                    </div>}
                    { !this.state.loading && this.state.actorList.length > 0 &&
                    
                        <motion.div
                            className='actor-list'
                            initial={{ opacity: 0 }}
                            animate={{
                                opacity: 1,
                                transition: {
                                    duration: 0.5,
                                    delay: 0.2
                                }
                            }}
                            exit={{
                                opacity: 0,
                            }}
                        >
                            {this.state.actorList.map((item: any, index: number) => {
                                return <motion.div key={'actor-page-'+item.actor_id}
                                    className='actor-item'
                                    style={{
                                        opacity: this.state.crrOpenActor == item.actor_id ? 0 : 1
                                    }}
                                    onClick={() => {
                                        if(this.context.project != null && this.context.project!.rolePower! >= 2) {
                                            this.setState({
                                                crrOpenActor: item.actor_id
                                            })
                                        }
                                    }}
                                >
                                    <motion.div className='sub'
                                        layoutId={'actor-modal-a' + item.actor_id + '-panel'}
                                    >
                                        <motion.div
                                            className='circle-image'
                                            layoutId={'actor-modal-a' + item.actor_id + '-image'}
                                            style={{
                                                backgroundImage: 'url(' + configs.getAssetUrl() + item.image_url + ')'
                                            }}
                                        ></motion.div>

                                        <motion.div className='info'>
                                            <motion.p layoutId={'actor-modal-a' + item.actor_id + '-firstname'} className='actor-name'>{item.firstname + ' ' + item.lastname}</motion.p>
                                            <motion.p layoutId={'actor-modal-a' + item.actor_id + '-lastname'} className='detail'
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    this.setState({
                                                        targetPath: '/dashboard',
                                                        exiting: true
                                                    })
                                                    this.context.setSearchKeyword(item.actor_tag_map[0].tag.name);
                                                }}
                                            >{`In ${item.actor_tag_map[0].tag.tag_map.length} video${item.actor_tag_map[0].tag.tag_map.length > 1 ? 's' : ''}`}</motion.p>
                                        </motion.div>
                                    </motion.div>
                                </motion.div>
                            })}
                        </motion.div>
                        
                    }
                </motion.div>
            </>
        );
    }
}

ActorPage.contextType = AppContextState;

export default ActorPage;