import * as React from 'react';
import { Link, Navigate } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import PageContainer from '../../PageContainer';
import { Router, Route } from 'react-router-dom';

import './index.scss';

import { AppContextState, IAppState } from '../../../context';
import Button from '../Interactive/Button'
import TextBox from '../Interactive/Input/TextBox';
import { getData, postData, setCookie, displayFilesSizeFormat, postUplaodFile, sleep, deleteData, putData } from '../../../libs/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faImage, faDownload, faTrash } from '@fortawesome/free-solid-svg-icons';
import DashboardContainer from '../../DashboardContainer';
import VideoList from '../Video-List';
import LoadingIcon from '../../LoadingIcon';
import ReactPlayer from 'react-player';
import configs from '../../../configs';
import { FileUploader } from 'react-drag-drop-files';
import RichBox from '../Interactive/Input/RichBox';

const imageTypes = [
    'jpeg',
    'jpg'
];

type PageProps = {
    onClose: (shouldRefresh: boolean) => void
    
};
type PageState = {
    loading: Boolean,
    teamDetail: any
    files: any[]
    project_name: string,
    project_description: string,
    project_logo: string,
    invite_code: string,
    saving: boolean
    shouldRefresh: boolean
    uploadingProfile: boolean
    uploadingSample: boolean
    enableUploadSample: boolean
};
class TeamProfileModal extends React.Component<PageProps, PageState, IAppState> {
    context!: IAppState;

    state = {
        loading: true,
        teamDetail: {
            actor_id: 0,
            project_id: 0,
            firstname: "",
            lastname: "",
            image_url: null,
            created_date: null,
            actor_tag_map: [],
            projects: {},
            raw_data: null,
        },
        files: [],
        project_name: "",
        project_description: "",
        project_logo: '',
        invite_code: "",
        saving: false,
        shouldRefresh: false,
        uploadingProfile: false,
        uploadingSample: false,
        enableUploadSample: true
    };

    constructor(props: any) {
        super(props);
    }

    componentDidMount() {
        this.getteamDetail();
    }

    componentWillUnmount(): void {
        
    }

    async getteamDetail() {
        await this.context.refreshSession();
        await sleep(100);
        this.setState({
            loading: false,
            project_name: this.context.project.project?.project_name || '',
            project_description: this.context.project.project?.project_description || '',
            teamDetail: {
                image_url: this.context.project.project?.project_logo || '',
            }
        })
    }

    pickFile = (e: any) => {
        const files = e.target.files;
        this.setState({
            files: files
        })

        setTimeout(() => {
            this.uploadImage();
        }, 100);
    }

    uploadImage = async () => {
        if(this.state.files.length > 0) {
            this.setState({
                uploadingProfile: true
            })
            let formData = new FormData();
            formData.append('file', this.state.files[0]);
            formData.append('project_id', (this.context.project.project?.project_id || '').toString());
            let result = await postUplaodFile('/api/team/uploadTeamProfile', formData, (progressEvent: any) => {
                
            });
            if(result.status === 200) {
                const data = await result.json();
                await this.getteamDetail();
            }
            await sleep(1000);
            this.setState({
                uploadingProfile: false
            })
        }
    }

    inputFileRef = React.createRef<HTMLInputElement>();

    saveActor = async () => {
        this.setState({
            saving: true
        })
        let result = await putData('/api/team/update', {
            project_id: this.context.project.project?.project_id,
            project_name: this.state.project_name,
            project_description: this.state.project_description,
        })
        this.setState({
            saving: false,
            shouldRefresh: true
        })
        if(result.status === 200) {
            const data = await result.json();
            await this.getteamDetail();
        }
        // this.props.onClose(this.state.shouldRefresh);
    }

    render() {
        // console.log(this.props)


        return (
            <motion.div className='TeamProfileModal'>
                <motion.div className='modalBg'
                    initial={{ opacity: 0 }}
                    animate={{
                        opacity: 1,
                        transition: {
                            duration: 0.3,
                        }
                    }}
                    exit={{
                        opacity: 0,
                    }}
                    onClick={() => {
                        this.props.onClose(this.state.shouldRefresh);
                    }}
                ></motion.div>
                <motion.div className='modalContent'>
                    <motion.div className='sub'
                        layoutId={`modal-user-option-mainUserOption`}
                    >
                        <p className='title'>Team Information</p>
                        <motion.div className='sidePanel'>      
                            <motion.div className='circleImage' onClick={() => {
                                this.inputFileRef.current?.click();
                            }}
                            // layoutId={`actor-modal-a${this.props.actor_id}-image`}
                            >
                                {/* {!this.state.teamDetail.image_url &&
                                <motion.div className='centerContain'>
                                    <FontAwesomeIcon icon={faImage} />
                                </motion.div>} */}
                                {/* {this.state.teamDetail.image_url && */}
                                <motion.img src={this.state.teamDetail.image_url ? configs.getAssetUrl() + this.state.teamDetail.image_url : 'https://gravatar.com/avatar/' + this.context.project.project?.project_id + '?s=400&d=identicon&r=x'}
                                    onLoad={(e) => {
                                        e.currentTarget.style.opacity = '1';
                                    }}
                                />
                                <input ref={this.inputFileRef} hidden type="file" name="file" id="file" className="inputfile" onChange={this.pickFile} />
                                
                                { this.state.uploadingProfile && <motion.div className='bg-dim-backdrop'>
                                    <motion.div className='loadingCenter'>
                                        <LoadingIcon size={30}/>
                                    </motion.div>
                                </motion.div>}
                            </motion.div>
                            <br/> 
                        </motion.div>

                        <motion.div className='inputBox'
                            // layoutId={`actor-modal-a${this.props.actor_id}-firstname`}
                        >
                                <TextBox 
                                    placeholder='Team Name'
                                    style={{
                                        paddingRight: 0
                                    }}
                                    value={this.state.project_name}
                                    onChange={(value: string) => {
                                        this.setState({
                                            project_name: value
                                        })
                                    }}
                                />
                            </motion.div>
                            <motion.div className='inputBox'
                                // layoutId={`actor-modal-a${this.props.actor_id}-lastname`}
                                style={{
                                    height: 100
                                }}
                            >

                                <RichBox
                                    placeholder='Description'
                                    style={{
                                        paddingRight: 0,
                                        resize: 'none',
                                    }}
                                    value={this.state.project_description}
                                    onChange={(value: string) => {
                                        this.setState({
                                            project_description: value
                                        })
                                }}/>
                            </motion.div>
                            <br/>
                            <motion.div className='btnBox'>
                                <Button
                                    style={{
                                        width: '100%',
                                        height: 40
                                    }}
                                    onClick={() => {
                                        this.saveActor();
                                    }}
                                >{this.state.saving ? 'Saving...' : 'Save'}</Button>
                                <Button
                                    onClick={() => {
                                        this.props.onClose(this.state.shouldRefresh);
                                    }}
                                    style={{
                                        width: '100%',
                                        height: 40,
                                        backgroundColor: 'transparent'
                                    }}
                                >Close</Button>
                            </motion.div>
                        
                    </motion.div>
                </motion.div>
            </motion.div>
        );
    }

}

TeamProfileModal.contextType = AppContextState;

export default TeamProfileModal;