import configs from "../configs";
import { IUser } from "../context";

export class RandomColorLuminance {
    originalHex: string
    constructor(hex?: string) {
        this.originalHex = hex || "";
        if(!this.originalHex) this.originalHex = this.getRandomRolor();
    }

    // From https://stackoverflow.com/questions/20114469/javascript-generate-random-dark-color
    public getRandomRolor(): string {
        var letters: Array<string> = '0123456789ABCDEF'.split('');
        var color: string = '#';
        for (var i = 0; i < 6; i++) {
            color += letters[Math.round(Math.random() * 15)];
        }
        return color;
    }

    public ColorLuminance(hex: string = this.originalHex, lum: number = -0.2): string {
        // validate hex string
        hex = String(hex).replace(/[^0-9a-f]/gi, '');
        if (hex.length < 6) {
            hex = hex[0]+hex[0]+hex[1]+hex[1]+hex[2]+hex[2];
        }
        lum = lum || 0;
      
        // convert to decimal and change luminosity
        var rgb: string = "#", c, i;
        for (i = 0; i < 3; i++) {
            c = parseInt(hex.substr(i*2,2), 16);
            c = Math.round(Math.min(Math.max(0, c + (c * lum)), 255)).toString(16);
            rgb += ("00"+c).substr(c.length);
        }
      
        return rgb;
    }
}

export class secondToTimeFormat {
    sec = 0
    constructor(second: any) {
        this.sec = second;
    }

    toMinute() {
        return Math.floor(this.sec / 60)
    }

    toHour() {
        return Math.floor(this.toMinute() / 60)
    }

    toADay() {
        return Math.floor(this.toHour() / 24)
    }

    toMonth() {
        return Math.floor(this.toADay() / 30)
    }

    toString() {
        let second = this.sec % 60,
            minute = this.toMinute(),
            hour = this.toHour(),
            day = this.toADay(),
            month = this.toMonth(),
            strFormat = "";
        strFormat += `${month ? month + 'M ' : ''}`
        strFormat += `${day ? day + 'D ' : ''}`
        strFormat += `${hour ? hour + ':' : ''}`
        strFormat += `${minute}:${pad(second, 2)}`
        return strFormat
    }
}

export function sleep(ms: any) {
    return new Promise(resolve => {
        setTimeout(resolve, ms);
    })
}

export function pad(num: any, size: any) {
    num = num.toString();
    while (num.length < size) num = "0" + num;
    return num;
}

export function checkIsMobile() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}

export function postData(url: string, data: any, mode: RequestMode = 'cors') : any {
    return new Promise((resolve, reject) => {
        fetch(configs.apiUrl + url, {
            body: JSON.stringify(data),
            //credentials: 'include',
            headers: {
                'content-type': 'application/json',
                'Authorization': 'Bearer ' + getCookie('token'), 
            },
            mode: mode,
            method: 'POST'
        }).then((res) => {
            resolve({
                status: res.status,
                json: res.json.bind(res)
            });
        }).catch((err) => {
            resolve({
                status: 500,
                message: err
            });
        })
    });
}

export function getData(url: string, mode: RequestMode = 'cors') : any {
    return new Promise((resolve, reject) => {
        fetch(configs.apiUrl + url, {
            method: 'GET',
            //credentials: 'include',
            headers: {
                'content-type': 'application/json',
                'Authorization': 'Bearer ' + getCookie('token'), 
            },
            mode: mode,
        }).then((res) => {
            resolve({
                status: res.status,
                json: res.json.bind(res)
            });
        }).catch((err) => {
            resolve({
                status: 500,
                message: err
            });
        })
    });
}

export function putData(url: string, data: any, mode: RequestMode = 'cors') : any {
    return new Promise((resolve, reject) => {
        fetch(configs.apiUrl + url, {
            body: JSON.stringify(data),
            //credentials: 'include',
            headers: {
                'content-type': 'application/json',
                'Authorization': 'Bearer ' + getCookie('token'), 
            },
            method: 'PUT',
            mode: mode,
        }).then((res) => {
            resolve({
                status: res.status,
                json: res.json.bind(res)
            });
        }).catch((err) => {
            resolve({
                status: 500,
                message: err
            });
        })
    });
}

export function deleteData(url: string, mode: RequestMode = 'cors') : any {
    return new Promise((resolve, reject) => {
        fetch(configs.apiUrl + url, {
            //credentials: 'include',
            method: 'DELETE',
            headers: {
                'content-type': 'application/json',
                'Authorization': 'Bearer ' + getCookie('token'), 
            },
            mode: mode,
        }).then((res) => {
            resolve({
                status: res.status,
                json: res.json.bind(res)
            });
        }).catch((err) => {
            resolve({
                status: 500,
                message: err
            });
        })
    });
}

export function postUplaodFile(url: string, formData: any, onProgess: any, mode: RequestMode = 'cors') : any {
    return new Promise((resolve, reject) => {
        let xhr = new XMLHttpRequest();
        xhr.open('POST', configs.apiUrl + url, true);
        xhr.setRequestHeader('Authorization', 'Bearer ' + getCookie('token'));
        xhr.upload.onprogress = onProgess;
        xhr.onload = function () {
            if (this.status === 200) {
                resolve({
                    status: this.status,
                    json: () => {
                        return JSON.parse(this.response);
                    }
                });
            } else {
                resolve({
                    status: this.status,
                    message: this.response
                });
            }
        };

        xhr.onerror = function () {
            resolve({
                status: this.status,
                message: this.response
            });
        };
        xhr.send(formData);
    });
}

export function getCookie(name: string) {
    let value = "; " + document.cookie;
    let parts = value.split("; " + name + "=");

    if (parts.length === 2) {
        return parts.pop()?.split(";").shift();
    }
}

export function setCookie(name: string, value: string, days: number) {
    let expires = "";
    if (days) {
        let date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 *1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/"
}

export function deleteCookie(name: string) {
    document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

export async function checkSession(): Promise<IUser | boolean> {
    let result = await getData('/api/user/checkSession');
    if (result.status === 200) {
        let data = await result.json();
        return data['user'];
    } else if (result.status === 500) {
        return true;
    }
    return false;
}

export function getRolePowerName(rolePower: number) {
    switch(rolePower) {
        case -1: return 'Pending';
        case 0: return 'Guest';
        case 1: return 'Editor';
        case 2: return 'Reporter';
        case 3: return 'Admin';
        default: return 'Unknown';
    }
}

export function displayFilesSizeFormat(byte: number) {
    const units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB']
    let index = 0
    while (byte >= 1024) {
      byte /= 1024
      index++
    }
    return `${byte.toFixed(2)} ${units[index]}`
}
