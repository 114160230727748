import * as React from 'react';
import { Link } from 'react-router-dom';
import { AnimatePresence, motion, stagger } from 'framer-motion';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faGithub } from '@fortawesome/free-brands-svg-icons'
import { faAt, faCheckCircle, faPhotoFilm, faPlusCircle, faTimesCircle, faTrash } from '@fortawesome/free-solid-svg-icons'

import { AppContextState, IAppState } from '../../../context';

import { displayFilesSizeFormat, getData, getRolePowerName, postData, putData, secondToTimeFormat, sleep } from '../../../libs/utils';
import ReactPlayer from 'react-player';
import LoadingIcon from '../../LoadingIcon';
import { OnProgressProps } from 'react-player/base';
import Button from '../../components/Interactive/Button';
import TextBox from '../../components/Interactive/Input/TextBox';
import configs from '../../../configs';
import DashboardContainer from '../../DashboardContainer';

type Props = {
    crrSelectedKey?: string
};
type State = {
    keyword: string
    addingTag: boolean,
    submitting: boolean
};
class SubmitPanel extends React.Component<Props, State, IAppState> {
    context!: IAppState;

    state: State = {
        keyword: '',
        addingTag: false,
        submitting: false
    };


    componentDidMount(): void {
        const targetVidel = this.context.uploadList.find((item: any) => item.key === this.props.crrSelectedKey);
        if(targetVidel) { 
            this.setState({
                keyword: targetVidel.filename
            })
        }
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: IAppState | undefined): void {
        if(prevProps.crrSelectedKey != this.props.crrSelectedKey) {
            const targetVidel = this.context.uploadList.find((item: any) => item.key === this.props.crrSelectedKey);
            if(targetVidel) { 
                this.setState({
                    keyword: targetVidel.filename
                })
            } else {
                this.setState({
                    keyword: ''
                })
            }
        }
    }

    async getFileDetail() {
        const targetVidel = this.context.uploadList.find((item: any) => item.key === this.props.crrSelectedKey);
        if(targetVidel && targetVidel.data) {
            let file_id = targetVidel.data.file_id;
            let result = await getData('/api/storage/getFileDetail?file_id=' + file_id)
            console.log('targetVidel', targetVidel)
            if(result.status === 200) {
                const data = await result.json();
                console.log("data", data)
                this.context.updateUploadList(targetVidel.key, {
                    ...targetVidel,
                    filename: data.file.title,
                    data: {
                        ...targetVidel.data,
                        ...data
                    }
                });
            }
        }
    }


    async updateFootage(status: string = 'active') {
        // if(this.state.submitting) return;
        this.setState({
            submitting: true
        })
        const targetVidel = this.context.uploadList.find((item: any) => item.key === this.props.crrSelectedKey);
        if(targetVidel) {
            let result = await putData('/api/storage/updateFile', {
                file_id: targetVidel.data.file_id,
                title: this.state.keyword,
                status: status,
            })

            if(result.status === 200 && status === 'active') {
                const data = await result.json();
                this.context.removeUploadList(targetVidel.key);
                console.log(data)
            }
            this.getFileDetail();
        }

        this.setState({
            submitting: false
        })

        await sleep(500);
        if(this.context.uploadList.length === 0) {
            DashboardContainer.toggleModalUploader(false);
            this.context.setLastContentUpdate(Date.now());
        }
    }

    render() {
        // const { textColor, crrFeature, isToggleNav }: IAppState = this.context;
        // console.log(this.context)

        const { auth, project, uploadList } = this.context;

        const targetVidel = uploadList.find((item: any) => item.key === this.props.crrSelectedKey);

        // console.log(targetVidel)

        // targetVidel.data.raw_data

        return (
            <motion.div id="submitPanel">
                {(!targetVidel || !targetVidel.data) && <motion.div className=''>
                <motion.h1 className="topic">Information</motion.h1>
                </motion.div>}
                {targetVidel && targetVidel.data && <>
                    <motion.div className='tag-top-panel'>
                        <TextBox placeholder='Video Name' onChange={(value) => {
                                this.setState({
                                    keyword: value
                                })
                            }}
                            value={this.state.keyword}
                            style={{
                                width: '100%',
                                paddingRight: 0
                            }}
                            onBlur={() => {
                                this.updateFootage('inactive')
                            }}
                        />
                            
                    </motion.div>
                    <motion.h1 className="topic">Tag Timeline</motion.h1>
                    <motion.div className='info-data'>
                        <motion.p className='name'>Duration</motion.p>
                        <motion.p className='data'>{
                            new secondToTimeFormat(targetVidel?.data?.duration || 0).toString()
                        }</motion.p>
                    </motion.div>
                    <motion.div className='info-data'>
                        <motion.p className='name'>Size</motion.p>
                        <motion.p className='data'>{
                            displayFilesSizeFormat(targetVidel?.data?.size || 0)
                        }</motion.p>
                    </motion.div>
                    
                    <motion.div className='tag-bottom-panel'>
                        <Button
                            style={{
                                width: '100%',
                                fontSize: '1.1rem',
                                pointerEvents: this.state.submitting ? 'none' : 'all'
                            }}
                            onClick={() => {
                                // if(this.state.submitting) return;
                                console.log("active")
                                this.updateFootage();
                            }}
                        >{ this.state.submitting ? 'Sending...' : 'Submit'}</Button>
                    </motion.div>
                </>}
            </motion.div>
        );
    }

}

SubmitPanel.contextType = AppContextState;

export default SubmitPanel;