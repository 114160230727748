import * as React from 'react';
import { Link } from 'react-router-dom';
import { AnimatePresence, motion, stagger } from 'framer-motion';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faGithub } from '@fortawesome/free-brands-svg-icons'
import { faAt, faPhotoFilm } from '@fortawesome/free-solid-svg-icons'

import { AppContextState, IAppState } from '../../../../context';

import configs from '../../../../configs';

import './index.scss';
import { getRolePowerName } from '../../../../libs/utils';

type Props = {
    pageTitle?: string,
    onToggleNav?: () => void
    onUploadClick?: () => void
};
type State = {
    
};
class HeaderUploader extends React.Component<Props, State, IAppState> {
    context!: IAppState;

    state: State = {
        
    };

    containerVariant = {
        hidden: { 
            
        },
        show: {
            // transition: {
            //     staggerChildren: .2,
            //     staggerDirection: 1
            // }
        }
    }

    componentDidMount(): void {
        
    }

    searchInterval: any = null;

    onSearchHandler = (e: React.ChangeEvent<HTMLInputElement>) => {

        if(this.searchInterval) {
            clearTimeout(this.searchInterval);
        }

        this.searchInterval = setTimeout(() => {
            console.log(e.target.value)
            this.context.setSearchKeyword(e.target.value);

            clearTimeout(this.searchInterval);
        }, 500)
    }

    render() {
        // const { textColor, crrFeature, isToggleNav }: IAppState = this.context;
        // console.log(this.context)

        const { auth, project } = this.context;


        return (
            <>
                <motion.div className='HeaderUploader'
                    // layoutId='page-HeaderUploader'
                >
                    <motion.div className='sub HeaderUploader-context'>
                        

                        <motion.div className='userPanel'>
                            <motion.div className='userContainer'>
                                <motion.div className='userImg'
                                    style={{
                                        backgroundImage: `url("${configs.getAssetUrl() + this.context.auth.user?.image_url || 'https://avatars.githubusercontent.com/u/46596082?v=4'}")`
                                    }}
                                ></motion.div>
                                <motion.div className='userName'>
                                    <p className='name'>{auth.user?.username || 'Username'}</p>
                                    <p className='role'>{getRolePowerName(project.rolePower || 0)}</p>
                                </motion.div>
                            </motion.div>
                        </motion.div>
                    </motion.div>
                </motion.div>
            </>
        );
    }

}

HeaderUploader.contextType = AppContextState;

export default HeaderUploader;