import * as React from 'react';
import { Link, Navigate } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import PageContainer from '../../common/PageContainer';
import { Router, Route } from 'react-router-dom';

import './index.scss';

import { AppContextState, IAppState } from '../../context';
import Button from '../../common/components/Interactive/Button'
import TextBox from '../../common/components/Interactive/Input/TextBox';
import { getData, postData, setCookie, displayFilesSizeFormat, postUplaodFile, sleep, deleteData } from '../../libs/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faImage, faDownload, faTrash } from '@fortawesome/free-solid-svg-icons';
import DashboardContainer from '../../common/DashboardContainer';
import VideoList from '../../common/components/Video-List';
import LoadingIcon from '../../common/LoadingIcon';
import ReactPlayer from 'react-player';
import configs from '../../configs';
import { FileUploader } from 'react-drag-drop-files';

const imageTypes = [
    'jpeg',
    'jpg'
];

type PageProps = {
    actor_id: string
    onClose: (shouldRefresh: boolean) => void
    
};
type PageState = {
    loading: Boolean,
    actorDetail: any
    files: any[]
    firstname: string
    lastname: string
    saving: boolean
    shouldRefresh: boolean
    uploadingProfile: boolean
    uploadingSample: boolean
    enableUploadSample: boolean
};
class ActorModal extends React.Component<PageProps, PageState, IAppState> {
    context!: IAppState;

    state = {
        loading: true,
        actorDetail: {
            actor_id: 0,
            project_id: 0,
            firstname: "",
            lastname: "",
            image_url: null,
            created_date: null,
            actor_tag_map: [],
            projects: {},
            raw_data: null,
        },
        files: [],
        firstname: '',
        lastname: '',
        saving: false,
        shouldRefresh: false,
        uploadingProfile: false,
        uploadingSample: false,
        enableUploadSample: true
    };

    constructor(props: any) {
        super(props);
        this.SampleItem = this.SampleItem.bind(this);
    }

    componentDidMount() {
        this.getActorDetail();
    }

    componentWillUnmount(): void {
        
    }

    async getActorDetail() {
        let result = await getData('/api/actor/getActorDetail?actor_id=' + this.props.actor_id)
        if(result.status === 200) {
            const data = await result.json();
            if(data.actor === null) {
                return;
            }
            this.setState({
                loading: false,
                actorDetail: data.actor,
                firstname: data.actor.firstname,
                lastname: data.actor.lastname
            })
        }
    }

    pickFile = (e: any) => {
        const files = e.target.files;
        this.setState({
            files: files
        })

        setTimeout(() => {
            this.uploadImage();
        }, 100);
    }

    uploadImage = async () => {
        if(this.state.files.length > 0) {
            this.setState({
                uploadingProfile: true
            })
            let formData = new FormData();
            formData.append('file', this.state.files[0]);
            formData.append('actor_id', this.props.actor_id);
            let result = await postUplaodFile('/api/actor/uploadImage', formData, (progressEvent: any) => {
                
            });
            if(result.status === 200) {
                const data = await result.json();
                this.setState({
                    actorDetail: {
                        ...this.state.actorDetail,
                        image_url: data.image_url
                    },
                    shouldRefresh: true
                })
            }
            await sleep(1000);
            this.setState({
                uploadingProfile: false
            })
        }
    }

    inputFileRef = React.createRef<HTMLInputElement>();

    saveActor = async () => {
        this.setState({
            saving: true
        })
        let result = await postData('/api/actor/updateActor', {
            actor_id: this.props.actor_id,
            firstname: this.state.firstname,
            lastname: this.state.lastname
        })
        this.setState({
            saving: false,
            shouldRefresh: true
        })
        if(result.status === 200) {
            const data = await result.json();
            this.setState({
                actorDetail: data.actor,
                firstname: data.actor.firstname,
                lastname: data.actor.lastname
            })
        }
        this.props.onClose(this.state.shouldRefresh);
    }

    createActor = async () => {
        this.setState({
            saving: true
        })
        let result = await postData('/api/actor/createActor', {
            project_id: this.context.project.project?.project_id,
            firstname: this.state.firstname,
            lastname: this.state.lastname
        })
        this.setState({
            saving: false,
            shouldRefresh: true
        })
        if(result.status === 200) {
            const data = await result.json();
            this.setState({
                actorDetail: data.actor,
                firstname: data.actor.firstname,
                lastname: data.actor.lastname
            })
        }
        this.props.onClose(this.state.shouldRefresh);
    }

    uploadSampleImage = async (sampleList: any[]) => {
        console.log(sampleList)
        if(sampleList.length > 0) {
            this.setState({
                uploadingSample: true
            })
            let formData = new FormData();
            for(let i = 0; i < sampleList.length; i++) {
                let item = sampleList[i];
                formData.append(`files[${i}]`, item);
            }
            formData.append('actor_id', this.props.actor_id);
            let result = await postUplaodFile('/api/actor/uploadFaceSampleImage', formData, (progressEvent: any) => {
                
            });
            if(result.status === 200) {
                const data = await result.json();
                this.getActorDetail();
            }

            await sleep(1000);
            this.setState({
                uploadingSample: false
            })
        }
    }

    async deleteActor() {
        if(window.confirm('Are you sure to delete this actor?') === false) {
            return;
        }

        let result = await deleteData('/api/actor/deleteActor?actor_id=' + this.props.actor_id);
        if(result.status === 200) {
            const data = await result.json();
            this.setState({
                shouldRefresh: true
            })
            this.props.onClose(this.state.shouldRefresh);
        }
    }

    render() {
        // console.log(this.props)


        return (
            <motion.div className='actorModal'>
                <motion.div className='modalBg'
                    initial={{ opacity: 0 }}
                    animate={{
                        opacity: 1,
                        transition: {
                            duration: 0.3,
                        }
                    }}
                    exit={{
                        opacity: 0,
                    }}
                    onClick={() => {
                        this.props.onClose(this.state.shouldRefresh);
                    }}
                ></motion.div>
                <motion.div className='modalContent'>
                    <motion.div className='sub'
                        layoutId={`actor-modal-a${this.props.actor_id}-panel`}
                    >
                        {this.props.actor_id !== '0' && <>
                            <motion.button className='deleteBtn'
                                initial={{
                                    opacity: 0,
                                }}
                                animate={{
                                    opacity: 1,
                                    transition: {
                                        duration: .5,
                                        delay: .25,
                                    }
                                }}
                                onClick={() => {
                                    this.deleteActor();
                                }}
                            >
                                <FontAwesomeIcon icon={faTrash} />
                            </motion.button>
                        </>}
                        <motion.div className='sidePanel'>                            
                            {this.props.actor_id !== '0' && <>
                                <motion.div className='circleImage' onClick={() => {
                                    this.inputFileRef.current?.click();
                                }}
                                layoutId={`actor-modal-a${this.props.actor_id}-image`}
                                >
                                    {!this.state.actorDetail.image_url &&
                                    <motion.div className='centerContain'>
                                        <FontAwesomeIcon icon={faImage} />
                                    </motion.div>}
                                    {this.state.actorDetail.image_url &&
                                    <motion.img src={configs.getAssetUrl() + this.state.actorDetail.image_url || ''}
                                        onLoad={(e) => {
                                            e.currentTarget.style.opacity = '1';
                                        }}
                                    />}
                                    <input ref={this.inputFileRef} hidden type="file" name="file" id="file" className="inputfile" onChange={this.pickFile} />
                                    
                                    { this.state.uploadingProfile && <motion.div className='bg-dim-backdrop'>
                                        <motion.div className='loadingCenter'>
                                            <LoadingIcon size={30}/>
                                        </motion.div>
                                    </motion.div>}
                                </motion.div>
                                <br/> 
                            </>}
                        </motion.div>

                        <motion.div className='inputBox'
                            layoutId={`actor-modal-a${this.props.actor_id}-firstname`}
                        >
                                <TextBox 
                                    placeholder='First Name'
                                    style={{
                                        paddingRight: 0
                                    }}
                                    value={this.state.firstname}
                                    onChange={(value: string) => {
                                        this.setState({
                                            firstname: value
                                        })
                                    }}
                                />
                            </motion.div>
                            <motion.div className='inputBox'
                                layoutId={`actor-modal-a${this.props.actor_id}-lastname`}
                            >
                                <TextBox 
                                    placeholder='Last Name'
                                    style={{
                                        paddingRight: 0
                                    }}
                                    value={this.state.lastname}
                                    onChange={(value: string) => {
                                        this.setState({
                                            lastname: value
                                        })
                                    }}
                                />
                            </motion.div>
                            <br/>
                            {this.props.actor_id !== '0' &&<>
                                <h3>Sample Images: (Can't delete after upload)</h3>
                                <motion.div className='sub'>
                                    <FileUploader 
                                        handleChange={(files: any) => {
                                            this.uploadSampleImage(files);
                                        }}
                                        multiple={true}
                                        name="files"
                                        disabled={!this.state.enableUploadSample}
                                        types={imageTypes}

                                    z>
                                        
                                        <motion.div className='sampleList'>
                                            <this.SampleItem />
                                        </motion.div>
                                    </FileUploader>
                                    { this.state.uploadingSample && <motion.div className='bg-dim-backdrop'>
                                        <motion.div className='loadingCenter'>
                                            <LoadingIcon size={30}/>
                                        </motion.div>
                                    </motion.div>}
                                </motion.div>
                            </>}
                            <br/>
                            <motion.div className='btnBox'>
                                <Button
                                    style={{
                                        width: '100%',
                                        height: 40
                                    }}
                                    onClick={() => {
                                        if(this.props.actor_id === '0') {
                                            this.createActor();
                                        } else {
                                            this.saveActor();
                                        }
                                    }}
                                >{this.props.actor_id === '0' ? 'Create Actor' : 'Save'}</Button>
                                <Button
                                    onClick={() => {
                                        this.props.onClose(this.state.shouldRefresh);
                                    }}
                                    style={{
                                        width: '100%',
                                        height: 40,
                                        backgroundColor: 'transparent'
                                    }}
                                >Close</Button>
                            </motion.div>
                        
                    </motion.div>
                </motion.div>
            </motion.div>
        );
    }

    SampleItem() {
        if(this.state.actorDetail.raw_data === null) {
            return <>
                <div className='centerContain'>
                    Drop jpeg files here
                </div>
            </>;
        }

        let raw_data = JSON.parse(this.state.actorDetail.raw_data);
        let sampleList = raw_data['sampleList'] || [];
        console.log(sampleList)
        return <>
            {sampleList.map((item: any, index: number) => {
                return <motion.div className='sampleItem' key={'sample-files-actor-'+index}
                    onHoverStart={() => {
                        this.setState({
                            enableUploadSample: false
                        })
                    }}
                    onHoverEnd={() => {
                        this.setState({
                            enableUploadSample: true
                        })
                    }}
                    onClick={(e: any) => {
                        window.open(configs.getAssetUrl() + item, '_blank');
                    }}
                >
                    <span>Sample {index + 1}</span>
                    <FontAwesomeIcon icon={faDownload} />
                </motion.div>
            })}
        </>;
    }
}

ActorModal.contextType = AppContextState;

export default ActorModal;