import * as React from 'react';
import { Link } from 'react-router-dom';
import { AnimatePresence, motion, stagger } from 'framer-motion';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faGithub } from '@fortawesome/free-brands-svg-icons'
import { faAt, faCheckCircle, faPhotoFilm, faPlusCircle, faTimesCircle, faTrash } from '@fortawesome/free-solid-svg-icons'

import { AppContextState, IAppState } from '../../../context';

import { getData, getRolePowerName, postData, secondToTimeFormat } from '../../../libs/utils';
import ReactPlayer from 'react-player';
import LoadingIcon from '../../LoadingIcon';
import { OnProgressProps } from 'react-player/base';
import Button from '../../components/Interactive/Button';
import TextBox from '../../components/Interactive/Input/TextBox';
import configs from '../../../configs';

type Props = {
    crrSelectedKey?: string
};
type State = {
    keyword: string
    addingTag: boolean
};
class TagTimeline extends React.Component<Props, State, IAppState> {
    context!: IAppState;

    state: State = {
        keyword: '',
        addingTag: false
    };


    componentDidMount(): void {
        
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: IAppState | undefined): void {
        if(prevProps.crrSelectedKey != this.props.crrSelectedKey) {
            this.getFileDetail();
        }
    }

    async getFileDetail() {
        const targetVidel = this.context.uploadList.find((item: any) => item.key === this.props.crrSelectedKey);
        if(targetVidel && targetVidel.data) {
            let file_id = targetVidel.data.file_id;
            let result = await getData('/api/storage/getFileDetail?file_id=' + file_id)
            console.log('targetVidel', targetVidel)
            if(result.status === 200) {
                const data = await result.json();
                console.log("data", data)
                this.context.updateUploadList(targetVidel.key, {
                    ...targetVidel,
                    data: {
                        ...targetVidel.data,
                        ...data
                    }
                });
            }
        }
    }

    async addTag() {
        if(this.state.keyword === '') return;
        this.setState({
            addingTag: true
        })
        const targetVidel = this.context.uploadList.find((item: any) => item.key === this.props.crrSelectedKey);
        if(targetVidel) {
            let result = await postData('/api/footage/createTag', {
                project_id: this.context.project.project?.project_id,
                tag_name: this.state.keyword
            })
            // console.log(result)
            if(result.status === 200) {
                let data = await result.json();
                let tag_id = data.tag.tag_id;
                let file_id = targetVidel.data.file_id;
                let result2 = await postData('/api/footage/mapTag', {
                    tag_id: tag_id,
                    file_id: file_id,
                    duration: targetVidel.crrDuration
                })
                console.log(result2)
                if(result2.status === 200) {
                    const data2 = await result2.json();
                    console.log(data2)
                    this.getFileDetail();
                }
            }
        }

        this.setState({
            addingTag: true,
            keyword: ''
        })
    }

    async removeTag(id: number) {
        let result = await postData('/api/footage/unmapTag', {
            tag_map_id: id
        })
        console.log(result)
        if(result.status === 200) {
            const data = await result.json();
            console.log(data)
            this.getFileDetail();
        }
    }

    getDuration() {
        const targetVidel = this.context.uploadList.find((item: any) => item.key === this.props.crrSelectedKey);

        if(!targetVidel) return 0;

        return Math.floor(targetVidel.crrDuration || 0);
    }

    render() {
        // const { textColor, crrFeature, isToggleNav }: IAppState = this.context;
        // console.log(this.context)

        const { auth, project, uploadList } = this.context;

        const targetVidel = uploadList.find((item: any) => item.key === this.props.crrSelectedKey);

        // console.log(targetVidel)

        // targetVidel.data.raw_data

        return (
            <motion.div id="tagTimeline">
                <motion.h1 className="topic">Tag Timeline</motion.h1>
                {targetVidel && targetVidel.data && <>
                    <motion.div className='tag-list'>
                        {targetVidel && targetVidel.data && targetVidel.data.tagMap && targetVidel.data.tagMap.map((tagItem: any, index: number) => {
                            // console.log(tagItem)
                            return <motion.div className={'tag-item ' + (this.getDuration() == tagItem.duration ? 'highlight' : '')}
                                key={'tag-item-adder-' + index}
                                onClick={()=>{
                                    window.player.currentTime(tagItem.duration);
                                }}
                            >

                                <motion.p className='time'>{new secondToTimeFormat(tagItem.duration).toString()} - </motion.p>

                                {tagItem.tag.actor_tag_map && tagItem.tag.actor_tag_map.length > 0 &&
                                    <motion.div className='circleImg'>
                                        <img src={configs.getAssetUrl() + tagItem.tag.actor_tag_map[0].actor.image_url} alt=""/>
                                    </motion.div>
                                }

                                <motion.div className='tag-info'>
                                    <motion.p className='tag-name'>{tagItem.tag.name}</motion.p>
                                    <motion.div className='option' onClick={(e)=> {
                                        e.stopPropagation();
                                        this.removeTag(tagItem.id);
                                    }}>
                                        <FontAwesomeIcon icon={faTrash} className='icon'/>
                                    </motion.div>
                                </motion.div>
                            </motion.div>
                        })}
                    </motion.div>

                    <motion.div className='tag-bottom-panel'>
                        <Button className='addTagBtn' onClick={() => {
                            this.addTag();
                        }}
                            style={{
                                backgroundColor: 'transparent'
                            }}
                        >
                                <FontAwesomeIcon icon={faPlusCircle} className='icon'/>
                        </Button>
                        <TextBox placeholder='Add Tag' onChange={(value) => {
                                this.setState({
                                    keyword: value
                                })
                            }}
                            value={this.state.keyword}
                            style={{
                                width: '100%',
                                paddingRight: 0
                            }}
                            onSubmit={() => {
                                this.addTag();
                            }}
                        />
                            
                    </motion.div>
                </>}
            </motion.div>
        );
    }

}

TagTimeline.contextType = AppContextState;

export default TagTimeline;