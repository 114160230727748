import * as React from 'react';
import { Link, Navigate } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import PageContainer from '../../common/PageContainer';
import { Router, Route } from 'react-router-dom';

import './index.scss';

import { AppContextState, IAppState } from '../../context';
import Button from '../../common/components/Interactive/Button'
import TextBox from '../../common/components/Interactive/Input/TextBox';
import { getData, postData, setCookie, displayFilesSizeFormat, postUplaodFile, sleep, deleteData, putData } from '../../libs/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faImage, faDownload, faTrash, faUser } from '@fortawesome/free-solid-svg-icons';
import DashboardContainer from '../../common/DashboardContainer';
import VideoList from '../../common/components/Video-List';
import LoadingIcon from '../../common/LoadingIcon';
import ReactPlayer from 'react-player';
import configs from '../../configs';
import { FileUploader } from 'react-drag-drop-files';

const imageTypes = [
    'jpeg',
    'jpg'
];

type PageProps = {
    member_id: string
    onClose: (shouldRefresh: boolean) => void

};
type PageState = {
    loading: Boolean,
    memberDetail: any
    saving: boolean
    role_power: number
    shouldRefresh: boolean
    uploadingProfile: boolean
    uploadingSample: boolean
    enableUploadSample: boolean
};
class MemberDetailModal extends React.Component<PageProps, PageState, IAppState> {
    context!: IAppState;

    state: PageState = {
        loading: true,
        memberDetail: {
            member_id: 0,
            project_id: 0,
            firstname: "",
            lastname: "",
            image_url: null,
            created_date: null,
            actor_tag_map: [],
            projects: {},
            user: {},
            raw_data: null,
        },
        saving: false,
        role_power: 0,
        shouldRefresh: false,
        uploadingProfile: false,
        uploadingSample: false,
        enableUploadSample: true
    };

    constructor(props: any) {
        super(props);
    }

    componentDidMount() {
        this.getActorDetail();
    }

    componentWillUnmount(): void {

    }

    async getActorDetail() {
        let result = await getData('/api/team/getTeamMemberDetail?user_id=' + this.props.member_id + '&project_id=' + this.context.project.project?.project_id)
        if (result.status === 200) {
            const data = await result.json();
            this.setState({
                loading: false,
                memberDetail: data.member,
                role_power: data.member.role_power
            })
        }
    }


    saveMember = async () => {
        this.setState({
            saving: true
        })
        let result = await putData('/api/team/updateTeamMemberRole', {
            project_id: this.context.project.project?.project_id,
            user_id: this.props.member_id,
            role_power: this.state.role_power
        })
        this.setState({
            saving: false,
            shouldRefresh: true
        })
        if (result.status === 200) {
            const data = await result.json();
            this.setState({
                memberDetail: data.member,
            })
        }
        this.props.onClose(this.state.shouldRefresh);
    }

    async deleteMember() {
        if (window.confirm('Are you sure to delete this member?') === false) {
            return;
        }

        let result = await deleteData('/api/team/deleteTeamMember?user_id=' + this.props.member_id + '&project_id=' + this.context.project.project?.project_id)
        if (result.status === 200) {
            const data = await result.json();
            this.setState({
                shouldRefresh: true
            })
            this.props.onClose(this.state.shouldRefresh);
        }
    }

    render() {
        // console.log(this.props)


        return (
            <motion.div className='memberDetailModal'>
                <motion.div className='modalBg'
                    initial={{ opacity: 0 }}
                    animate={{
                        opacity: 1,
                        transition: {
                            duration: 0.3,
                        }
                    }}
                    exit={{
                        opacity: 0,
                    }}
                    onClick={() => {
                        this.props.onClose(this.state.shouldRefresh);
                    }}
                ></motion.div>
                <motion.div className='modalContent'>
                    <motion.div className='sub'
                        layoutId={`member-modal-a${this.props.member_id}-panel`}
                    >
                        <motion.button className='deleteBtn'
                            initial={{
                                opacity: 0,
                            }}
                            animate={{
                                opacity: 1,
                                transition: {
                                    duration: .5,
                                    delay: .25,
                                }
                            }}
                            onClick={() => {
                                this.deleteMember();
                            }}
                        >
                            <FontAwesomeIcon icon={faTrash} />
                        </motion.button>
                        <motion.div className='sidePanel'>
                            {this.props.member_id !== '0' && <>
                                <motion.div className='circleImage'
                                    layoutId={`member-modal-a${this.props.member_id}-image`}
                                >
                                    {!this.state.memberDetail.user.user_id && !this.state.memberDetail &&
                                        <motion.div className='centerContain'>
                                            <FontAwesomeIcon icon={faUser} />
                                        </motion.div>}
                                    {this.state.memberDetail && this.state.memberDetail.user && this.state.memberDetail.user.user_id &&
                                        <motion.img src={this.state.memberDetail.user.image_url ? configs.getAssetUrl() + this.state.memberDetail.user.image_url : 'https://gravatar.com/avatar/' + this.state.memberDetail.user.user_id + '?s=400&d=retro&r=x'}
                                            onLoad={(e) => {
                                                e.currentTarget.style.opacity = '1';
                                            }}
                                        />}
                                </motion.div>
                            </>}
                        </motion.div>
                        <br />
                        <br />
                        {this.state.memberDetail && <>
                            <motion.h2
                                style={{
                                    textAlign: 'center',
                                }}
                            >{this.state.memberDetail.user.username || ''}</motion.h2>
                            <motion.p
                                style={{
                                    textAlign: 'center',
                                }}
                            >{this.state.memberDetail.user.email || ''}</motion.p>
                        </>}
                        <br /><br />
                        <select onChange={(e) => {
                            console.log(e.currentTarget.value)
                            this.setState({
                                role_power: parseInt(e.currentTarget.value)
                            })
                        }}>
                            {this.context.project.rolePower! >= 1 &&
                                <option value="1" selected={this.state.role_power === 1}>Editor</option>
                            }
                            {this.context.project.rolePower! >= 2 &&
                                <option value="2" selected={this.state.role_power === 2}>Reporter</option>
                            }
                            {this.context.project.rolePower! >= 3 &&
                                <option value="3" selected={this.state.role_power === 3}>Admin</option>
                            }
                        </select>
                        <br /><br />
                        <motion.div className='btnBox'>
                            <Button
                                style={{
                                    width: '100%',
                                    height: 40
                                }}
                                onClick={() => {
                                    this.saveMember();
                                }}
                            >{this.props.member_id === '0' ? 'Create Actor' : 'Save'}</Button>
                            <Button
                                onClick={() => {
                                    this.props.onClose(this.state.shouldRefresh);
                                }}
                                style={{
                                    width: '100%',
                                    height: 40,
                                    backgroundColor: 'transparent'
                                }}
                            >Close</Button>
                        </motion.div>

                    </motion.div>
                </motion.div>
            </motion.div>
        );
    }

    SampleItem() {
        if (this.state.memberDetail.raw_data === null) {
            return <>
                <div className='centerContain'>
                    Drop jpeg files here
                </div>
            </>;
        }

        let raw_data = JSON.parse(this.state.memberDetail.raw_data);
        let sampleList = raw_data['sampleList'] || [];
        console.log(sampleList)
        return <>
            {sampleList.map((item: any, index: number) => {
                return <motion.div className='sampleItem' key={'sample-files-actor-' + index}
                    onHoverStart={() => {
                        this.setState({
                            enableUploadSample: false
                        })
                    }}
                    onHoverEnd={() => {
                        this.setState({
                            enableUploadSample: true
                        })
                    }}
                    onClick={(e: any) => {
                        window.open(configs.getAssetUrl() + item, '_blank');
                    }}
                >
                    <span>Sample {index + 1}</span>
                    <FontAwesomeIcon icon={faDownload} />
                </motion.div>
            })}
        </>;
    }
}

MemberDetailModal.contextType = AppContextState;

export default MemberDetailModal;