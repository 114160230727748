import * as React from 'react';
import { Link, Navigate } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import PageContainer from '../common/PageContainer';
import { Router, Route } from 'react-router-dom';

import './index.scss';

import { AppContextState, IAppState } from '../context';
import Button from '../common/components/Interactive/Button'
import TextBox from '../common/components/Interactive/Input/TextBox';
import { postData, setCookie } from '../libs/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOut, faTimes } from '@fortawesome/free-solid-svg-icons';

type PageProps = {
};
type PageState = {
    
};
class JoinTeamPage extends React.Component<PageProps, PageState, IAppState> {
    context!: IAppState;

    state = {
        crrPage: 'join',
        isLoggingIn: false,
        exiting: false,
        targetPath: '',
        code: '',
        teamName: '',
    };

    componentDidMount() {
        
    }

    componentWillUnmount(): void {
        
    }

    doJoin = async () => {
        this.setState({
            isLoggingIn: true
        })
        let res = await postData('/api/team/join', {
            invite_code: this.state.code
        })

        if(res.status === 200) {

            const data = await res.json();
            await this.context.refreshSession?.();
            this.setState({
                exiting: true,
                targetPath: '/teams'
            })
        } else {
            const data = await res.json();
            alert(data.message)
            this.setState({
                isLoggingIn: false
            })
        }
    }

    doCreateTeam = async () => {
        this.setState({
            isLoggingIn: true
        })
        let res = await postData('/api/team/create', {
            project_name: this.state.teamName,
        })

        if(res.status === 200) {
            const data = await res.json();

            await this.context.refreshSession();

            this.setState({
                exiting: true,
                targetPath: '/teams'
            })
        } else {
            const data = await res.json();
            alert(data.message)
            this.setState({
                isLoggingIn: false
            })
        }

        
    }

    render() {
        return (
            <PageContainer key={'paylist'} pathName='/join-team'>
                {this.state.exiting && this.state.targetPath && (
                    <Navigate to={this.state.targetPath} replace={true} />
                )}
                {this.context.auth.user && 
                    <motion.div className='team-page-header'>
                        <p onClick={() => {
                            this.context.auth.logout?.();
                        }}>
                            <span>Logout </span>
                            <FontAwesomeIcon icon={faSignOut} />
                        </p>
                    </motion.div>
                }
                <motion.div
                    className='join-team-page'
                    key={'JoinTeamPage'}
                >
                    <motion.div className='centerContain'>
                        { this.context.auth.user?.projects_members && this.context.auth.user?.projects_members.length > 0 &&
                            <motion.button className='closeBtn'
                                    initial={{
                                        opacity: 0,
                                    }}
                                    animate={{
                                        opacity: 1,
                                        transition: {
                                            duration: .5,
                                            delay: .25,
                                        }
                                    }}
                                    onClick={() => {
                                        this.setState({
                                            exiting: true,
                                            targetPath: '/teams'
                                        })
                                    }}
                                >
                                    <FontAwesomeIcon icon={faTimes} />
                            </motion.button>
                        }

                        {this.state.crrPage == 'join' && <motion.div
                        initial={{
                            opacity: 0,
                            x: -50
                        }}
                        animate={{
                            opacity: 1,
                            x: 0,
                            transition: {
                                duration: .25
                            }
                        }}>
                        <h1 style={{
                            fontSize: '2.5rem',
                        }}>Join Team</h1>
                        <br/>
                        
                        <div style={{
                            position: 'relative',
                            width: '100%',
                            height: '40px',
                            borderRadius: '10px',
                            border: '1px solid rgba(255, 255, 255, .2)',
                            overflow: 'hidden'
                        }}>
                            <TextBox placeholder="PIN CODE 6 DIGITS" style={{
                                padding: 0
                            }} onChange={(value) => {
                                this.setState({
                                    code: value
                                })
                            }}/>
                        </div>
                        <br/>
                        <br/>
                        <div style={{
                            display: 'flex',
                            marginLeft: 'auto',
                            width: '50%',
                        }} className="btnJoin">
                            <Button style={{
                                marginRight: '10px',
                                width: '50%',
                                height: '40px',
                                backgroundColor: 'transparent',
                            }} onClick={() => {
                                this.setState({
                                    crrPage: 'create'
                                })
                            }}>Create Team</Button>
                        
                            <div style={{
                                width: '50%',
                                // border: '1px solid rgba(255, 255, 255, .05)',
                                // backgroundColor: 'rgba(255, 255, 255, .05)',
                            }}
                            >
                                <Button style={{
                                    width: '100%',
                                    height: '40px',
                                    borderRadius: '10px',
                                    fontSize: '1.2rem',
                                    overflow: 'hidden',
                                    
                                    ...(this.state.isLoggingIn && {
                                        opacity: 0.5,
                                        pointerEvents: 'none'   
                                    })
                                }}
                                onClick={this.doJoin}
                                >
                                    {!this.state.isLoggingIn && 'Join'}
                                    {this.state.isLoggingIn && 'Joining...'}
                                </Button>
                            </div>
                            
                        </div>
                        
                        </motion.div>}

                        {this.state.crrPage == 'create' && <motion.div
                            initial={{
                                opacity: 0,
                                x: 50
                            }}
                            animate={{
                                opacity: 1,
                                x: 0,
                                transition: {
                                    duration: .25
                                }
                            }}
                            exit={{
                                opacity: 0,
                                x: -50,
                                transition: {
                                    duration: .25
                                }
                            }}>
                            <div style={{
                                display: 'flex',
                            }}>
                                <Button style={{
                                    paddingTop: 0,
                                    borderRadius: 10,
                                    overflow: 'hidden',
                                    backgroundColor: 'transparent',
                                    color: 'white',
                                    border: 'none',
                                    fontFamily: 'Anuphan',
                                    fontSize: 40,
                                    transform: 'translateY(-3px)',
                                    marginRight: 10,
                                }}
                                className='btnBack'
                                onClick={() => {
                                    this.setState({
                                        crrPage: 'join'
                                    })
                                }}
                                >
                                    {// arrow-left 
                                        "<"
                                    }
                                </Button>
                                <h1 style={{
                                    fontSize: '2.5rem',
                                }}>Create Team</h1>
                            </div>
                            <br/>
                            <div style={{
                                position: 'relative',
                                width: '100%',
                                height: '40px',
                                borderRadius: '10px',
                                border: '1px solid rgba(255, 255, 255, .2)',
                                overflow: 'hidden'
                            }}>
                                <TextBox placeholder="Team Name" style={{
                                    padding: 0
                                }} onChange={(value) => {
                                    this.setState({
                                        teamName: value
                                    })
                                }}/>
                            </div>
                            <br/>
                            <br/>
                            <div style={{
                                marginLeft: 'auto',
                                width: '30%',
                                // border: '1px solid rgba(255, 255, 255, .05)',
                                // backgroundColor: 'rgba(255, 255, 255, .05)',
                            }}
                            className='btnJoin'
                            >
                                <Button style={{
                                    width: '100%',
                                    height: '40px',
                                    borderRadius: '10px',
                                    fontSize: '1.2rem',
                                    overflow: 'hidden',
                                    ...(this.state.isLoggingIn && {
                                        opacity: 0.5,
                                        pointerEvents: 'none'   
                                    })
                                }}
                                onClick={() => {
                                    this.doCreateTeam()
                                }}
                                >
                                    {this.state.isLoggingIn && 'Creating Team...'}
                                    {!this.state.isLoggingIn && 'Create Team'}
                                </Button>
                            </div>
                            <br/>
                        </motion.div>}
                    </motion.div>
                </motion.div>
            </PageContainer>
        );
    }
}

JoinTeamPage.contextType = AppContextState;

export default JoinTeamPage;