import { getCookie } from "./libs/utils";

const configs: {
    apiUrl: string,
    s3Url: string,
    getAssetUrl: () => string
} = {
    apiUrl: 'https://film-api.jaruwat.dev',
    s3Url: '',
    getAssetUrl: () => {
        return configs.apiUrl + `/api/storage/gotoDirectUrl?token=${getCookie('token')}&path_file=`;
    }
};

export default configs;