import * as React from 'react';
import { Link, Navigate } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import PageContainer from '../../common/PageContainer';
import { Router, Route } from 'react-router-dom';

import './index.scss';

import { AppContextState, IAppState } from '../../context';
import Button from '../../common/components/Interactive/Button'
import TextBox from '../../common/components/Interactive/Input/TextBox';
import { getData, postData, setCookie, displayFilesSizeFormat, postUplaodFile, sleep, deleteData } from '../../libs/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faImage, faDownload, faTrash, faTimes } from '@fortawesome/free-solid-svg-icons';
import DashboardContainer from '../../common/DashboardContainer';
import VideoList from '../../common/components/Video-List';
import LoadingIcon from '../../common/LoadingIcon';
import ReactPlayer from 'react-player';
import configs from '../../configs';
import { FileUploader } from 'react-drag-drop-files';

const imageTypes = [
    'jpeg',
    'jpg'
];

type PageProps = {
    inviteList: any[]
    onClose: (shouldRefresh: boolean) => void
    onUpdateMember: (user_id: number, role_power: number) => void
    
};
type PageState = {
    loading: Boolean,
};
class InviteModal extends React.Component<PageProps, PageState, IAppState> {
    context!: IAppState;

    state = {
        loading: true,
    };

    constructor(props: any) {
        super(props);
    }

    componentDidMount() {

    }

    componentWillUnmount(): void {
        
    }

    componentDidUpdate(prevProps: Readonly<PageProps>, prevState: Readonly<PageState>, snapshot?: IAppState | undefined): void {
        // console.log(this.props)
        if(prevProps.inviteList != this.props.inviteList) {
            this.setState({
                loading: false,
            })
        }
    }

    async acceptMember(user_id: number) {
        await this.props.onUpdateMember(user_id, 1);
    }

    async rejectMember(user_id: number) {
        await this.props.onUpdateMember(user_id, 0);
    }

    render() {
        // console.log(this.props)


        return (
            <motion.div className='memberModal'>
                <motion.div className='modalBg'
                    initial={{ opacity: 0 }}
                    animate={{
                        opacity: 1,
                        transition: {
                            duration: 0.3,
                        }
                    }}
                    exit={{
                        opacity: 0,
                    }}
                    onClick={() => {
                        this.props.onClose(true);
                    }}
                ></motion.div>
                <motion.div className='modalContent'>
                    <motion.div className='sub'
                        layoutId={`member-modal-a0-panel`}
                    >
                        <motion.button className='deleteBtn'
                            initial={{
                                opacity: 0,
                            }}
                            animate={{
                                opacity: 1,
                                transition: {
                                    duration: .5,
                                    delay: .25,
                                }
                            }}
                            onClick={() => {
                                this.props.onClose(true);
                            }}
                        >
                            <FontAwesomeIcon icon={faTimes} />
                        </motion.button>
                        
                        <motion.div>
                            <h1>Invite</h1>
                            <p>Invite members to join your project</p>
                            <br/>
                            <motion.div className='inviteList'>
                                {this.props.inviteList.map((item, index) => {

                                    return <motion.div className='inviteItem'
                                        key={'inviteItem-' + item.user_id}
                                    >
                                        <motion.div className='leftSide'>
                                            <motion.div className='miniCircleImage' style={{
                                                backgroundImage: 'url(' + (item.user.image_url ? configs.getAssetUrl() + item.user.image_url : 'https://gravatar.com/avatar/${' + item.user.user_id + '}?s=400&d=retro&r=x') + ')'
                                            }}></motion.div>
                                            <motion.div className='detail'>
                                                <motion.div className='name'>{item.user.username}</motion.div>
                                                <motion.div className='email'>{item.user.email}</motion.div>
                                            </motion.div>
                                        </motion.div>
                                        <motion.div className='rightSide'>
                                            <Button style={{
                                                marginRight: 10,
                                            }}
                                                onClick={() => {
                                                    this.acceptMember(item.user_id);
                                                }}
                                            >Accept</Button>
                                            <Button onClick={() => {
                                                this.rejectMember(item.user_id);
                                            }}>Reject</Button>
                                        </motion.div>
                                    </motion.div>
                                })}
                            </motion.div>
                        </motion.div>
                    </motion.div>
                </motion.div>
            </motion.div>
        );
    }
}

InviteModal.contextType = AppContextState;

export default InviteModal;