import * as React from 'react';
import { Link, Navigate } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import PageContainer from '../common/PageContainer';
import { Router, Route } from 'react-router-dom';

import './index.scss';

import { AppContextState, IAppState } from '../context';
import Button from '../common/components/Interactive/Button'
import TextBox from '../common/components/Interactive/Input/TextBox';
import { getData, postData, setCookie } from '../libs/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faPhotoVideo, faVideo } from '@fortawesome/free-solid-svg-icons';
import DashboardContainer from '../common/DashboardContainer';
import VideoList from '../common/components/Video-List';
import LoadingIcon from '../common/LoadingIcon';
import VideoPreviewModal from '../common/components/VideoPreview';
import configs from '../configs';
import ModalUploader from '../common/ModalUploader';

type PageProps = {
};
type PageState = {
    loading: Boolean,
    videoList: any[],
    crrPreviewVideo?: string,
    crrThumnail?: string,
    lastKeywordSearch: string
    lastContentUpdate: number
};
class DashboardPage extends React.Component<PageProps, PageState, IAppState> {
    context!: IAppState;

    state: PageState = {
        loading: true,
        videoList: [],
        crrPreviewVideo: undefined,
        crrThumnail: undefined,
        lastKeywordSearch: '',
        lastContentUpdate: 0
    };

    componentDidMount() {
        this.getFootageList();
    }

    componentWillUnmount(): void {
        
    }

    componentDidUpdate(prevProps: Readonly<PageProps>, prevState: Readonly<PageState>, snapshot?: IAppState | undefined): void {
        // console.log(this.context.keywordSearchInput, this.state.lastKeywordSearch)
        if(this.context.keywordSearchInput !== this.state.lastKeywordSearch || this.context.lastContentUpdate !== this.state.lastContentUpdate) {
            this.setState({
                loading: true,
                lastKeywordSearch: this.context.keywordSearchInput || '',
                lastContentUpdate: this.context.lastContentUpdate || 0
            });
            this.getFootageList();
        }
    }

    getFootageList = async () => {
        let result = await getData('/api/footage/getFileList?project_id=' + this.context.project.project?.project_id + '&keywords=' + this.context.keywordSearchInput)
        this.setState({
            loading: false
        })
        if(result.status === 200) {
            const data = await result.json();
            if(data.files) {
                
                // this.setState({
                //     videoList: data.files.map((item: any) => {
                //         return {
                //             title: item.file.title,
                //             duration: item.file.duration || 0,
                //             description: item.file.description || '',
                //             tags: item.tags.map((tag: any) => tag.tag.name ?? ''),
                //             source: "",
                //             thumbnail: item.file.thumbnail,
                //             ...item
                //         };
                //     })
                // })

                let videoListByDate: any = {};
                data.files.forEach((item: any) => {
                    const date = new Date(item.file.created_date);
                    let dateStr = date.toDateString();
                    // check is date is today
                    if(dateStr === new Date().toDateString()) {
                        dateStr = 'Today';
                    }
                    if(!videoListByDate[dateStr]) {
                        videoListByDate[dateStr] = [];
                    }
                    videoListByDate[dateStr].push(item);
                });

                this.setState({
                    videoList: Object.keys(videoListByDate).map((dateStr: string) => {
                        return {
                            date: dateStr,
                            videos: videoListByDate[dateStr].map((item: any) => {
                                return {
                                    title: item.file.title,
                                    duration: item.file.duration || 0,
                                    description: item.file.description || '',
                                    tags: item.tag_map.map((tag: any) => tag.tag.name ?? ''),
                                    source: "",
                                    thumbnail: item.file.thumbnail,
                                    rawData: item.file
                                };
                            })
                        };
                    })
                })
            }
        }
    }

    render() {

        const { crrPreviewVideo } = this.state;
        return (
            <>
                { this.state.crrPreviewVideo && <VideoPreviewModal video_id={this.state.crrPreviewVideo}
                    thumbnail={this.state.crrThumnail || ''}
                    onClose={() => {
                        this.setState({
                            crrPreviewVideo: undefined
                        })
                    } }/>
                }
                { this.state.loading && <div className='loadingCenter'><LoadingIcon size={30}/></div>}
                { !this.state.loading && this.state.videoList.length === 0 && <div className="no-footage" style={{
                    position: 'relative',
                    width: '100%',
                    height: '50vh',
                }}>
                    <>
                        <div className="centerContain" style={{
                            padding: 20,
                            backgroundColor: 'rgba(255, 255, 255, .025)',
                            border: '1px #545454 solid',
                            borderRadius: 10,
                            boxShadow: '0 0 10px #0000000f'
                        }}>
                            <p>There aren&rsquo;t any Footage in your project.</p>
                            <Button style={{ marginTop: 30 }} onClick={()=>{
                                DashboardContainer.toggleModalUploader(true);
                            }}>
                                <FontAwesomeIcon icon={ faVideo }/>
                                <span>Add New Footage</span>
                            </Button>
                        </div>
                    </>
                </div>}
                { !this.state.loading && this.state.videoList.length > 0 &&
                    <motion.div className="timeline"
                        initial={{ opacity: 0 }}
                        animate={{
                            opacity: 1,
                            transition: {
                                duration: 0.5,
                            }
                        }}
                        exit={{
                            opacity: 0,
                        }}
                    >
                        {this.state.videoList.map((item: any) => {
                            return <>
                                <h1 className="title">{item.date}</h1>
                                <VideoList items={item.videos} onVideoClick={(video: any) => {
                                    console.log('video', video);
                                    this.setState({
                                        crrThumnail: configs.getAssetUrl() + video.thumbnail,
                                        crrPreviewVideo: video.rawData.file_id
                                    });
                                }}
                                    crrPreviewVideo={crrPreviewVideo}
                                />
                                <br/>
                            </>
                        })}
                        
                    </motion.div>
                }
            </>
        );
    }
}

DashboardPage.contextType = AppContextState;

export default DashboardPage;