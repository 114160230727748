import * as React from 'react';
import { Link, Navigate } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import PageContainer from '../common/PageContainer';
import { Router, Route } from 'react-router-dom';

import './index.scss';

import { AppContextState, IAppState } from '../context';
import Button from '../common/components/Interactive/Button'
import TextBox from '../common/components/Interactive/Input/TextBox';
import { postData, setCookie } from '../libs/utils';

type PageProps = {
};
type PageState = {
    
};
class AuthPage extends React.Component<PageProps, PageState, IAppState> {
    context!: IAppState;

    state = {
        crrPage: 'login',
        isLoggingIn: false,
        exiting: false,
        targetPath: '',
        username: '',
        password: '',
        confirmPassword: '',
        email: '',
    };

    componentDidMount() {
        const { auth }: IAppState = this.context;
        
        // setCrrFeature('/story');
        if(auth.user) {
            this.setState({
                exiting: true,
                targetPath: '/dashboard'
            })
        }
    }

    componentWillUnmount(): void {
        console.log('unmount', "/story");
        
    }

    doSignIn = async () => {
        this.setState({
            isLoggingIn: true
        })
        let res = await postData('/api/user/login', {
            email: this.state.email,
            password: this.state.password,
        })

        if(res.status === 200) {

            const data = await res.json();
            if(data.token && data.user.projects_members.length === 0) {
                setCookie('token', data.token, 365);
                this.context.auth.setUser(data.user);
                await this.context.refreshSession?.();
                this.setState({
                    exiting: true,
                    targetPath: '/join-team'
                })
            } else if(data.token){
                setCookie('token', data.token, 365);
                this.context.auth.setUser(data.user);
                await this.context.refreshSession?.();
                this.setState({
                    exiting: true,
                    targetPath: '/teams'
                })
            }

            // this.context.setCrrPageHeight
        } else {
            const data = await res.json();
            alert(data.message)
            this.setState({
                isLoggingIn: false
            })
        }
    }

    doRegister = async () => {

        if(this.state.confirmPassword !== this.state.password) {
            alert('Password not match')
            return;
        }
        this.setState({
            isLoggingIn: true
        })
        let res = await postData('/api/user/signup', {
            email: this.state.email,
            username: this.state.username,
            password: this.state.password,
        })

        if(res.status === 200) {
            const data = await res.json();
            setCookie('token', data.token, 365);
            await this.context.refreshSession?.();

            this.setState({
                exiting: true,
                targetPath: '/join-team'
            })
            this.context.auth.setUser(data.user);
        } else {
            const data = await res.json();
            alert(data.message)
            this.setState({
                isLoggingIn: false
            })
        }

        
    }

    render() {
        return (
            <PageContainer key={'paylist'} pathName='/story'>
                {this.state.exiting && this.state.targetPath && (
                    <Navigate to={this.state.targetPath} replace={true} />
                )}
                <motion.div
                    className='login-page'
                    key={'AuthPage'}
                >
                    <motion.div className='bgOver'></motion.div>
                    <motion.div className='loginSide'>
                        <motion.div className='centerContain'>
                            {this.state.crrPage == 'login' && <motion.div
                            initial={{
                                opacity: 0,
                                x: -50
                            }}
                            animate={{
                                opacity: 1,
                                x: 0,
                                transition: {
                                    duration: .25
                                }
                            }}>
                            <h1 style={{
                                fontSize: '2.5rem',
                            }}>Sign in</h1>
                            <br/>
                            <br/>
                            
                            <div style={{
                                position: 'relative',
                                width: '100%',
                                height: '40px',
                                borderRadius: '10px',
                                border: '1px solid rgba(255, 255, 255, .2)',
                                overflow: 'hidden'
                            }}>
                                <TextBox placeholder="Email" type='email' style={{
                                    padding: 0
                                }} onChange={(value) => {
                                    this.setState({
                                        email: value
                                    })
                                }}/>
                            </div>
                            <br/>
                            <div style={{
                                position: 'relative',
                                width: '100%',
                                height: '40px',
                                borderRadius: '10px',
                                border: '1px solid rgba(255, 255, 255, .2)',
                                overflow: 'hidden',
                            }}>
                                <TextBox placeholder="Password" type="password" style={{
                                    padding: 0
                                }}
                                    onChange={(value) => {
                                        this.setState({
                                            password: value
                                        })
                                    }}
                                />
                            </div>
                            <br/>
                            <br/>
                            <div style={{
                                border: '1px solid rgba(255, 255, 255, .05)',
                                backgroundColor: 'rgba(255, 255, 255, .05)',
                            }}
                            >
                                <Button style={{
                                    width: '100%',
                                    height: '40px',
                                    borderRadius: '10px',
                                    fontSize: '1.2rem',
                                    overflow: 'hidden',
                                    
                                    ...(this.state.isLoggingIn && {
                                        opacity: 0.5,
                                        pointerEvents: 'none'   
                                    })
                                }}
                                onClick={this.doSignIn}
                                >
                                    {!this.state.isLoggingIn && 'Sign In'}
                                    {this.state.isLoggingIn && 'Signing In...'}
                                </Button>
                            </div>
                            
                           
                            <div style={{
                                width: '100%',
                                height: '15vh'
                            }}></div>
                            <p style={{textAlign: 'center'}}>Or</p>
                            <br/>
                            <div style={{
                                border: '1px solid rgba(255, 255, 255, .05)',
                                backgroundColor: 'rgba(255, 255, 255, .05)',
                            }}
                            >
                                <Button style={{
                                    width: '100%',
                                    height: '40px',
                                    borderRadius: '10px',
                                    fontSize: '1.2rem',
                                    overflow: 'hidden'
                                }}
                                onClick={() => {
                                    this.setState({
                                        crrPage: 'signup'
                                    })
                                }}
                                >Create account</Button>
                            </div>
                            </motion.div>}

                            {this.state.crrPage == 'signup' && <motion.div
                                initial={{
                                    opacity: 0,
                                    x: 50
                                }}
                                animate={{
                                    opacity: 1,
                                    x: 0,
                                    transition: {
                                        duration: .25
                                    }
                                }}
                                exit={{
                                    opacity: 0,
                                    x: -50,
                                    transition: {
                                        duration: .25
                                    }
                                }}>
                                <div style={{
                                    display: 'flex',
                                }}>
                                    <Button style={{
                                        paddingTop: 0,
                                        height: 40,
                                        borderRadius: 10,
                                        overflow: 'hidden',
                                        backgroundColor: 'transparent',
                                        color: 'white',
                                        border: 'none',
                                        fontFamily: 'Anuphan',
                                        fontSize: 40,
                                        transform: 'translateY(-3px)',
                                        marginRight: 10,
                                    }}
                                    className='backButton'
                                    onClick={() => {
                                        this.setState({
                                            crrPage: 'login'
                                        })
                                    }
                                    }
                                    >
                                        {// arrow-left 
                                            "<"
                                        }
                                    </Button>
                                    <h1 style={{
                                        fontSize: '2.5rem',
                                    }}>Sign up</h1>
                                </div>
                                <br/>
                                <br/>
                                <div style={{
                                    position: 'relative',
                                    width: '100%',
                                    height: '40px',
                                    borderRadius: '10px',
                                    border: '1px solid rgba(255, 255, 255, .2)',
                                    overflow: 'hidden'
                                }}>
                                    <TextBox placeholder="Username" style={{
                                        padding: 0
                                    }} onChange={(value) => {
                                        this.setState({
                                            username: value
                                        })
                                    }}/>
                                </div>
                                <br/>
                                <div style={{
                                    position: 'relative',
                                    width: '100%',
                                    height: '40px',
                                    borderRadius: '10px',
                                    border: '1px solid rgba(255, 255, 255, .2)',
                                    overflow: 'hidden'
                                }}>
                                    <TextBox placeholder="Email" type='email' style={{
                                        padding: 0
                                    }} onChange={(value) => {
                                        this.setState({
                                            email: value
                                        })
                                    }}/>
                                </div>
                                <br/>
                                <div style={{
                                    position: 'relative',
                                    width: '100%',
                                    height: '40px',
                                    borderRadius: '10px',
                                    border: '1px solid rgba(255, 255, 255, .2)',
                                    overflow: 'hidden',
                                }}>
                                    <TextBox placeholder="Password" type="password" style={{
                                        padding: 0
                                    }} onChange={(value) => {
                                        this.setState({
                                            password: value
                                        })
                                    }}/>
                                </div>
                                <br/>
                                <div style={{
                                    position: 'relative',
                                    width: '100%',
                                    height: '40px',
                                    borderRadius: '10px',
                                    border: '1px solid rgba(255, 255, 255, .2)',
                                    overflow: 'hidden',
                                }}>
                                    <TextBox placeholder="Confirm password" type="password" style={{
                                        padding: 0
                                    }} onChange={(value) => {
                                        this.setState({
                                            confirmPassword: value
                                        })
                                    }}/>
                                </div>
                                <br/>
                                <br/>
                                <div style={{
                                    border: '1px solid rgba(255, 255, 255, .05)',
                                    backgroundColor: 'rgba(255, 255, 255, .05)',
                                }}
                                >
                                    <Button style={{
                                        width: '100%',
                                        height: '40px',
                                        borderRadius: '10px',
                                        fontSize: '1.2rem',
                                        overflow: 'hidden',
                                        ...(this.state.isLoggingIn && {
                                            opacity: 0.5,
                                            pointerEvents: 'none'   
                                        })
                                    }}
                                    onClick={() => {
                                        this.doRegister()
                                    }}
                                    >
                                        {this.state.isLoggingIn && 'Signing up...'}
                                        {!this.state.isLoggingIn && 'Sign up'}
                                    </Button>
                                </div>
                                <div style={{
                                    width: '100%',
                                    height: '20vh'
                                }}></div>
                                <br/>
                            </motion.div>}
                        </motion.div>
                    </motion.div>
                </motion.div>
            </PageContainer>
        );
    }
}

AuthPage.contextType = AppContextState;

export default AuthPage;