import * as React from 'react';
import { Link, Navigate } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import PageContainer from '../../../common/PageContainer';
import { Router, Route } from 'react-router-dom';

import VideoJS from '../VideoJS'

import './index.scss';

import { AppContextState, IAppState } from '../../../context';
import Button from '../../../common/components/Interactive/Button'
import TextBox from '../../../common/components/Interactive/Input/TextBox';
import { getData, postData, setCookie, displayFilesSizeFormat, sleep, getCookie } from '../../../libs/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faPen, faTimes } from '@fortawesome/free-solid-svg-icons';
import DashboardContainer from '../../../common/DashboardContainer';
import VideoList from '../../../common/components/Video-List';
import LoadingIcon from '../../../common/LoadingIcon';
import ReactPlayer from 'react-player';
import configs from '../../../configs';
import ModalUploader from '../../ModalUploader';

type PageProps = {
    video_id: string
    thumbnail: string
    onClose: () => void
};
type PageState = {
    loading: Boolean,
    videoDetail: any,
    videoConfig: any,
    mounted: Boolean
};
class VideoPreviewModal extends React.Component<PageProps, PageState, IAppState> {
    context!: IAppState;

    state = {
        loading: true,
        videoDetail: {
            title: '',
            description: '',
            tags: [],
            source: '',
            file_path: '',

            file_id: '',
            raw_data: '',
            thumbnail: '',
            created_date: '',
            modified_date: '',
            duration: '',
            type: '',
            size: 0,
            owner_id: '',
        },
        videoConfig: {},
        mounted: false,
    };

    playerRef = React.createRef<HTMLInputElement>();
    playerElm: any;

    constructor(props: any) {
        super(props);
        this.handlePlayerReady = this.handlePlayerReady.bind(this);
    }

    componentDidMount() {
        setTimeout(() => {
            this.getVideoDetail();
        }, 1000)
    }

    componentWillUnmount(): void {}

    handlePlayerReady(player: any) {
        this.playerRef = player;
    
        // You can handle player events here, for example:
        player.on('waiting', () => {
        //   videojs.log('player is waiting');
        });
    
        player.on('dispose', () => {
        //   videojs.log('player will dispose');
        });
        // window.player = player;
    };

    async getVideoDetail() {
        let result = await getData('/api/storage/getFilesUrl?file_id=' + this.props.video_id)
        if(result.status === 200) {
            const data = await result.json();
            let rawData = JSON.parse(data.raw_data);
            this.setState({
                loading: false,
                videoDetail: data,
                videoConfig: {
                    autoplay: true,
                    controls: true,
                    responsive: true,
                    fluid: false,
                    playbackRates: [0.5, 1.0, 1.5, 2.0],
                    autoSetup: false,
                    preload: 'auto',
                    sources: [...rawData.compress_list.map((item: any) => {
                        // console.log(item.url)
                        return {
                            src: configs.getAssetUrl() + item.url + "&file_ext=.mp4",
                            type: 'video/mp4',
                            label: item.width,
                            res: item.width
                        };
                    }), {
                        src: data.file_path,
                        type: 'video/mp4',
                        label: 'Original',
                        res: 0
                    }],
                    plugins: {
                        videoJsResolutionSwitcher: {
                            default: 0,
                        }
                    },
                    poster: this.props.thumbnail,
                }
            })
        }
    }

    async addEditVideo() {
        if(this.state.videoDetail.file_id === '') {
            return;
        }

        this.props.onClose();

        let file = this.state.videoDetail;
        console.log('file', file)
        let itemKey = (`${file.file_id}-${file.owner_id}`).toString();
        // file.file_path = configs.s3Url + file.file_path;
        // console.log(file)
        
        this.context.addUploadList({
            key: itemKey,
            data: file,
            file: file,
            filename: file.title,
            percent: 100,
            success: true
        });

        await sleep(300);

        DashboardContainer.toggleModalUploader(true);
        await sleep(300);
        ModalUploader.setCrrSekected(itemKey);
    }

    render() {
        const { videoConfig } = this.state;
        return (
            <motion.div className='previewModal'>
                <motion.div className='modalBg'
                    initial={{ opacity: 0 }}
                    animate={{
                        opacity: 1,
                        transition: {
                            duration: 0.3,
                        }
                    }}
                    exit={{
                        opacity: 0,
                    }}
                    onClick={() => {
                        this.props.onClose();
                    }}
                ></motion.div>
                <motion.div className='modalContent'>
                    <motion.div className='sub'>
                        <motion.div 
                            className='bg-panel'
                            layoutId={`thumbnail-vid-${this.props.video_id}-panel`}
                        ></motion.div>
                        <motion.div className='videoPlayer'
                            layoutId={`thumbnail-vid-${this.props.video_id}-video`}
                            style={{
                                backgroundImage: `url(${this.props.thumbnail})`,
                                backgroundSize: 'contain',
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'center',
                            }}
                        >
                            {this.state.loading && <motion.div className='loadingCenter'>
                                <LoadingIcon />
                            </motion.div>}
                            {!this.state.loading && <motion.div
                                style={{
                                    width: '100%',
                                    height: '100%',
                                }}
                                initial={{
                                    opacity: 0,
                                }}
                                animate={{
                                    opacity: 1,
                                    transition: {
                                        duration: .5,
                                        delay: .25,
                                    }
                                }}
                            >
                                {/* <ReactPlayer url={this.state.videoDetail.file_path} 
                                    controls
                                    width={'100%'}
                                    height={'100%'}
                                    playing={true}
                                /> */}

                                <VideoJS
                                    options={videoConfig}
                                    onReady={this.handlePlayerReady}
                                    controls
                                    width={'100%'}
                                    height={'100%'}
                                    playing={true}
                                />
                            </motion.div>}
                        </motion.div>
                        
                        <motion.div className='videoInfo'
                            initial={{
                                opacity: 0,
                            }}
                            animate={{
                                opacity: 1,
                                transition: {
                                    duration: .5,
                                    delay: .25,
                                }
                            }}
                        >
                            {this.state.loading && <motion.div className='loadingCenter'>
                                <LoadingIcon size={20} border={3}/>
                            </motion.div>}
                            {!this.state.loading && <motion.div
                                initial={{
                                    opacity: 0,
                                }}
                                animate={{
                                    opacity: 1,
                                    transition: {
                                        duration: .5,
                                        delay: .25,
                                    }
                                }}
                            >
                            <div className='title'>
                                <p>{this.state.videoDetail.title}</p>
                                <div className='previewBtnPanel' style={{
                                    display: 'flex',
                                }}>
                                { this.context.project != null && this.context.project!.rolePower! >= 2 &&
                                    <motion.button className='editBtn'
                                        initial={{
                                            opacity: 0,
                                        }}
                                        animate={{
                                            opacity: 1,
                                            transition: {
                                                duration: .5,
                                                delay: .25,
                                            }
                                        }}
                                        onClick={() => {
                                            this.addEditVideo();
                                        }}
                                    >
                                        <FontAwesomeIcon icon={faPen} />
                                    </motion.button>
                                }
                                <motion.button className='closeBtn'
                                    initial={{
                                        opacity: 0,
                                    }}
                                    animate={{
                                        opacity: 1,
                                        transition: {
                                            duration: .5,
                                            delay: .25,
                                        }
                                    }}
                                    onClick={() => {
                                        this.props.onClose();
                                    }}
                                >
                                    <FontAwesomeIcon icon={faTimes} />
                                </motion.button>
                                </div>
                            </div>
                            <div className='description'>
                                <span style={{
                                    opacity: .5,
                                }}>{this.state.videoDetail.created_date}</span>
                                <br/><br/><br/>
                                {this.state.videoDetail.description}
                            </div>
                            <div className='videoTags'>
                                {this.state.videoDetail.tags?.map((tag: string) => {
                                    return (
                                        <div className='tag'>
                                            {tag}
                                        </div>
                                    )
                                })}
                            </div>

                            <div className='bottomPanel'>
                                <Button className='btn'
                                    onClick={() => {
                                        window.open(this.state.videoDetail.file_path, '_blank')
                                    }}
                                >Download file {displayFilesSizeFormat(this.state.videoDetail.size)}</Button>
                                    
                            </div>
                            </motion.div>}
                        </motion.div>
                    </motion.div>
                </motion.div>
            </motion.div>
        );
    }
}

VideoPreviewModal.contextType = AppContextState;

export default VideoPreviewModal;