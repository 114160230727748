import { Children, Component } from 'react'
import { Link } from 'react-router-dom'
import { LayoutGroup, AnimatePresence, motion } from 'framer-motion'

import styles from './index.module.css'

// Fonts & Icons
import { AppContextState, IAppState } from '../../../context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faCog, faHome, faUserFriends, faFileInvoice, faBars, faPhotoVideo, faLaughBeam } from '@fortawesome/free-solid-svg-icons'

import { sleep } from '../../../libs/utils'

interface IRecipeProps {
    toggleFn: Function,
    sidebarOn: Boolean,
    className?: any
}

type State = {
    
};

class SideOption extends Component<IRecipeProps, State, IAppState> {

    context!: IAppState;

    constructor(props: any) {
        super(props)
        this.MenuItem = this.MenuItem.bind(this)
    }

    MenuItem({ href, icon, children }: any) {
        return <Link to={ href } className={ window.location.pathname == href ? styles.active : '' }
            onClick={()=>{
                this.props.toggleFn(false)
            }}
        >
            <FontAwesomeIcon icon={ icon }/> <span>{ children }</span>
        </Link>
    }

    render() {
        let { MenuItem } = this;
        return <>
            {this.props.sidebarOn && <motion.div
                initial={{
                    opacity: 0
                }}
                animate={{
                    opacity: 1
                }}
                exit={{
                    opacity: 0
                }}
                className={styles['backdrop']}
                onClick={()=>{this.props.toggleFn()}}
            ></motion.div>}
            <motion.div
                className={ `${styles['side-menu']} ${this.props.className} ${this.props.sidebarOn ? styles['active'] : ''}` }
                style={{
                    paddingTop: 80
                }}
                initial={{
                    x: -50
                }}
                animate={{
                    x: 0,
                }}
            >
                {/* <button className={ `${styles['btn-cls']} circle_btn` }
                    onClick={()=>{this.props.toggleFn(false)}}
                >
                    <FontAwesomeIcon icon={ faArrowLeft }/>
                </button> */}
                {/* <div className={ styles.sideOptionCover }> */}
                    {/* <FontAwesomeIcon icon={ faBars }/> */}
                    {/* <h3 className={styles.text}>Options</h3> */}
                {/* </div> */}
                <motion.div className={styles['menu-area']}>
                    <MenuItem href="/dashboard" icon={ faPhotoVideo }>Footage</MenuItem>
                    <MenuItem href="/actors" icon={ faLaughBeam }>Actors</MenuItem>
                    { this.context.project!.rolePower! == 3 &&
                        <MenuItem href="/billing" icon={ faFileInvoice }>Billing</MenuItem>
                    }
                    { this.context.project!.rolePower! >= 2 &&
                        <MenuItem href="/activities" icon={ faCog }>Activities</MenuItem>
                    }
                    <MenuItem href="/members" icon={ faUserFriends }>Members</MenuItem>
                </motion.div>
                <motion.div className={styles['creditSection']}>
                    <motion.p>Ⓒ Since 2023.</motion.p>
                    <motion.p>Smart Scene Analysis System</motion.p>
                </motion.div>
            </motion.div>
        </>
    }
}

SideOption.contextType = AppContextState;

export default SideOption;