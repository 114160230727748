import * as React from 'react';
import { Link, Navigate } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import PageContainer from '../common/PageContainer';
import { Router, Route } from 'react-router-dom';

import './index.scss';

import { AppContextState, IAppState } from '../context';
import Button from '../common/components/Interactive/Button'
import TextBox from '../common/components/Interactive/Input/TextBox';
import { postData, setCookie } from '../libs/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faSignOut } from '@fortawesome/free-solid-svg-icons';
import configs from '../configs';

type PageProps = {
};
type PageState = {
    
};
class FoundTeamPage extends React.Component<PageProps, PageState, IAppState> {
    context!: IAppState;

    state = {
        isLoggingIn: false,
        exiting: false,
        targetPath: '',
        code: '',
        teamName: '',
    };

    componentDidMount() {
        
    }

    componentWillUnmount(): void {
        
    }

    doJoin = async () => {
        this.setState({
            isLoggingIn: true
        })
        let res = await postData('/api/team/join', {
            invite_code: this.state.code
        })

        if(res.status === 200) {

            const data = await res.json();
            this.setState({
                exiting: true,
                targetPath: '/dashboard'
            })
        } else {
            const data = await res.json();
            alert(data.message)
            this.setState({
                isLoggingIn: false
            })
        }
    }

    doCreateTeam = async () => {
        this.setState({
            isLoggingIn: true
        })
        let res = await postData('/api/team/create', {
            project_name: this.state.teamName,
        })

        if(res.status === 200) {
            const data = await res.json();

            this.setState({
                exiting: true,
                targetPath: '/dashboard'
            })
        } else {
            const data = await res.json();
            alert(data.message)
            this.setState({
                isLoggingIn: false
            })
        }

        
    }

    render() {
        return (
            <PageContainer key={'paylist'} pathName='/teams'>
                {this.state.exiting && this.state.targetPath && (
                    <Navigate to={this.state.targetPath} replace={true} />
                )}
                {this.context.auth.user && 
                    <motion.div className='team-page-header'>
                        <p onClick={() => {
                            this.context.auth.logout?.();
                        }}>
                            <span>Logout </span>
                            <FontAwesomeIcon icon={faSignOut} />
                        </p>
                    </motion.div>
                }
                <motion.div
                    className='join-team-page'
                    key={'FoundTeamPage'}
                >
                    <motion.div className='centerContain'>
                        <motion.div
                            initial={{
                                opacity: 0,
                                x: -50
                            }}
                            animate={{
                                opacity: 1,
                                x: 0,
                                transition: {
                                    duration: .25
                                }
                            }}>
                            <h1 style={{
                                fontSize: '2.5rem',
                            }}>Your Team</h1>
                            <br/>
                            <br/>
                            <br/>
                            <motion.div className='teamList'>
                                { this.context.auth.user?.projects_members.map((project: any, index: number) => (
                                    <motion.div className='teamItem' key={'teamItem-' + index}>
                                        <motion.div className='img' style={{
                                            backgroundImage: `url(${project.project.project_logo ? configs.getAssetUrl() + project.project.project_logo : 'https://gravatar.com/avatar/' + project.project.project_id + '?s=400&d=identicon&r=x'})`
                                        }}></motion.div>
                                        <motion.div className='detail'>
                                            <motion.div className='name'>{project.project.project_name}</motion.div>
                                            <motion.div className='peopleCount'>
                                                <span>{project.project._count.projects_members} people{project.project._count.projects_members > 1 ? 's' : ''}</span>
                                                {project.role_power == -1 &&
                                                    <span style={{
                                                        color: 'rgba(255, 255, 255, .5)',
                                                    }}> (Request Pending)</span>
                                                }
                                            </motion.div>
                                        </motion.div>
                                        <motion.div className='rightPanel'>
                                            <motion.button className='openBtn'
                                                style={project.role_power == -1 ? {
                                                    pointerEvents: 'none',
                                                    opacity: 0.5
                                                } : {}}
                                                onClick={() => {
                                                    this.context.project.setProject(project.project, project.role_power);
                                                    this.setState({
                                                        exiting: true,
                                                        targetPath: '/dashboard'
                                                    })
                                                }}
                                            >
                                                <span style={{
                                                    marginRight: 10
                                                }}>Open Team</span>
                                                <FontAwesomeIcon icon={faArrowRight} />
                                            </motion.button>
                                        </motion.div>
                                    </motion.div>
                                ))}
                            </motion.div>
                            
                            <br/>
                            <br/>
                            <div style={{
                                margin: '0 auto',
                                width: '100%',
                                maxWidth: '300px',
                                // border: '1px solid rgba(255, 255, 255, .05)',
                                // backgroundColor: 'rgba(255, 255, 255, .05)',
                            }}
                            >
                                <Button style={{
                                    width: '100%',
                                    height: '40px',
                                    borderRadius: '10px',
                                    fontSize: '1.2rem',
                                    overflow: 'hidden',
                                    ...(this.state.isLoggingIn && {
                                        opacity: 0.5,
                                        pointerEvents: 'none'   
                                    })
                                }}
                                onClick={() => {
                                    this.setState({
                                        exiting: true,
                                        targetPath: '/join-team'
                                    })
                                }}
                                >
                                    Join or Create Team
                                </Button>
                            </div>
                        </motion.div>
                    </motion.div>
                </motion.div>
            </PageContainer>
        );
    }
}

FoundTeamPage.contextType = AppContextState;

export default FoundTeamPage;