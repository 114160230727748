import * as React from 'react';
import { Link } from 'react-router-dom';
import { AnimatePresence, motion, MotionValue, stagger, useMotionValue, useSpring, useTransform, useViewportScroll } from 'framer-motion';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons'

import { AppContextState, IAppState } from '../context';

import './PageContainer.scss';
import Header from './header';
import SideOption from './components/SideOption';
import ModalUploader from './ModalUploader';

type Props = {
    pathName: string,
    pageTitle?: string,
    children: React.ReactNode
};
type State = {
    toggleNav: Boolean,
    mounted: Boolean,
    unieqKey: number,
    scrollY: number,
    isNavToggling: Boolean,
    isOpenModalUploader: Boolean,
};
class DashboardContainer extends React.Component<Props, State, IAppState> {
    context!: IAppState;

    state: State = {
        toggleNav: false,
        mounted: false,
        unieqKey: 0,
        scrollY: 0,
        isNavToggling: false,
        isOpenModalUploader: false,
    };

    static toggleModalUploader(status: boolean) {}

    contentScroll = React.createRef<HTMLDivElement>();
    smoothElmScroll = React.createRef<HTMLDivElement>();
    pageElmContainer = React.createRef<HTMLDivElement>();

    containerVariant = {
        initial: {
            y: '0',
            borderRadius: 50,
            opacity: 0
        },
        // animate: {
        //     y: 0,
        //     scale: 1,
        //     transition: {
        //         duration: 1,
        //         ease: [1, .1, .35, 1],
        //     }
        // },
        exit: {
            y: '-100vh',
            opacity: 0,
            borderRadius: 50,
            maxHeight: 0,
            transition: {
                duration: .5,
                ease: [0.5, 0.025, 0, 1] 
            }
        }
    }

    componentDidMount(): void {
        DashboardContainer.toggleModalUploader = (status: boolean) => {
            this.setState({
                isOpenModalUploader: status
            })
        }
        window.document.title = 'Smart Scene Analysis System';
    }

    componentWillUnmount(): void {
        window.removeEventListener('scroll', this.onScrollHandler);
    }

    onScrollHandler = (e: Event) => {
        // if(this.context.crrFeature === this.props.pathName) {
        //     let scrollling = this.smoothElmScroll.current,
        //         compos: any = scrollling ? window.getComputedStyle(scrollling) : {},
        //         matrix = new WebKitCSSMatrix(compos.transform),
        //         currentScroll = window.isMobile ? window.scrollY : matrix.m42 * -1;
        //     this.context.setParallaxPos(currentScroll);   
        // }

        
    }

    toggleNavHandler(state?: boolean) {
        this.setState({
            toggleNav: state != undefined ? state : !this.state.toggleNav,
        });
    }

    render() {
        const { pathName, pageTitle } = this.props;
        const { toggleNav, mounted, unieqKey, scrollY } = this.state;

        const namespace = (pathName.split('/')[1] || 'home') + unieqKey.toString();

        return (<>
            <ModalUploader
                isShowPage={this.state.isOpenModalUploader}
                onClose={()=>{
                    this.setState({
                        isOpenModalUploader: false
                    })
                }}
            />
            <motion.div
                id="PageContainer"
                // layoutId='DashboardPageContainer'
                ref={this.pageElmContainer}
                className={'PageContainer ' + namespace + (window.isMobile ? ' mobile' : '')}
                key={'PageContainer' + namespace}
                style={{
                    paddingTop: this.context.project.project == null ? 0 : 70,
                    paddingBottom: this.context.project.project == null ? 0 : 100,
                    backgroundColor: '#1B1B1C',
                    // overflow: 'unset',
                }}
                variants={this.containerVariant}
                initial='initial'
                animate={{
                    y: 0,
                    // scale: isToggleNav ? 0.95 : 1,
                    opacity: this.state.isOpenModalUploader ? 0.2 : 1,
                    scale: this.state.isOpenModalUploader ? 0.95 : 1,
                    borderRadius: 0,
                    transition: {
                        duration: mounted ? 0.5 : .75,
                        ease: window.onFirstMounted || mounted ? [0.5, 0.025, 0, 1] : [1, .1, .35, 1],
                    }
                }}
                exit='exit'
                onAnimationComplete={() => {
                    window.onFirstMounted = false;
                    this.setState({
                        mounted: true,
                        toggleNav: !mounted ? false : toggleNav
                    })
                }}
            >
                {this.context.project.project != null &&
                    <Header pageTitle={pageTitle} onToggleNav={()=>{
                        this.toggleNavHandler();
                    }}
                    onUploadClick={()=>{
                        this.setState({
                            isOpenModalUploader: true
                        })
                    }}
                    toggleState={this.state.toggleNav == true}
                    />
                }
                
                {this.context.project.project != null &&
                    <SideOption sidebarOn={this.state.toggleNav} toggleFn={(state?: boolean)=>{
                        this.toggleNavHandler(state);
                    }} className={ this.state.toggleNav ? 'active' : ''}/>
                }
            
                <motion.div
                    initial={{
                        opacity: 0,
                    }}
                    animate={{
                        opacity: 1,
                        transition: {
                            duration: .35,
                            ease: 'easeInOut',
                            delay: .25
                        }
                    }}
                    style={{
                        padding: '0 20px'
                    }}
                >
                    {this.props.children}
                </motion.div>
            </motion.div>
        </>);
    }
}

DashboardContainer.contextType = AppContextState;

export default DashboardContainer;