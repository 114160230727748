import { Component, createRef, CSSProperties } from 'react'

import style from './index.module.css'
import { type } from 'os';

interface IRecipeProps {
    placeholder?: string,
    style?: CSSProperties
    type?: string,
    onChange(value: string): void
    onSubmit?(value: string): void
    onBlur?(value: string): void
    value?: string
}

class RichBox extends Component<IRecipeProps> {

    inputBox: any = createRef();

    constructor(props: any) {
        super(props);
    }    

    render() {
        return <>
            <div className={ style.textBox } style={ this.props.style }>
                

                <textarea
                    ref={ this.inputBox }
                    placeholder={ this.props.placeholder ? this.props.placeholder : 'Type something'}
                    style={ this.props.style }
                    value={ this.props.value }
                    onChange={ (e: any) => {
                        if(this.props.onChange) {
                            this.props.onChange(e.target.value);
                        }
                    }}
                    onBlur={ (e: any) => {
                        if(this.props.onBlur) {
                            this.props.onBlur(e.target.value);
                        }
                    }}
                    onKeyDown={(e: any) => {
                        if(e.key === 'Enter') {
                            if(this.props.onSubmit) {
                                this.props.onSubmit(e.target.value);
                            }
                        }
                    }}
                ></textarea>
            </div>
        </>
    }
}

export default RichBox;