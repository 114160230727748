import * as React from 'react';
import { Link } from 'react-router-dom';
import { AnimatePresence, motion, stagger } from 'framer-motion';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faGithub } from '@fortawesome/free-brands-svg-icons'
import { faAt, faCheckCircle, faPhotoFilm, faPlusCircle, faTimesCircle, faTrash } from '@fortawesome/free-solid-svg-icons'

import { AppContextState, IAppState } from '../../../context';

import { displayFilesSizeFormat, getData, getRolePowerName, postData, putData, secondToTimeFormat } from '../../../libs/utils';
import ReactPlayer from 'react-player';
import LoadingIcon from '../../LoadingIcon';
import { OnProgressProps } from 'react-player/base';
import Button from '../../components/Interactive/Button';
import TextBox from '../../components/Interactive/Input/TextBox';
import configs from '../../../configs';
import RichBox from '../../components/Interactive/Input/RichBox';

type Props = {
    crrSelectedKey?: string
};
type State = {
    keyword: string
    submitting: boolean
};
class DescriptionPanel extends React.Component<Props, State, IAppState> {
    context!: IAppState;

    state: State = {
        keyword: '',
        submitting: false
    };


    componentDidMount(): void {
        const targetVidel = this.context.uploadList.find((item: any) => item.key === this.props.crrSelectedKey);
        if(targetVidel && targetVidel.data) { 
            this.setState({
                keyword: targetVidel.data.description
            })
        }

        
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: IAppState | undefined): void {
        if(prevProps.crrSelectedKey != this.props.crrSelectedKey) {
            const targetVidel = this.context.uploadList.find((item: any) => item.key === this.props.crrSelectedKey);
            if(targetVidel && targetVidel.data) { 
                this.setState({
                    keyword: targetVidel.data.description
                })
            } else {
                this.setState({
                    keyword: ''
                })
            }
        }
    }
    

    async getFileDetail() {
        const targetVidel = this.context.uploadList.find((item: any) => item.key === this.props.crrSelectedKey);
        if(targetVidel && targetVidel.data) {
            let file_id = targetVidel.data.file_id;
            let result = await getData('/api/storage/getFileDetail?file_id=' + file_id)
            console.log('targetVidel', targetVidel)
            if(result.status === 200) {
                const data = await result.json();
                console.log("data", data)
                this.context.updateUploadList(targetVidel.key, {
                    ...targetVidel,
                    filename: data.file.title,
                    data: {
                        ...targetVidel.data,
                        ...data
                    }
                });
            }
        }
    }

    async updateFootage(status: string = 'inactive') {
        if(this.state.submitting) return;
        this.setState({
            submitting: true
        })
        const targetVidel = this.context.uploadList.find((item: any) => item.key === this.props.crrSelectedKey);
        if(targetVidel) {
            let result = await putData('/api/storage/updateFile', {
                file_id: targetVidel.data.file_id,
                description: this.state.keyword,
                status: status,
            })
            this.getFileDetail();
        }

        this.setState({
            submitting: false
        })
    }


    render() {
        // const { textColor, crrFeature, isToggleNav }: IAppState = this.context;
        // console.log(this.context)

        const { auth, project, uploadList } = this.context;

        const targetVidel = uploadList.find((item: any) => item.key === this.props.crrSelectedKey);

        // console.log(targetVidel)

        // targetVidel.data.raw_data

        return (
            <motion.div id="DescriptionPanel">
                {(!targetVidel) && <motion.div className='centerContain'>
                    <motion.h1 className='title'>No Video Selected</motion.h1>
                    <motion.p className='desc'>Please select a video to continue</motion.p>
                </motion.div>}
                {(targetVidel && targetVidel.percent >= 100 && !targetVidel.data) && <motion.div className='centerContain'>
                    <LoadingIcon size={20} border={4} />
                    <br/><br/>
                    <p className='tag-title'>Your Video is proccessing...</p>
                </motion.div>}
                {targetVidel && targetVidel.data && <>
                    <motion.p className="topic">Video Description</motion.p>
                    <RichBox
                        style={{
                            paddingRight: 0,
                            backgroundColor: 'transparent',
                            color: 'rgb(211, 211, 211)',
                            padding: '0 10px',
                        }}
                        placeholder='Enter your description here...'
                        value={this.state.keyword}
                        onChange={(value: string) => {
                            const targetVidel = this.context.uploadList.find((item: any) => item.key === this.props.crrSelectedKey);
                            if(targetVidel) {
                                this.context.updateUploadList(targetVidel.key, {
                                    ...targetVidel,
                                    data: {
                                        ...targetVidel.data,
                                        description: value
                                    }
                                });
                            }
                            this.setState({
                                keyword: value
                            })
                        }}

                        onBlur={() => {
                            this.updateFootage('inactive')
                        }}
                    />
                </>}
            </motion.div>
        );
    }

}

DescriptionPanel.contextType = AppContextState;

export default DescriptionPanel;