import * as React from 'react';
import { Link, Navigate } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import PageContainer from '../../../common/PageContainer';
import { Router, Route } from 'react-router-dom';

import './index.scss';

import { AppContextState, IAppState } from '../../../context';
import Button from '../../../common/components/Interactive/Button'
import TextBox from '../../../common/components/Interactive/Input/TextBox';
import { getData, postData, setCookie, displayFilesSizeFormat, postUplaodFile, sleep, deleteData } from '../../../libs/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faImage, faDownload, faTrash, faTimes, faWarning, faQrcode, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import DashboardContainer from '../../../common/DashboardContainer';
import VideoList from '../../../common/components/Video-List';
import LoadingIcon from '../../../common/LoadingIcon';
import ReactPlayer from 'react-player';
import configs from '../../../configs';
import { FileUploader } from 'react-drag-drop-files';

type PageProps = {
    price: string
    onClose: (shouldRefresh: boolean) => void
    planDetail: any
};
type PageState = {
    loading: Boolean,
    shouldRefresh: boolean
    qrCodeData: any
    refCode: string
};
class PaymentModal extends React.Component<PageProps, PageState, IAppState> {
    context!: IAppState;

    state = {
        loading: false,
        shouldRefresh: false,
        qrCodeData: null,
        refCode: '',
    };

    constructor(props: any) {
        super(props);
        this.createqQRCode = this.createqQRCode.bind(this);
    }

    componentDidMount() {
        
    }

    componentWillUnmount(): void {
        clearInterval(this.inQueryCheck);
    }

    async createqQRCode() {
        this.setState({
            loading: true
        })
        let result = await postData('/api/team/buyTeamStoragePlan', {
            project_id: this.context.project.project?.project_id,
            price: this.props.price
        })
        if(result.status === 200) {
            const data = await result.json();
            if(data.qrCodeData === null) {
                alert('Cannot create QRCode');
                return;
            }
            this.setState({
                loading: false,
                qrCodeData: data.qrCodeData,
                refCode: data.refCode,
            })
            this.inQueryCheck = setInterval(() => {
                this.checkInQuery();
            }, 2000);
        } else {
            alert('Sorry, something went wrong. Please try again later.');
            // this.props.onClose(this.state.shouldRefresh);
        }
    }

    inQueryCheck: any;
    async checkInQuery() {
        let result = await postData('/api/team/getPaymentInquery', {
            project_id: this.context.project.project?.project_id,
            refCode: this.state.refCode
        })
        if(result.status === 200) {
            const data = await result.json();
            if(data.paidStatus === true) {
                await this.context.refreshSession();
                await sleep(1000);
                this.setState({
                    shouldRefresh: true
                })
                this.props.onClose(true);
            }
        }
    }

    render() {
        // console.log(this.props)
        const { planDetail } = this.props;

        return (
            <motion.div className='PaymentModal'>
                <motion.div className='modalBg'
                    initial={{ opacity: 0 }}
                    animate={{
                        opacity: 1,
                        transition: {
                            duration: 0.3,
                        }
                    }}
                    exit={{
                        opacity: 0,
                    }}
                    onClick={() => {
                        if(this.state.qrCodeData) return;
                        this.props.onClose(this.state.shouldRefresh);
                    }}
                ></motion.div>
                <motion.div className='modalContent'>
                    
                    
                    <motion.div className='sub'
                        layoutId={`payment-modal-price-${this.props.price}`}
                    >
                        <p className="title">Change Usage Plan</p>
                        { this.state.loading && <motion.div
                            style={{
                                position: 'relative',
                                minHeight: 400,
                            }}
                        >
                                <motion.div className='centerContain'>
                                    <LoadingIcon/>
                                </motion.div>
                            </motion.div>
                        }
                        {!this.state.loading &&
                            <motion.button className='closeBtn'
                                initial={{
                                    opacity: 0,
                                }}
                                animate={{
                                    opacity: 1,
                                    transition: {
                                        duration: .5,
                                        delay: .25,
                                    }
                                }}
                                onClick={() => {
                                    this.props.onClose(this.state.shouldRefresh);
                                }}
                            >
                                <FontAwesomeIcon icon={faTimes} />
                            </motion.button>
                        }

                        { !this.state.loading && !this.state.qrCodeData && <>
                            {this.props.price != "0" && <motion.div style={{
                                color: 'rgba(255, 255, 255, .75)',
                            }}>
                                <p>After you click the "Pay Now" button. It will create a QRCode to scan in your bank mobile app</p>
                                <br/>
                                <p>Do you want to change the current plan to {planDetail.price}฿?</p>
                            </motion.div>}

                            {this.props.price == "0" && <motion.div style={{
                                color: 'rgba(255, 255, 255, .75)',
                            }}>
                                <p>You will go back to this plan automatic when your current plan expired.</p>
                            </motion.div>}
                            <br/>
                            <motion.div className='planList'>
                                <motion.div className={'planItem selected'}>
                                    <motion.div className='sub'>
                                        <motion.div className='priceBanner' style={{
                                            backgroundColor: planDetail.price > 0 ? '#3e54aa' : ''
                                        }}>
                                            <p className="displayPrice">{planDetail.price == 0 ? 'Free' : planDetail.price + ' ฿'}</p>
                                        </motion.div>
                                        <motion.div className='item-detail'>
                                            <motion.div className='line'>
                                                <p>Storage</p>
                                                <p>{planDetail.storage} GB</p>
                                            </motion.div>
                                            <motion.div className='line'>
                                                <p>Reporter Account</p>
                                                <p>{planDetail.reporter}</p>
                                            </motion.div>
                                        </motion.div>
                                        <motion.div className='bottom-range'>
                                            <p>{planDetail.duration}</p>
                                        </motion.div>
                                    </motion.div>
                                </motion.div>
                            </motion.div>
                            <br/>
                            
                            {this.props.price != "0" && <>
                                <p style={{
                                    color: '#ff6d6d',
                                    textAlign: 'center',
                                }}>
                                    <FontAwesomeIcon icon={faWarning}/>
                                    <span>  Your old plan will not be refund after you switch to new plan.</span>
                                </p>
                                <br/>
                            </>}
                            <motion.div>
                                <Button
                                    onClick={() => {
                                        this.createqQRCode();
                                    }}
                                    style={{
                                        width: '100%',
                                        height: 45,
                                        fontWeight: 600,
                                        pointerEvents: this.props.price == "0" ? 'none' : 'unset',
                                        opacity: this.props.price == "0" ? 0.5 : 1,
                                    }}
                                >
                                    { this.props.price != "0" && <>
                                        <FontAwesomeIcon icon={faQrcode}/>
                                        <span> Pay Now</span>
                                    </>}

                                    { this.props.price == "0" && <>
                                        <span>Nothing to do</span>
                                    </>}
                                </Button>
                            </motion.div>
                        </>}

                        {!this.state.loading && this.state.qrCodeData && <motion.div
                            initial={{
                                opacity: 0,
                            }}
                            animate={{
                                opacity: 1,
                            }}
                        >
                            <motion.div className=''
                                style={{
                                    position: 'relative',
                                    textAlign: 'center',
                                    width: '100%',
                                    height: 400,
                                    maxHeight: '80vh',
                                }}
                            >
                                <p style={{
                                    marginBottom: 20,
                                }}>Scan this QRCode in your bank mobile app</p>
                                <img src={
                                    'data:image/png;base64,' + this.state.qrCodeData['qrImage']
                                } alt=""
                                    style={{
                                        width: 350
                                    }}
                                />
                            </motion.div>
                            <br/>
                            <p style={{
                                color: 'rgba(255, 255, 255, .75)',
                                textAlign: 'center',
                            }}>REFCODE: {this.state.refCode}</p>
                            <p style={{
                                color: 'rgba(255, 255, 255, .75)',
                                textAlign: 'center',
                            }}>
                                <FontAwesomeIcon icon={faInfoCircle}/>
                                <span> This popup will close automatic when you pay success.</span>
                            </p>
                            <br/>
                            <motion.div>
                                <Button
                                    onClick={() => {
                                        this.createqQRCode();
                                    }}
                                    style={{
                                        width: '100%',
                                        height: 45,
                                        fontWeight: 600,
                                        pointerEvents: 'none',
                                    }}
                                >
                                    <motion.div style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}>
                                        <div style={{
                                            width: 20,
                                        }}><LoadingIcon size={10} border={2}/></div>
                                        <p style={{marginLeft: 10}}>Checking...</p>
                                    </motion.div>
                                </Button>
                            </motion.div>
                        </motion.div>}
                    </motion.div>
                </motion.div>
            </motion.div>
        );
    }

}

PaymentModal.contextType = AppContextState;

export default PaymentModal;