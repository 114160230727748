import React, { useState, useEffect } from 'react';
import {
    useLocation,
    Routes,
    Route
} from 'react-router-dom';
import { LayoutGroup, AnimatePresence, motion, color } from 'framer-motion';
// Components
import Header from './common/header';
import LoadingIcon from './common/LoadingIcon';
// Pages
import AuthPage from './auth';

import { AppContextState, IAppState } from './context';

import './App.scss';
import { checkIsMobile, checkSession, getCookie, displayFilesSizeFormat, getData } from './libs/utils';
import JoinTeamPage from './join-team';
import FoundTeamPage from './join-team/foundTeam';
import DashboardPage from './dashboard';
import Button from './common/components/Interactive/Button';
import { Link } from 'react-router-dom';
import ActorPage from './actor';
import DashboardContainer from './common/DashboardContainer';
import MembersPage from './members';
import ActivitiesPage from './activities';
import { radiansToDegrees } from 'popmotion';
import BillingPage from './billing';


export default function App() {
    const location = useLocation();
    const [isPlan6MonthHighlighted, setIsPlan6MonthHighlighted] = useState(false);
    const [isPlan9MonthHighlighted, setIsPlan9MonthHighlighted] = useState(false);
    const [isPlan12MonthHighlighted, setIsPlan12MonthHighlighted] = useState(false);
    const [isPlan24MonthHighlighted, setIsPlan24MonthHighlighted] = useState(false);

    const [billUsedSpace, setBillUsedSpace] = useState(0);
    const [billTotalSpace, setBillTotalSpace] = useState(0);

    const [usedSpace, setUsedSpace] = useState(0);
    const [totalSpace, setTotalSpace] = useState(0);


    const handlePlan6MonthHover = () => {
        setIsPlan6MonthHighlighted(true);
    };
    const handlePlan6MonthLeave = () => {
        setIsPlan6MonthHighlighted(false);
    };

    const handlePlan9MonthHover = () => {
        setIsPlan9MonthHighlighted(true);
    };
    const handlePlan9MonthLeave = () => {
        setIsPlan9MonthHighlighted(false);
    };

    const handlePlan12MonthHover = () => {
        setIsPlan12MonthHighlighted(true);
    };
    const handlePlan12MonthLeave = () => {
        setIsPlan12MonthHighlighted(false);
    };

    const handlePlan24MonthHover = () => {
        setIsPlan24MonthHighlighted(true);
    };
    const handlePlan24MonthLeave = () => {
        setIsPlan24MonthHighlighted(false);
    };

    const [paymentInfo, setPaymentInfo] = useState({
        cardNumber: '',
        cardName: '',
        expirationDate: '',
        cvv: '',
    });

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        console.log('Input changed:', e.target.value);
        const { name, value } = e.target;
        setPaymentInfo((prev) => ({ ...prev, [name]: value }));
    };


    const handleNextClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        // Add logic to handle the "Next" button click
        // For example, you can navigate to the next step or perform further actions.
    };

    return (
        <LayoutGroup key={'mainLayoutGroup'}>
            <AnimatePresence mode='sync' key="routeAnimated">
                <AppClass>
                    <DashboardContainer key={'dashbaord'} pathName='/dashboard'
                        pageTitle={''}
                    >
                        <Routes location={location} key={location.pathname}>
                            <Route key={'home'} path="/" element={<AuthPage key={'authen'} />} />
                            <Route key={'join-team'} path="/join-team" element={<JoinTeamPage key={'join-theme'} />} />
                            <Route key={'found-team'} path="/teams" element={<FoundTeamPage key={'found-theme'} />} />
                            <Route key={'dashboard'} path="/dashboard" element={<DashboardPage key={'dashboard'} />} />
                            <Route key={'actors'} path="/actors" element={<ActorPage key={'actors'} />} />
                            <Route key={'activities'} path="/activities" element={<ActivitiesPage key={'activities'} />} />
                            <Route key={'members'} path="/members" element={<MembersPage key={'members'} />} />
                            <Route key={'billing'} path="/billing" element={<BillingPage key={'payment'} />} />
                            <Route key={'not-found'} path="*" element={<motion.div className='centerContain'>
                                {(() => {
                                    // window.document.title = '404 - Page not found';
                                    return false;
                                })() && <></>}
                                <motion.div
                                    initial={{
                                        opacity: 0,
                                        scale: 0,
                                    }}
                                    animate={{
                                        opacity: 1,
                                        scale: 1,
                                    }}
                                    exit={{
                                        opacity: 0,
                                        scale: 0,
                                    }}
                                    transition={{
                                        duration: .3,
                                    }}
                                >
                                    <h1>404</h1>
                                    <h3>Page not found</h3>
                                    <br/>
                                    <Link to='/'>
                                        <Button onClick={() => {
                                            // window.location.href = '/';
                                        }}>Go back to home</Button>
                                    </Link>
                                </motion.div>
                            </motion.div>} />
                        </Routes>
                    </DashboardContainer>
                </AppClass>
            </AnimatePresence>
        </LayoutGroup>
    );
}

declare global {
    interface Window {
        onLoadSuccessfully: Function;
        onFirstMounted: boolean;
        isMobile: boolean;
        player: any;
    }
}

interface IAppClassProps {
    children?: React.ReactNode;
}

interface IAppClassState {
    isLoaded: boolean;
}
class AppClass extends React.Component<IAppClassProps, IAppClassState, IAppState> {
    context!: IAppState;

    state: IAppClassState = {
        isLoaded: false,
    }

    constructor(props: IAppClassProps) {
        super(props);
        window.isMobile = checkIsMobile();
    }

    componentDidMount(): void {
        window.document.title = 'Smart Scene Analysis System';
        document.getElementById('preloaderTxt')?.remove();
        window.onLoadSuccessfully = () => {
            window.onFirstMounted = true;
            setTimeout(() => {
                this.setState({
                    isLoaded: true,
                });
            }, 1000);
        }
    }
    render() {
        return (
            <>
                {this.state.isLoaded && this.props.children}
                <AnimatePresence mode='sync' key="landing-loader">
                    {!this.state.isLoaded && <motion.div className="loadingCenter">
                        <motion.div className="sub"
                            initial={{ opacity: 0 }}
                            animate={{
                                opacity: 1,
                                transition: {
                                    duration: 1,
                                }
                            }}
                            exit={{
                                opacity: 0,
                                scale: 0,
                            }}
                        >
                            <LoadingIcon />
                        </motion.div>
                    </motion.div>}
                </AnimatePresence>
            </>
        );
    }
}

AppClass.contextType = AppContextState;