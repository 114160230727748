import * as React from 'react'; // Import React here once
import { Link, Navigate } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import PageContainer from '../common/PageContainer';
import { Router, Route } from 'react-router-dom';

import './index.scss';

import { AppContextState, IAppState } from '../context';
import Button from '../common/components/Interactive/Button'
import TextBox from '../common/components/Interactive/Input/TextBox';
import { getData, postData, setCookie } from '../libs/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faFile, faPhotoVideo } from '@fortawesome/free-solid-svg-icons';
import DashboardContainer from '../common/DashboardContainer';
import VideoList from '../common/components/Video-List';
import LoadingIcon from '../common/LoadingIcon';
import VideoPreviewModal from '../common/components/VideoPreview';
import configs from '../configs';
import { getRolePowerName } from '../libs/utils';

interface MemberDetailModalProps {
    member_id: string;
    saveMember: (memberData: any) => void;
    onClose: () => void;
}

type PageProps = {
};
type PageState = {
    loading: Boolean,
    activitiesList: any[],
    crrPreviewVideo?: string,
    crrThumnail?: string,
    lastKeywordSearch: string,
    lastContentUpdate: number, // เพิ่มคุณสมบัติเพื่อเก็บประวัติการอัพเดต
    memberRoleUpdates: any[], // เก็บประวัติการอัพเดต member role
    showMemberDetailModal: boolean,
    selectedMemberId: string | null,
};
class MemberDetailModal extends React.Component<MemberDetailModalProps> {
    // โค้ดสำหรับ MemberDetailModal
}

class ActivitiesPage extends React.Component<PageProps, PageState, IAppState> {
    context!: IAppState;

    

    state: PageState = {
        loading: true,
        activitiesList: [],
        crrPreviewVideo: undefined,
        crrThumnail: undefined,
        lastKeywordSearch: '',
        lastContentUpdate: 0,
        memberRoleUpdates: [],
        showMemberDetailModal: false, // เพิ่มค่าสถานะนี้
        selectedMemberId: null, // เพิ่มค่าสถานะนี้
    };



    openMemberDetailModal = (memberId: string) => {
        this.setState({
            showMemberDetailModal: true,
            selectedMemberId: memberId,
        });
    }

    closeMemberDetailModal = () => {
        this.setState({
            showMemberDetailModal: false,
            selectedMemberId: null,
        });
    }

    handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            // ทำสิ่งที่คุณต้องการกับค่า this.state.lastKeywordSearch
            const searchValue = this.state.lastKeywordSearch;
            this.navigateToSearchResult(searchValue);
        }
    }
    saveMember = (memberData: any) => {
        // ทำอะไรสักอย่างกับข้อมูลสมาชิกที่เกิดการเปลี่ยนแปลง
    }

    navigateToSearchResult = (searchValue: string) => {
        // สร้าง URL ที่เกี่ยวข้องกับการค้นหา
        const searchURL = `/search/${searchValue}`; // ปรับแต่ง URL ตามที่คุณต้องการ

        // นำคุณไปยัง URL โดยใช้ React Router Navigate
        // คุณควรจัดเรียงเป็น component แยกหรือทำการจัดการใน react-router ตามที่คุณมีอยู่
        // เช่น ใช้ component ที่คุณกำลังสร้างไปยังตำแหน่งนี้
        // <Navigate to={searchURL} replace={true} />; // รายละเอียดการใช้งานต้องแตกต่างขึ้นอยู่กับโครงสร้างของคุณ
    }

    componentDidMount() {
        this.getActivitiesList();
    }

    componentWillUnmount(): void {

    }

    componentDidUpdate(prevProps: Readonly<PageProps>, prevState: Readonly<PageState>, snapshot?: IAppState | undefined): void {

    }

    getActivitiesList = async () => {
        let result = await getData('/api/team/getTeamLogs?project_id=' + this.context.project.project?.project_id + '&keywords=' + this.state.lastKeywordSearch);
        this.setState({
            loading: false
        });
        if (result.status === 200) {
            const data = await result.json();
            if (data.logs) {
                let videoListByDate: any = {};
                let memberRoleUpdates: any = {}; // เพิ่มประวัติการอัพเดต member role
                data.logs.forEach((item: any) => {
                    const date = new Date(item.created_date);
                    const dayOfWeek = date.toLocaleDateString('en-US', { weekday: 'short' });
                    const day = date.toLocaleDateString('en-US', { day: '2-digit' });
                    const month = date.toLocaleDateString('en-US', { month: 'short' });
                    const year = date.toLocaleDateString('en-US', { year: 'numeric' });
                    let formattedDate = `${dayOfWeek}\n${day}\n${month}\n${year}`;
                    // check if date is today
                    if (formattedDate.includes('Today')) {
                        formattedDate = formattedDate.replace('Today', 'Sat');
                    }

                    if (!videoListByDate[formattedDate]) {
                        videoListByDate[formattedDate] = [];
                    }
                    videoListByDate[formattedDate].push(item);

                    // ตรวจสอบประเภทของกิจกรรม
                    if (item.type === 'Update member role') {
                        if (!memberRoleUpdates[item.user.username]) {
                            memberRoleUpdates[item.user.username] = [];
                        }
                        memberRoleUpdates[item.user.username].push({
                            role: item.user.role_power,
                            timestamp: item.created_date,
                        });
                    }
                });

                this.setState({
                    activitiesList: Object.keys(videoListByDate).map((formattedDate: string) => {
                        return {
                            date: formattedDate,
                            logs: videoListByDate[formattedDate].map((item: any) => {
                                return {
                                    log_id: item.log_id,
                                    project_id: item.proejct_id,
                                    user_id: item.user_id,
                                    file_id: item.file_id,
                                    type: item.type,
                                    created_date: item.created_date,
                                    log_data: JSON.parse(item.log_data),
                                    user: item.user,
                                    fileName: item.fileName,
                                    status: item.status,
                                    uploadTime: item.uploadTime
                                };
                            })
                        };
                    }),
                    memberRoleUpdates
                });
            }
        }
    }


    render() {
        return (
            <>
                {this.state.showMemberDetailModal && (
                    <MemberDetailModal
                        member_id={this.state.selectedMemberId || ''} // หรือใส่ค่าที่เหมาะสมตามรูปแบบทางธุรกิจของคุณ
                        saveMember={this.saveMember}
                        onClose={this.closeMemberDetailModal}
                    />
                )}


                
                {this.state.loading && <div className='loadingCenter'><LoadingIcon size={30} /></div>}
                {!this.state.loading && this.state.activitiesList.length === 0 && <div className="no-footage" style={{
                    position: 'relative',
                    width: '100%',
                    height: '50vh',
                    opacity: .5
                }}>
                    <div className='centerContain'>
                        <FontAwesomeIcon icon={faFile} fontSize={50} />
                        <br /><br />
                        <h1>No log found here</h1>
                    </div>
                </div>}
                {!this.state.loading && this.state.activitiesList.length > 0 &&
                    <motion.div className="activities"
                        initial={{ opacity: 0 }}
                        animate={{
                            opacity: 1,
                            transition: {
                                duration: 0.5,
                            }
                        }}
                        exit={{
                            opacity: 0,
                        }}
                    >
                        {this.state.activitiesList.map((item: any) => {
                            return (
                                <React.Fragment key={item.date}>
                                    <div className="date-info">
                                        <div className="dayOfWeek">{item.date.split('\n')[0]}</div>
                                        <div className="day">{item.date.split('\n')[1]}</div>
                                        <div className="month">{item.date.split('\n')[2]}</div>
                                        <div className="year">{item.date.split('\n')[3]}</div>
                                    </div>
                                    <motion.div className='logList'>
                                        {item.logs.map((log: any) => {
                                            let data = log.log_data;
                                            // ตรวจสอบประเภทของกิจกรรมใน memberRoleUpdates
                                            if (Array.isArray(this.state.memberRoleUpdates)) {
                                                this.state.memberRoleUpdates.forEach(log => {
                                                    if (log.type === 'SaveMember') {
                                                        // ทำสิ่งที่คุณต้องการเมื่อเจอกิจกรรม 'Update member role'
                                                        return (
                                                            <motion.div className='logItem' key={log.log_id}>
                                                                <motion.div className='leftSide'>
                                                                    <div className='logIcon' style={{
                                                                        backgroundImage: 'url(' + (log.user.image_url ? configs.getAssetUrl() + log.user.image_url : 'https://gravatar.com/avatar/${' + this.context.auth.user?.user_id + '}?s=400&d=retro&r=x') + ')'
                                                                    }}></div>
                                                                    <div className='logContent'>
                                                                        <h4 className='logTitle'>
                                                                            <span>{log.user.username}</span>
                                                                            <span style={{ opacity: 0.5 }}>{` (${log.user.email})`}</span>
                                                                        </h4>
                                                                        <p>{`Update member role of ${data.username} to ${getRolePowerName(data.role)}`}</p>
                                                                        <p>{`Time: ${new Date(log.created_date).toLocaleTimeString()}`}</p>
                                                                    </div>
                                                                </motion.div>
                                                                <div className='logTime'>
                                                                    <p>{log.created_date ?? 'N/A'}</p>
                                                                </div>
                                                            </motion.div>
                                                        );
                                                    } else if (log.type === 'Create actor') {
                                                        // ทำสิ่งที่คุณต้องการเมื่อเจอกิจกรรม 'Create actor'
                                                    } else {
                                                        // ทำสิ่งที่คุณต้องการเมื่อเจอกิจกรรมอื่น ๆ
                                                    }
                                                });
                                            }
                                            else if (log.type === 'Create actor') {
                                                // แสดงข้อมูลสำหรับกิจกรรม "Create actor"
                                                return (
                                                    <motion.div className='logItem' key={log.log_id}>
                                                        <motion.div className='leftSide'>
                                                            <div className='logIcon' style={{
                                                                backgroundImage: 'url(' + (log.user.image_url ? configs.getAssetUrl() + log.user.image_url : 'https://gravatar.com/avatar/${' + this.context.auth.user?.user_id + '}?s=400&d=retro&r=x') + ')'
                                                            }}></div>
                                                            <div className='logContent'>
                                                                <h4 className='logTitle'>
                                                                    <span>{log.user.username}</span>
                                                                    <span style={{ opacity: 0.5 }}>{` (${log.user.email})`}</span>
                                                                </h4>
                                                                <p className='logDesc'>{data.message}</p>
                                                                <p>actor name: {data.actorName}</p>
                                                                {/* เพิ่มข้อมูลตำแหน่งของคนที่สร้าง actor */}
                                                                <p>{`Time: ${data.time}`}</p>
                                                            </div>
                                                        </motion.div>
                                                        <div className='logTime'>
                                                            <p>{log.created_date ?? 'N/A'}</p>
                                                        </div>
                                                    </motion.div>
                                                );
                                            } else {
                                                // แสดงกิจกรรมอื่นๆ
                                                return (
                                                    <motion.div className='logItem' key={log.log_id}>
                                                        <motion.div className='leftSide'>
                                                            <div className='logIcon' style={{
                                                                backgroundImage: 'url(' + (log.user.image_url ? configs.getAssetUrl() + log.user.image_url : 'https://gravatar.com/avatar/${' + this.context.auth.user?.user_id + '}?s=400&d=retro&r=x') + ')'
                                                            }}></div>
                                                            <div className='logContent'>
                                                                <h4 className='logTitle'>
                                                                    <span>{log.user.username}</span>
                                                                    <span style={{ opacity: 0.5 }}>{`(${getRolePowerName(log.user.role_power)})`}</span>
                                                                </h4>
                                                                <p className='logDesc'>{data.message}</p>
                                                                <p>Time: {new Date(log.created_date).toLocaleTimeString()}</p>
                                                            </div>
                                                        </motion.div>
                                                        <div className='logTime'>
                                                            <p>{log.created_date ?? 'N/A'}</p>
                                                        </div>
                                                    </motion.div>
                                                    
                                                );
                                            }
                                        })}
                                    </motion.div>
                                    <br />
                                </React.Fragment>
                            );
                        })}

                    </motion.div>
                }
            </>
        );
    }
}

ActivitiesPage.contextType = AppContextState;

export default ActivitiesPage;