import * as React from 'react';
import { Link } from 'react-router-dom';
import { AnimatePresence, motion, stagger } from 'framer-motion';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faGithub } from '@fortawesome/free-brands-svg-icons'
import { faAt, faCheckCircle, faPhotoFilm, faTimesCircle, faTrash } from '@fortawesome/free-solid-svg-icons'

import { AppContextState, IAppState } from '../../../context';
import VideoJS from '../../components/VideoJS';

import { getRolePowerName, sleep } from '../../../libs/utils';
import ReactPlayer from 'react-player';
import LoadingIcon from '../../LoadingIcon';
import { OnProgressProps } from 'react-player/base';

type Props = {
    crrSelectedKey?: string
};
type State = {
    loading: boolean,
    videoConfig: any,
    crrKey: string
};
class PlayerPanel extends React.Component<Props, State, IAppState> {
    context!: IAppState;

    state: State = {
        loading: true,
        crrKey: '',
        videoConfig: {},
    };

    constructor(props: Props) {
        super(props);
        this.handlePlayerReady = this.handlePlayerReady.bind(this);
    }

    componentDidMount(): void {
        this.setPlayer();
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: IAppState | undefined): void {
        // console.log(this.context.keywordSearchInput, this.state.lastKeywordSearch)
        if(prevProps.crrSelectedKey !== this.props.crrSelectedKey) {
            const targetVidel = this.context.uploadList.find((item: any) => item.key === this.props.crrSelectedKey);

            if(targetVidel) {
                this.setPlayer();
                try {
                    // this.playerController?.seekTo(targetVidel.crrDuration);
                } catch (error) {
                    
                }
            }
        }
    }

    async setPlayer() {
        console.log("PLAYERPANEL")
        const targetVidel = this.context.uploadList.find((item: any) => item.key === this.props.crrSelectedKey);

        if(!targetVidel || !targetVidel.data) {
            return 
        }

        this.setState({
            loading: true
        })
        await sleep(100);
        this.setState({
            loading: false,
            crrKey: targetVidel.key,
            // videoConfig: {
            //     autoplay: true,
            //     controls: true,
            //     responsive: true,
            //     fluid: false,
            //     volume: 0.5,
            //     playbackRates: [0.5, 1.0, 1.5, 2.0],
            //     autoSetup: false,
            //     preload: 'metadata',
            //     sources: [
            //         {
            //             src: targetVidel.data.file_path,
            //             type: 'video/mp4',
            //             label: 'auto',
            //         }
            //     ],
            //     plugins: {
            //         videoJsResolutionSwitcher: {
            //             dynamicLabel: false
            //         }
            //     },
            //     poster: '',
            // }
        })
        console.log("PLAYERPANEL TRUTRT")
    }

    playerController: ReactPlayer | null = null;

    playerRef = React.createRef<HTMLInputElement>();
    playerElm: any;
    handlePlayerReady(player: any) {
        this.playerRef = player;
    
        // You can handle player events here, for example:
        player.on('waiting', () => {
        //   videojs.log('player is waiting');
        });
    
        player.on('dispose', () => {
        //   videojs.log('player will dispose');
        });
        // window.player = player;

         // when video second is changed
         player.on('timeupdate', () => {
            // console.log('timeupdate', player.currentTime())
            // console.log('timeupdate', player.duration())

            // console.log('onDuration', progress)
            const { auth, project, uploadList } = this.context;

            const targetVidel = uploadList.find((item: any) => item.key === this.props.crrSelectedKey);

            if(targetVidel && targetVidel.percent >= 100 && !targetVidel.error && targetVidel.data) {
                this.context.updateUploadList(targetVidel.key, {
                    ...targetVidel,
                    crrDuration: player.currentTime()
                })   
            }
        });

        player.on('loadedmetadata', () => {
            const targetVidel = this.context.uploadList.find((item: any) => item.key === this.props.crrSelectedKey);

            if(targetVidel && targetVidel.percent >= 100 && !targetVidel.error && targetVidel.data) {
                player.currentTime(targetVidel.crrDuration ?? 0);
            }
        });
    };

    render() {
        // const { textColor, crrFeature, isToggleNav }: IAppState = this.context;
        // console.log(this.context)

        const { auth, project, uploadList } = this.context;

        const targetVidel = uploadList.find((item: any) => item.key === this.props.crrSelectedKey);

        // console.log(targetVidel)

        return (
            <motion.div id="PlayerPanel">
                {targetVidel && targetVidel.percent < 100 && <motion.div className='loadingCenter'>
                    <LoadingIcon size={30}/>
                </motion.div>}

                {targetVidel && targetVidel.percent >= 100 && targetVidel.error && <motion.div className='centerContain'>
                    <FontAwesomeIcon icon={faTimesCircle} size="3x" color="red"/>
                    <div className='text'>Upload failed please delete and try to uplaod again</div>
                </motion.div>}

                {!this.state.loading && targetVidel && targetVidel.percent >= 100 && !targetVidel.error && targetVidel.data && <VideoJS
                    key={'video-preview'}
                    options={{
                        autoplay: true,
                        controls: true,
                        responsive: true,
                        fluid: false,
                        playbackRates: [0.5, 1.0, 1.5, 2.0],
                        autoSetup: false,
                        preload: 'metadata',
                        sources: [
                            {
                                src: targetVidel.data.file_path,
                                type: 'video/mp4',
                                label: 'auto',
                            }
                        ],
                        plugins: {
                            videoJsResolutionSwitcher: {
                                dynamicLabel: false
                            }
                        },
                        poster: '',
                    }}
                    onReady={this.handlePlayerReady}
                    controls
                    width={'100%'}
                    height={'100%'}
                    playing={true}
                />}
                
                {/* <ReactPlayer url={targetVidel.data.file_path} 
                    ref={p => this.playerController = p}
                    controls
                    width={'100%'}
                    height={'100%'}
                    playing={true}
                    volume={0.1}
                    onProgress={(progress: OnProgressProps) => {
                        // console.log('onDuration', progress)

                        this.context.updateUploadList(targetVidel.key, {
                            ...targetVidel,
                            crrDuration: progress.playedSeconds
                        })
                    }}
                    config={{
                        file: {
                            attributes: {
                                controlsList: 'nodownload'
                            },
                        }
                    }}
                /> */}
            </motion.div>
        );
    }

}

PlayerPanel.contextType = AppContextState;

export default PlayerPanel;