import { Component } from 'react'
import { LayoutGroup, AnimatePresence, motion } from 'framer-motion'

// Fonts & Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faVideo, faPlayCircle, faRotate } from '@fortawesome/free-solid-svg-icons'
import { faFolderOpen } from '@fortawesome/free-regular-svg-icons'

import { sleep, secondToTimeFormat } from '../../../libs/utils'
import { AppContextState, IAppState } from '../../../context';

import Button from '../Interactive/Button'

import styles from './video-list.module.css'
import configs from '../../../configs'

interface IRecipeProps {
    items?: Array<any>
    onVideoClick: (video: any) => void
    crrPreviewVideo?: string
}

type PageState = {

}

interface VideoItems {
    title: String
    duration: Number
    description: String
    tags: Array<String>
    source: String,
    thumbnail: String
    rawData: any
}

class VideoList extends Component<IRecipeProps, PageState, IAppState> {
    context!: IAppState;

    nonItemComponent() {
        return <>
            <div className="centerContain" style={{
                padding: 20,
                backgroundColor: 'rgba(255, 255, 255, .025)',
                border: '1px #545454 solid',
                borderRadius: 10,
                boxShadow: '0 0 10px #0000000f'
            }}>
                <p>There aren&rsquo;t any Footage in your project.</p>
                <Button style={{ marginTop: 30 }}>
                    <FontAwesomeIcon icon={ faVideo }/>
                    <span>Add New Footage</span>
                </Button>
            </div>
        </>
    }

    render() {
        let { items, crrPreviewVideo } = this.props;
        return <>
            { !items && <this.nonItemComponent/> }

            { items && <>
                <motion.div className={styles['video-list']}>
                    
                    {items.map((ctx: VideoItems, index: number)=> {
                        // console.log(crrPreviewVideo, ctx.rawData.file_id, this.props.crrPreviewVideo == ctx.rawData.file_id)
                        return <motion.div key={ 'video-list-' + ctx.rawData.file_id } className={styles['base-v-item']}
                            layoutId={`thumbnail-vid-${ctx.rawData.file_id}-panel`}
                            style={{
                                opacity: crrPreviewVideo == ctx.rawData.file_id ? 0 : 1,
                                transitionDuration: crrPreviewVideo ? '0.5s' : ''
                            }}
                        >
                            <motion.div className={styles['v-item']}>
                                <motion.div className={styles['thumbnail']}
                                    layoutId={`thumbnail-vid-${ctx.rawData.file_id}-video`}
                                    onClick={()=>{
                                        // console.log(ctx)
                                        if(ctx.rawData.raw_data) {
                                            this.props.onVideoClick(ctx)
                                        }
                                    }}
                                >
                                    <motion.img
                                        src={configs.getAssetUrl() + ctx.thumbnail}
                                        onLoad={(e: any)=>{
                                            e.target.style.opacity = 1;
                                        }}
                                    />
                                    {ctx.rawData.raw_data && <FontAwesomeIcon className={styles['play-icon']} icon={faPlayCircle}/>}
                                    {!ctx.rawData.raw_data && <FontAwesomeIcon className={styles['play-icon']} icon={faRotate}/>}
                                    <p className={styles['duration']}>
                                        {!ctx.rawData.raw_data && 'Processing...'}
                                        {ctx.rawData.raw_data && new secondToTimeFormat(ctx.duration).toString() }
                                    </p>
                                </motion.div>
                                <div className={styles['content']}>
                                    <h4 className={styles['title']}>{ ctx.title } {crrPreviewVideo}</h4>
                                    <div className={`${styles['subline']} miniScroll`}>
                                        <span>Tags: </span>
                                        {ctx.tags.length === 0 && <span>None</span>}
                                        {ctx.tags.length > 0 && ctx.tags.map((text: String, index)=>{
                                            return <span className={styles["linkClick"]} key={index}
                                                onClick={() => {
                                                    this.context.setSearchKeyword(text.toString())
                                                }}
                                            >{text}{(index + 1) === ctx.tags.length ? '' : ','} </span>
                                        })}
                                    </div>
                                    <p className={`${styles['desc']} miniScroll`}>{ ctx.description }</p>
                                    {/* <div className={`${styles['tags']} miniScroll`}>
                                        {ctx.tags.map((text: String, index)=>{
                                            return <p key={index}>{text}</p>
                                        })}
                                    </div> */}
                                </div>
                            </motion.div>
                        </motion.div>
                    })}
                </motion.div>
            </>}
        </>
    }
}

VideoList.contextType = AppContextState;

export default VideoList;