import * as React from 'react';
import { Link } from 'react-router-dom';
import { AnimatePresence, motion, stagger } from 'framer-motion';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faGithub } from '@fortawesome/free-brands-svg-icons'
import { faArrowDown, faArrowUp, faAt, faChevronDown, faChevronUp, faPhotoFilm, faSearch, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons'

import { AppContextState, IAppState } from '../context';

import './header.scss';
import { getRolePowerName } from '../libs/utils';
import UserOptionModal from './components/UserOptionModal';
import UserProfileModal from './components/UserProfileModal';
import configs from '../configs';
import TeamProfileModal from './components/TeamProfileModal';

type Props = {
    pageTitle?: string,
    toggleState: boolean
    onToggleNav?: () => void
    onUploadClick?: () => void
};
type State = {
    showUserModal: boolean
    showUserProfileModal: boolean
    showTeamProfileModal: boolean
    keywords: string
    showSearch: boolean
};
class Header extends React.Component<Props, State, IAppState> {
    context!: IAppState;

    state: State = {
        showUserModal: false,
        showUserProfileModal: false,
        showTeamProfileModal: false,
        keywords: '',
        showSearch: false
    };

    containerVariant = {
        hidden: { 
            
        },
        show: {
            // transition: {
            //     staggerChildren: .2,
            //     staggerDirection: 1
            // }
        }
    }

    componentDidMount(): void {
        
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: IAppState | undefined): void {
        // console.log(this.context.keywordSearchInput, this.state.lastKeywordSearch)
        if(prevState.keywords === this.state.keywords && prevState.keywords !== this.context.keywordSearchInput) {
            this.setState({
                keywords: this.context.keywordSearchInput || ''
            })
        }
    }

    searchInterval: any = null;

    onSearchHandler = (e: React.ChangeEvent<HTMLInputElement>) => {

        this.setState({
            keywords: e.target.value
        })

        if(this.searchInterval) {
            clearTimeout(this.searchInterval);
        }

        this.searchInterval = setTimeout(() => {
            console.log(e.target.value)
            this.context.setSearchKeyword(e.target.value);

            clearTimeout(this.searchInterval);
        }, 500)
    }

    getPageName() {
        const pathName = window.location.pathname;
        switch (pathName) {
            case '/dashboard':
                return 'Footage';
            case '/actors':
                return 'Actors';
            case '/activities':
                return 'Activities';
            case '/members':
                return 'Members';
            case '/billing':
                return 'Billing';
            default:
                return 'Dashboard';
        }
    }

    render() {
        // const { textColor, crrFeature, isToggleNav }: IAppState = this.context;
        // console.log(this.context)

        const { auth, project } = this.context;


        return (
            <>
                {this.state.showUserModal && <UserOptionModal
                    modalKey='mainUserOption'
                    onClose={()=>{
                        this.setState({
                            showUserModal: false
                        })
                    }}
                    visible={!this.state.showUserProfileModal && !this.state.showTeamProfileModal}
                    onOpenUserProfileModal={()=>{
                        this.setState({
                            // showUserModal: false,
                            showUserProfileModal: true
                        })
                    }}
                    onTeamProfileModal={()=>{
                        this.setState({
                            // showUserModal: false,
                            showTeamProfileModal: true
                        })
                    }}
                />}

                { this.state.showUserProfileModal && <UserProfileModal onClose={(shouldRefresh: boolean) => {
                    this.setState({
                        showUserProfileModal: false
                    })
                } }/>}

                { this.state.showTeamProfileModal && <TeamProfileModal onClose={(shouldRefresh: boolean) => {
                    this.setState({
                        showTeamProfileModal: false
                    })
                } }/>}
                <motion.div className='header'
                    // layoutId='page-header'
                    initial={{
                        y: -100,
                        opacity: 0
                    }}
                    animate={{
                        y: 0,
                        opacity: 1
                    }}
                    transition={{
                        duration: 1,
                        ease: [1, .1, .35, 1]
                    }}
                >
                    <motion.div className='sub header-context'>
                        <motion.div className='burger' onClick={() => {
                            if (this.props.onToggleNav) {
                                this.props.onToggleNav();
                            }
                        }}>
                            <motion.div className='line'>
                                <motion.div className='yeet' animate={{
                                    transformOrigin: 'center',
                                    rotate: this.props.toggleState ? 45 : 0,
                                    x: this.props.toggleState ? -5 : 0,
                                    y: this.props.toggleState ? 5.5 : 0,
                                }}></motion.div>
                            </motion.div>
                            <motion.div className='line'>
                                <motion.div className='yeet' animate={{
                                    // scale: this.props.toggleState ? 0 : 1,
                                    width: this.props.toggleState ? 0 : 20,
                                    x: this.props.toggleState ? 0 : -5,
                                    opacity: this.props.toggleState ? 0 : 1
                                }}></motion.div>
                            </motion.div>
                            <motion.div className='line'>
                                <motion.div className='yeet' animate={{
                                    transformOrigin: 'center',
                                    rotate: this.props.toggleState ? -45 : 0,
                                    x: this.props.toggleState ? -5 : 0,
                                    y: this.props.toggleState ? -9 : 0,
                                }}></motion.div>
                            </motion.div>
                        </motion.div>
                        <motion.div className='siteName'>
                            <img src='img/logo-white.png' style={{
                                height: 20,
                                transform: 'scale(1.18) translateY(2px)'
                            }} />
                            <span style={{
                                display: 'inline-block',
                                marginLeft: 10,
                            }}>{this.getPageName() || 'Dashboard'}</span>
                        </motion.div>
                        <motion.div className={'searchBox ' + (this.state.showSearch ? 'active' : '')}>
                            <input type='text' placeholder='Search...' onChange={(e) => {
                                // console.log(e.target.value)
                                this.onSearchHandler(e); 
                            }} value={this.state.keywords || ''} />
                            {this.state.keywords &&
                                <button className='btnEmpty' onClick={() => {
                                    this.setState({
                                        keywords: ''
                                    })
                                    this.context.setSearchKeyword('');
                                }}>
                                    <FontAwesomeIcon icon={faTrash} />
                                </button>
                            }
                            <p className={'btnCloseSearch ' + (this.state.showSearch ? 'active' : '')} onClick={()=>{
                                this.setState({
                                    showSearch: false
                                })
                            }}>
                                <FontAwesomeIcon icon={faTimes} />
                            </p>
                        </motion.div>
                        
                        <p className={'btnSearch ' + (this.state.showSearch ? 'active' : '')} onClick={()=>{
                            this.setState({
                                showSearch: true
                            })
                        }}>
                            <FontAwesomeIcon icon={faSearch} />
                        </p>

                        <motion.div className='userPanel'>
                            { this.context.project != null && this.context.project!.rolePower! >= 2 &&
                                <motion.button className='btnUpload'
                                    onClick={() => {
                                        if (this.props.onUploadClick) {
                                            this.props.onUploadClick();
                                        }
                                    }}
                                >
                                    <FontAwesomeIcon icon={faPhotoFilm} />
                                    <span style={{
                                        marginLeft: 5
                                    }}> Upload</span>
                                </motion.button>
                            }
                            <motion.div className='userContainer'
                                // layoutId={`modal-user-option-mainUserOption`}
                                onClick={()=>{
                                    this.setState({
                                        showUserModal: !this.state.showUserModal
                                    })
                                }}
                            >
                                <motion.div className='userImg'
                                    style={{
                                        backgroundImage: `url("${this.context.auth.user?.image_url ? (configs.getAssetUrl() + this.context.auth.user?.image_url) : 'https://gravatar.com/avatar/' + this.context.auth.user?.user_id + '?s=400&d=retro&r=x'}")`
                                    }}
                                ></motion.div>
                                <motion.div className='userName'>
                                    <p className='name'>{auth.user?.username || 'Username'}</p>
                                    <p className='role'>{getRolePowerName(project.rolePower || 0)}</p>
                                </motion.div>
                                <motion.div className='arrowDrop' style={{
                                    marginLeft: 10,
                                    height: 35
                                }}
                                    animate={{
                                        rotate: this.state.showUserModal ? 180 : 0,
                                        y: this.state.showUserModal ? -12 : 0,
                                        transition: {
                                            duration: .2,
                                            ease: [.1, .1, .1, 1]
                                        }
                                    }}
                                >
                                    <FontAwesomeIcon icon={faChevronDown} />
                                </motion.div>
                            </motion.div>
                        </motion.div>
                    </motion.div>
                </motion.div>
            </>
        );
    }

}

Header.contextType = AppContextState;

export default Header;