import React from 'react';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import 'videojs-resolution-switcher-vjs7/videojs-resolution-switcher.css';

window.videojs = videojs;

require('videojs-resolution-switcher-vjs7/videojs-resolution-switcher.js');

export const VideoJS = (props) => {
  const videoRef = React.useRef(null);
  const playerRef = React.useRef(null);
  const {options, onReady} = props;

  React.useEffect(() => {
    // Make sure Video.js player is only initialized once
    if (!playerRef.current) {
      // The Video.js player needs to be _inside_ the component el for React 18 Strict Mode. 
      const videoElement = document.createElement("video-js");

      videoElement.classList.add('vjs-big-play-centered');
      videoRef.current.appendChild(videoElement);

      const player = playerRef.current = videojs(videoElement, options, () => {
        videojs.log('player is ready');
        onReady && onReady(player);
        
        player.on('resolutionchange', function(){
          console.info('Source changed to %s', player.src())
        })

        try {
          player.updateSrc(options.sources);
        } catch (error) {
          
        }

      });

      window.player = player;
    // You could update an existing player in the `else` block here
    // on prop change, for example:
    } else {
      const player = playerRef.current;
      window.player = player;
      player.autoplay(options.autoplay);
      player.volume(options.volume);
    }

  }, [options, videoRef]);

  // Dispose the Video.js player when the functional component unmounts
  React.useEffect(() => {
    const player = playerRef.current;

    return () => {
      if (player && !player.isDisposed()) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  return (
    <div data-vjs-player style={{
      width: '100%',
      height: '100%',
    }}>
      <div ref={videoRef} style={{
        width: '100%',
        height: '100%',
      }}/>
    </div>
  );
}

export default VideoJS;