import * as React from 'react';
import { Link } from 'react-router-dom';
import { AnimatePresence, motion, stagger } from 'framer-motion';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faGithub } from '@fortawesome/free-brands-svg-icons'
import { faAt, faCheckCircle, faCircle, faCircleNodes, faPhotoFilm, faPlusCircle, faRobot, faSearch, faStar, faTimesCircle, faTrash, faUser } from '@fortawesome/free-solid-svg-icons'

import { AppContextState, IAppState } from '../../../context';

import { getData, getRolePowerName, postData, secondToTimeFormat } from '../../../libs/utils';
import ReactPlayer from 'react-player';
import LoadingIcon from '../../LoadingIcon';
import { OnProgressProps } from 'react-player/base';
import Button from '../../components/Interactive/Button';
import TextBox from '../../components/Interactive/Input/TextBox';
import configs from '../../../configs';

type Props = {
    crrSelectedKey?: string
};
type State = {
    keyword: string
    addingTag: boolean
};
class IngredientPanel extends React.Component<Props, State, IAppState> {
    context!: IAppState;

    state: State = {
        keyword: '',
        addingTag: false
    };

    timer: any = null;

    componentDidMount(): void {
        this.timer = setInterval(() => {
            this.getFileDetail();
        }, 3000);
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: IAppState | undefined): void {
        if(prevProps.crrSelectedKey != this.props.crrSelectedKey) {
            this.getFileDetail();
        }
    }

    componentWillUnmount(): void {
        clearInterval(this.timer);
    }

    async getFileDetail() {
        const targetVidel = this.context.uploadList.find((item: any) => item.key === this.props.crrSelectedKey);
        if(targetVidel && targetVidel.data) {
            let file_id = targetVidel.data.file_id;
            let result = await getData('/api/storage/getFileDetail?file_id=' + file_id)
            console.log('targetVidel', targetVidel)
            if(result.status === 200) {
                const data = await result.json();
                console.log("data", data)
                this.context.updateUploadList(targetVidel.key, {
                    ...targetVidel,
                    data: {
                        ...targetVidel.data,
                        ...data
                    }
                });
            }
        }
    }

    async addTag(keyword: string) {
        if(keyword === '') return;
        this.setState({
            addingTag: true
        })
        const targetVidel = this.context.uploadList.find((item: any) => item.key === this.props.crrSelectedKey);
        
        if(targetVidel) {
            let result = await postData('/api/footage/createTag', {
                project_id: this.context.project.project?.project_id,
                tag_name: keyword
            })
            // console.log(result)
            if(result.status === 200) {
                let data = await result.json();
                let tag_id = data.tag.tag_id;
                let file_id = targetVidel.data.file_id;
                let result2 = await postData('/api/footage/mapTag', {
                    tag_id: tag_id,
                    file_id: file_id,
                    duration: targetVidel.crrDuration
                })
                console.log(result2)
                if(result2.status === 200) {
                    const data2 = await result2.json();
                    console.log(data2)
                    this.getFileDetail();
                }
            }
        }

        this.setState({
            addingTag: false,
        })
    }

    async removeTag(id: number) {
        let result = await postData('/api/footage/unmapTag', {
            tag_map_id: id
        })
        console.log(result)
        if(result.status === 200) {
            const data = await result.json();
            console.log(data)
            this.getFileDetail();
        }
    }

    getDuration() {
        const targetVidel = this.context.uploadList.find((item: any) => item.key === this.props.crrSelectedKey);

        if(!targetVidel) return 0;

        return Math.floor(targetVidel.crrDuration || 0);
    }

    render() {
        // const { textColor, crrFeature, isToggleNav }: IAppState = this.context;
        // console.log(this.context)

        const { auth, project, uploadList } = this.context;

        const targetVidel = uploadList.find((item: any) => item.key === this.props.crrSelectedKey);

        // console.log(targetVidel)

        // targetVidel.data.file && targetVidel.data.file.raw_data

        let label_vision = [];
        let actors_list = [];

        // console.log('targetVideltargetVidel', targetVidel)
        if(targetVidel && targetVidel.data && targetVidel.data.file && targetVidel.data.file && targetVidel.data.file.raw_data) {
            let raw_data = JSON.parse(targetVidel.data.file && targetVidel.data.file.raw_data);
            if(raw_data && raw_data.label_vision) {
                label_vision = raw_data.label_vision;
            }

            if(raw_data && raw_data.actors) {
                actors_list = raw_data.actors;
            }
        }

        // console.log("actors_list", actors_list)

        return (
            <motion.div id="IngredientPanel">
                {actors_list && actors_list.length > 0 && <>
                <motion.h1 className="topic">Actors</motion.h1>
                    {targetVidel && targetVidel.data && <>
                        <motion.div className='tag-list'>
                            {actors_list && actors_list.map((actor: any, index: number) => {
                                return <motion.div className='tag-item'
                                    key={'tag-item-adder-actor-' + index}
                                    onClick={() => {
                                        this.addTag(actor.name)
                                    }}
                                >
                                    <motion.div className='circleImg'>
                                        <div className='centerContain'>
                                            <FontAwesomeIcon icon={faUser} fontSize={40}/>    
                                        </div>
                                    </motion.div>
                                    <p className='tag-title'>{actor.name}</p>
                                </motion.div>
                            })}
                        </motion.div>
                    </>}
                    <div className='hr'></div>
                </>}
                <motion.h1 className="topic">
                    <FontAwesomeIcon icon={faCircleNodes} fontSize={20}/>
                    <span style={{
                        marginLeft: '5px',
                        padding: '1px 5px',
                        backgroundColor: 'white',
                        color: '#3049b7',
                        fontSize: '15px',
                        borderRadius: '3px',
                        display: 'inline-block',
                        lineHeight: '20px',
                        transform: 'translateY(-2px)',
                    }}>{'[AI]'}</span>
                    <span> Scene analysis at {this.getDuration()} second</span>
                </motion.h1>
                {targetVidel && targetVidel.data && <>
                    <motion.div className='tag-list'>

                        {(!(targetVidel && targetVidel.data && targetVidel.data.file && targetVidel.data.file.raw_data) 
                        || (targetVidel && targetVidel.data && targetVidel.data.file && !targetVidel.data.file.vision_api_check)
                        )
                        && 
                        <motion.div style={{
                            height: 200,
                        }}>
                            <motion.div className='centerContain'>
                                <LoadingIcon size={20} border={4}/>
                                <br/><br/>
                                <p className='tag-title'>Analyzing...</p>
                            </motion.div>
                        </motion.div>
                        }

                        {targetVidel && targetVidel.data && targetVidel.data.file && targetVidel.data.file.raw_data && targetVidel.data.file.vision_api_check &&
                            label_vision.length == 0 && <motion.div className='centerContain'>
                            <p className='tag-title'>No ingredient found</p>
                        </motion.div>}

                        {label_vision[this.getDuration()] && label_vision[this.getDuration()].length > 0 && label_vision[this.getDuration()] && label_vision[this.getDuration()].length > 0 && label_vision[this.getDuration()].map((tagString: string, index: number) => {
                            return <motion.div className='tag-item'
                                key={'tag-item-adder-' + index}
                                onClick={() => {
                                    this.addTag(tagString)
                                }}
                            >
                                <motion.div className='circleImg'>
                                    <div className='centerContain'><h1>{tagString[0]}</h1></div>
                                </motion.div>
                                <p className='tag-title'>{tagString}</p>
                            </motion.div>
                        })}

                        {targetVidel && targetVidel.data && targetVidel.data.file && targetVidel.data.file.raw_data &&
                        (!label_vision[this.getDuration()] || label_vision[this.getDuration()].length == 0) &&
                            <motion.div className='centerContain'>
                                No element found
                            </motion.div>
                        }

                            {/* <motion.div className='tag-item'
                                onClick={() => {
                                    this.addTag('Test 1234')
                                }}
                            >
                                <motion.div className='circleImg'>
                                    <div className='centerContain'><h1>T</h1></div>
                                </motion.div>
                                <p className='tag-title'>Test 1234</p>
                            </motion.div> */}
                    </motion.div>
                </>}
            </motion.div>
        );
    }

}

IngredientPanel.contextType = AppContextState;

export default IngredientPanel;