import * as React from 'react'; // Import React here once
import { Link, Navigate } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import PageContainer from '../common/PageContainer';
import { Router, Route } from 'react-router-dom';

import './index.scss';

import { AppContextState, IAppState } from '../context';
import Button from '../common/components/Interactive/Button'
import TextBox from '../common/components/Interactive/Input/TextBox';
import { getData, postData, setCookie, sleep } from '../libs/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faFile, faPhotoVideo } from '@fortawesome/free-solid-svg-icons';
import DashboardContainer from '../common/DashboardContainer';
import VideoList from '../common/components/Video-List';
import LoadingIcon from '../common/LoadingIcon';
import VideoPreviewModal from '../common/components/VideoPreview';
import configs from '../configs';
import { getRolePowerName } from '../libs/utils';
import moment from 'moment';
import PaymentModal from './components/PaymentModal';

type PageProps = {
};
type PageState = {
    loading: Boolean,
    storagePercent: number,
    storageTotal: number,
    storageUsed: number,
    expiredDate: string,
    crrPriceSelectForModal?: string,
    planList: Array<any>,
};

class BillingPage extends React.Component<PageProps, PageState, IAppState> {
    context!: IAppState;


    state: PageState = {
        loading: true,
        storagePercent: 0,
        storageTotal: 50,
        storageUsed: 0,
        expiredDate: '2023-07-14',
        crrPriceSelectForModal: '',
        planList: [
            {
                price: 0,
                storage: 50,
                reporter: 2,
                duration: 'Forever'
            },
            {
                price: 150,
                storage: 100,
                reporter: 5,
                duration: 'Monthly'
            },
            {
                price: 300,
                storage: 250,
                reporter: 10,
                duration: 'Monthly'
            },
            {
                price: 500,
                storage: 400,
                reporter: 20,
                duration: 'Monthly'
            }
        ]
    };

    constructor(props: PageProps) {
        super(props);
        this.calculateStoragePercent = this.calculateStoragePercent.bind(this);
    }

    componentDidMount() {
        console.log("BILL", this.context.project.project)
        this.calculateStoragePercent();
    }

    componentWillUnmount(): void {

    }

    componentDidUpdate(prevProps: Readonly<PageProps>, prevState: Readonly<PageState>, snapshot?: IAppState | undefined): void {

    }

    calculateStoragePercent = async () => {
        console.log("refreshing")
        let storageTotal = this.context.project.project?.usageData?.storageTotal || 50;
        let storageUsed = this.context.project.project?.usageData?.storageUsed || 0;
        let expiredDate = this.context.project.project?.usageData?.expiredDate;
        this.context.project.project && this.setState({
            expiredDate: expiredDate,
            storageTotal: storageTotal,
            storageUsed: storageUsed,
            storagePercent: Math.round((storageUsed / storageTotal) * 100),
            loading: false
        })
    }

    render() {

        const {
            expiredDate,
            storagePercent,
            storageTotal,
            storageUsed,
            planList
        } = this.state;

        return (
            <>
                { this.state.crrPriceSelectForModal && <PaymentModal price={this.state.crrPriceSelectForModal}
                planDetail={planList.find(item => item.price.toString() == this.state.crrPriceSelectForModal)}
                onClose={async (shouldRefresh: boolean) => {
                    this.setState({
                        crrPriceSelectForModal: undefined
                    })
                    if(shouldRefresh) {
                        this.calculateStoragePercent();
                    }
                } }/>}
                {this.state.loading && <div className='loadingCenter'><LoadingIcon size={30} /></div>}
                {!this.state.loading && <motion.div className='billingPage'>
                    <motion.div style={{
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}>
                        <p className='title'>Space</p>
                        { expiredDate && moment(expiredDate).isBefore(moment().add(-7, 'days')) === true && <>
                            <motion.div style={{
                                    paddingTop: 2,
                                    marginLeft: 20,
                                    height: 30,
                                }}
                            >
                                <Button
                                    onClick={() => {
                                        this.setState({
                                            crrPriceSelectForModal: this.state.planList.find(item => item.storage == storageTotal)?.price.toString()
                                        })
                                    }}
                                >Pay Now</Button>
                            </motion.div>
                        </>}
                    </motion.div>
                    <br/>
                    <motion.div className='storageProgres'>
                        <motion.div className='storageTxt'>
                            <p>Total {storageTotal} GB</p>
                            <p>Available {(storageTotal - storageUsed).toFixed(2)} GB</p>
                        </motion.div>
                        <motion.div className='sProgressBar'>
                            <motion.div className={'yeet ' + (storagePercent > 90 ? 'danger' : storagePercent > 60 ? 'warning' : '')}
                                initial={{
                                    width: '0%'
                                }}
                                animate={{
                                    width: storagePercent + '%',
                                    transition: {
                                        duration: .75,
                                        easings: 'easeInOut'
                                    }
                                }}
                            ></motion.div>
                        </motion.div>
                        <motion.div className='storageTxt'>
                            {expiredDate &&
                                <p>Expired {
                                    moment(expiredDate).format('DD MMM YYYY')
                                }</p>
                            }

                            {!expiredDate && 
                                <p>Never Expired</p>
                            }

                            <p>Usage {storageUsed.toFixed(2)} GB</p>
                        </motion.div>
                    </motion.div>
                    <br/><br/><br/>
                    <p className='title'>Switch Plan</p>
                    <br/>
                    <motion.div className='planList' >
                        {planList.map((item, index) => {
                            return <motion.div className={'planItem ' + (item.storage == storageTotal ? 'selected' : '')}
                                onClick={() => {
                                    if(item.storage == storageTotal) return;
                                    this.setState({
                                        crrPriceSelectForModal: item.price.toString()
                                    })
                                }}
                                layoutId={`payment-modal-price-${item.price}`}
                            >
                                <motion.div className='sub'
                                    
                                >
                                    <motion.div className='priceBanner' style={{
                                        backgroundColor: item.price > 0 ? '#3e54aa' : ''
                                    }}>
                                        <p className="displayPrice">{item.price == 0 ? 'Free' : item.price + ' ฿'}</p>
                                    </motion.div>
                                    <motion.div className='item-detail'>
                                        <motion.div className='line'>
                                            <p>Storage</p>
                                            <p>{item.storage} GB</p>
                                        </motion.div>
                                        <motion.div className='line'>
                                            <p>Reporter Account</p>
                                            <p>{item.reporter}</p>
                                        </motion.div>
                                    </motion.div>
                                    {
                                        (item.storage == storageTotal ? 'selected' : '') && expiredDate && <>
                                            <p className='renewTxt'>Renew on {
                                                moment(expiredDate).format('DD MMM YYYY')
                                            }</p>
                                        </>
                                    }
                                    <motion.div className='bottom-range'>
                                        <p>{item.duration}</p>
                                    </motion.div>
                                </motion.div>
                            </motion.div>;
                        })}
                    </motion.div>

                </motion.div>}
            </>
        );
    }
}

BillingPage.contextType = AppContextState;

export default BillingPage;