import * as React from 'react';
import { Link, Navigate } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import PageContainer from '../../PageContainer';
import { Router, Route } from 'react-router-dom';

import './index.scss';

import { AppContextState, IAppState } from '../../../context';
import Button from '../Interactive/Button'
import TextBox from '../Interactive/Input/TextBox';
import { getData, postData, setCookie, displayFilesSizeFormat } from '../../../libs/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faClipboard, faPeopleGroup, faRotate, faShuffle, faSignOut, faTimes, faUser } from '@fortawesome/free-solid-svg-icons';
import DashboardContainer from '../../DashboardContainer';
import VideoList from '../Video-List';
import LoadingIcon from '../../LoadingIcon';
import ReactPlayer from 'react-player';

type PageProps = {
    modalKey: string,
    visible: boolean,
    onClose: () => void,
    onOpenUserProfileModal: () => void
    onTeamProfileModal: () => void
};
type PageState = {

};
class UserOptionModal extends React.Component<PageProps, PageState, IAppState> {
    context!: IAppState;

    state = {
        
    };

    componentDidMount() {
        
    }

    componentWillUnmount(): void {
        
    }

    render() {

        return (
            <motion.div className='userOptionModal'
                style={{
                    opacity: this.props.visible ? 1 : 0,
                    transitionDuration: !this.props.visible ? '0.3s' : '',
                }}
            >
                {/* <motion.div className='modalBg'
                    initial={{ opacity: 0 }}
                    animate={{
                        opacity: 1,
                        transition: {
                            duration: 0.3,
                        }
                    }}
                    exit={{
                        opacity: 0,
                    }}
                    onClick={() => {
                        this.props.onClose();
                    }}
                ></motion.div> */}
                <motion.div className='modalContent'>
                    <motion.div className='sub'
                        layoutId={`modal-user-option-${this.props.modalKey}`}
                        initial={{
                            opacity: 0,
                            y: 50
                        }}

                        animate={{
                            opacity: 1,
                            y: 0,
                            transition: {
                                duration: 0.15,
                            }
                        }}
                    >

                        <motion.div className='menuList'>
                            <motion.div className='menuItem'
                                onClick={() => {
                                    this.props.onOpenUserProfileModal();
                                }}
                            >
                                <motion.div className='icon'>
                                    <FontAwesomeIcon icon={faUser} />
                                </motion.div>
                                <p className='text'>Edit Profile</p>
                            </motion.div>
                            <motion.div className='menuItem'
                                onClick={() => {
                                    let inviteCode = this.context.project.project?.invite_code;
                                    if (inviteCode) {
                                        navigator.clipboard.writeText(inviteCode);
                                    }
                                }}
                            >
                                <motion.div className='icon'>
                                    <FontAwesomeIcon icon={faClipboard} />
                                </motion.div>
                                <p className='text'>Copy Invite Code ({this.context.project.project?.invite_code})</p>
                            </motion.div>

                            { this.context.project.rolePower == 3 && <motion.div className='menuItem'
                                onClick={() => {
                                    this.props.onTeamProfileModal();
                                }}
                            >
                                <motion.div className='icon'>
                                    <FontAwesomeIcon icon={faPeopleGroup} />
                                </motion.div>
                                <p className='text'>Edit Team Information</p>
                            </motion.div>}

                            <motion.div className='menuItem'
                                onClick={() => {
                                    this.context.project.deleteProject();
                                }}
                            >
                                <motion.div className='icon'>
                                    <FontAwesomeIcon icon={faRotate} />
                                </motion.div>
                                <p className='text'>Switch Team</p>
                            </motion.div>

                            <motion.div className='menuItem'
                                onClick={() => {
                                    this.context.auth.logout();
                                }}
                            >
                                <motion.div className='icon'>
                                    <FontAwesomeIcon icon={faSignOut} />
                                </motion.div>
                                <p className='text'>Logout</p>
                            </motion.div>
                        </motion.div>
                    </motion.div>

                   
                </motion.div>
            </motion.div>
        );
    }
}

UserOptionModal.contextType = AppContextState;

export default UserOptionModal;